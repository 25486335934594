import React from "react";
import Panel from "./Panel"
import _ from "lodash";
import { GetEPG, UpdateEPGContents } from "../../graphql/epg-queries";
import { Icon, ICONS } from "@brightcove/studio-components";
import Table, { ComponentChannelModalColumns } from "../Table";
import ToggleButton from "../ToggleButton/ToggleButton";
import { withModal, AddToChannelModal } from "../Modals";

const AddItemButton = withModal(ToggleButton, AddToChannelModal);

export default class EpgComponentPanel extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      items: props.content.channels || []
    };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.content.channels, this.props.content.channels)) {
      this.setState({
        items: this.props.content.channels || []
      })
    }
    if (prevProps.editing != this.props.editing) {
      this.setState({
        changes: {},
        items: this.props.content.channels || []
      })
    }
  }

  onDelete = (id) => {
    return () => {
      this.setState({
        items: this.state.items.filter(item => item.id != id)
      })
    }
  }

  addItem = (item) => {
    this.setState({ items: _.concat(this.state.items, item) })
  }

  getChanges = () => {
    const { content } = this.props;
    const { items } = this.state;
    return {
      input: {
        id: content.id,
        bannerImageUrl: content.bannerImageUrl,
        type: content.type,
        channels: items && items.map(item => item.id)
      }
    }
  }

  getColumns = () => {
    if (this.props.editing) {
      let editColumn: any = {
        Header: "",
        accessor: "id",
        resizable: false,
        maxWidth: 90,
        sortable: true,
        Cell: (props) => {
          return (
            <div className="edit-buttons" >
              <div onClick={this.onDelete(props.value)}><Icon name={ICONS.TRASHCAN} color="magenta" /></div>
              <div onMouseDown={props.tdProps.rest.onDragStart}><Icon name={ICONS.MOVE} color="teal" /></div>
            </div>
          )
        }
      };
      return _.concat(ComponentChannelModalColumns, editColumn);
    }
    return ComponentChannelModalColumns;
  }

  onDragEnd = (oldIndex, newIndex) => {
    let items = this.state.items.slice(0);
    let oldIndexElement = items[oldIndex];
    let newIndexElement = items[newIndex];
    items[oldIndex] = newIndexElement;
    items[newIndex] = oldIndexElement

    this.setState({ items })
  }

  refetch = (mutationResult) => {
    const { content } = this.props;
    return [{
      query: GetEPG,
      variables: { id: content.id }
    }]
  }

  render() {
    const { onSave, onEdit, onCancel, editing, disabled, itemVariables } = this.props;
    const { items } = this.state;
    return (
      <Panel
        className="component-contents"
        title="Content"
        id="content"
        changes={this.getChanges}
        mutation={UpdateEPGContents}
        onSave={onSave}
        onEdit={onEdit}
        onCancel={onCancel}
        editing={editing}
        disabled={disabled}
        refetch={this.refetch}
      >
        {
          items && items.length > 0 ?
            <Table
              className="editable-list"
              columns={this.getColumns()}
              data={items}
              manual
              showPagination={false}
              fullWidth
              draggable
              onDragEnd={this.onDragEnd}
            />
            :
            null
        }
        {editing && <AddItemButton type="add" text="Add Channel" onModalConfirm={this.addItem} modalProps={{ items, itemVariables }} />}
      </Panel>
    )
  }
};
