import React from "react";
import Panel from "./Panel"
import _ from "lodash";
import EditGroup from "../EditGroup/EditGroup";
import { UpdateShowPreview } from "../../graphql/show-queries";
import { Input } from "../ControlledComponents";
import classnames from "classnames";
import "./PreviewVideoPanel.scss";
import ReactPlayerLoader from "../ReactPlayerLoader/ReactPlayerLoader";
import { ToasterManager } from "../../utils";

const MinimumVideoIdLength = 13;

export default class PreviewVideoPanel extends React.Component<any, any> {
  player;

  constructor(props) {
    super(props);
    this.state = {
      previewVideoId: props.content.previewVideoId,
      invalidId: true
    };
  }

  componentDidUpdate(prevProps) {
    if(!_.isEqual(prevProps.content.previewVideoId, this.props.content.previewVideoId) ||
      (prevProps.editing && !this.props.editing)) {
      ToasterManager.dismiss();
      this.setState({
        previewVideoId: this.props.content.previewVideoId,
        invalidId: true
      })
    }
  }

  onChange = (previewVideoId, property) => {
    ToasterManager.dismiss();
    if(previewVideoId.length >= MinimumVideoIdLength) {
      this.loadVideo(previewVideoId);
    }
    else if(!previewVideoId.length) {
      this.setState({ previewVideoId: null, invalidId: false });
    }
    else {
      this.setState({ invalidId: true });
    }
  }

  loadVideo = (previewVideoId) => {
    if(!previewVideoId)
      return;

    this.player.catalog.getVideo(previewVideoId, (error, video) => {
      if(error && !video) {
        this.onPlayerFailure({ message: "Video not found. Please enter a valid ID." });
      }
      else {
        this.player.catalog.load(video);
        this.setState({
          previewVideoId: previewVideoId,
          invalidId: !video
        })
      }
    })
  }

  onPlayerLoad = (success) => {
    this.player = success.ref;
    const { previewVideoId } = this.state;
    this.loadVideo(previewVideoId);
  }

  onPlayerFailure = (error) => {
    ToasterManager.error(error);
    this.setState({ invalidId: true })
  }

  render() {
    const { content, ...props } = this.props;
    const { previewVideoId, invalidId } = this.state;
    let playerClassName = classnames(["preview-player", previewVideoId && !invalidId ? "valid" : "invalid"]);

    return (
      <Panel
        title="Preview Video"
        id="preview-video"
        {...props}
        mutation={UpdateShowPreview}
        changes={{ input: { id: content.id, previewVideoId } }}
        onValidation={() => { return !invalidId }}
      >
        <EditGroup label="Preview Video ID" text={previewVideoId}>
          <Input property="previewVideoId" value={previewVideoId} onChange={this.onChange} />
        </EditGroup>
        <div className={playerClassName}>
          <img src="https://via.placeholder.com/622x350/000000/ffffff.png?text=No+Preview+Video" />
          <ReactPlayerLoader
            key={previewVideoId}
            videoId={previewVideoId}
            accountId={process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID}
            onSuccess={this.onPlayerLoad}
            onFailure={this.onPlayerFailure}
          />
        </div>
      </Panel>
    )
  }
};
