import React from "react"
import { Query } from "react-apollo"
import { UpdateCollection, GetCollection } from "../../graphql/collection-queries";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import { withRouter } from "react-router-dom";
import { PageIndex, PageIndexButton } from "../../components/PageIndex/PageIndex";
import Panel from "../../components/Panel/Panel";
import EditGroup from "../../components/EditGroup/EditGroup";
import { Input } from "../../components/ControlledComponents";
import { Button } from "@brightcove/studio-components";
import Loading from "../../components/Loading/Loading";
import AvailabilityPanel from "../../components/Panel/AvailabilityPanel";
import ManualItemsPanel from "../../components/Panel/ManualItemsPanel";
import DynamicItemsPanel from "../../components/Panel/DynamicItemsPanel";
import _ from "lodash";
import Row from "../../components/Row/Row";
import Details from "../Details/Details";
import { withModal, DeleteConfirmationModal } from "../../components/Modals";
import { ToasterManager } from "../../utils";

const canBeActivated = (collection) => {
  return collection.type == "DYNAMIC" ||  collection.type == "CONTINUE_WATCHING" || collection.items.nodes && collection.items.nodes.length
};
const DeleteButton = withModal(Button, DeleteConfirmationModal);
const defaultModel = {
  items: []
};

class Collection extends Details {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      itemVars: {
        after: 0,
        first: 10,
        orderBy: { field: "UPDATED_AT", direction: "DESC" }
      }
    }
  }

  onCompleted = () => {
    this.setState({
      changes: null,
      editingPanel: null,
      itemVars: {
        after: 0,
        first: 10,
        orderBy: { field: "UPDATED_AT", direction: "DESC" }
      }
    });
  }

  updateItemPaging = (itemVars) => {
    this.setState({ itemVars })
  }

  render() {
    const { history, match } = this.props
    const { itemVars } = this.state;

    return (
      <Query query={GetCollection} variables={{ id: match.params.id, ...itemVars }}>
        {({ loading, error, data }) => {
          let collection: any = data && data.collection || defaultModel;
          if(error) ToasterManager.error(error)

          return (
            <React.Fragment>
              <SectionHeader
                title={collection.name}
                subtitleAbove="Back"
                black
                onSubtitleClick={() => { history.goBack() }}
                buttons={[
                  <DeleteButton
                    text="Delete"
                    pink
                    onModalConfirm={() => { history.goBack() }}
                    modalProps={{selections: [collection], type: "collection"}}
                  />
                ]}/>
              <div className="detail-main">
                <div className="detail-content">
                  { loading && <Loading /> }
                  <Panel
                    title="Collection Information"
                    id="collection"
                    mutation={UpdateCollection}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("collection")}
                    disabled={this.isDisabled("collection")}
                    changes={() => {
                      let current = _.pick(collection, ["id", "name", "metadata"]);
                      current.metadata = current.metadata && _.omit(current.metadata, "__typename");

                      return {
                        input: {
                        ...current,
                        ...this.state.changes
                        }
                      }
                    }}
                    onValidation={(changes) => {
                      let inputs = changes.input;
                      return inputs.name && inputs.name.length > 0 || false;
                    }}
                  >
                    <Row>
                      <EditGroup label="Collection Name" text={collection.name}>
                        <Input value={collection.name} property="name" onChange={this.onChange}/>
                      </EditGroup>
                      <EditGroup label="ID" text={collection.id}/>
                      <EditGroup label="Type" text={collection.type}/>
                    </Row>
                  </Panel>

                  <AvailabilityPanel
                    content={collection}
                    statusDisabled={!canBeActivated(collection)}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("availability")}
                    disabled={this.isDisabled("availability")}
                  />

                  {
                    collection.type ?
                      collection.type == "DYNAMIC" ?
                        <DynamicItemsPanel
                          content={collection}
                          itemVariables={itemVars}
                          onPageChange={this.updateItemPaging}
                          onSave={this.onSave}
                          onEdit={this.onPanelEdit}
                          onCancel={this.onPanelCancel}
                          editing={this.isEditing("items")}
                          disabled={this.isDisabled("items")}
                        />
                        
                      :
                      <ManualItemsPanel
                      content={collection}
                      onPageChange={this.updateItemPaging}
                      itemVariables = {collection.type}
                      onSave={this.onSave}
                      onEdit={this.onPanelEdit}
                      onCancel={this.onPanelCancel}
                      editing={this.isEditing("items")}
                      disabled={this.isDisabled("items")}
                    />
                    : null
                  }
                </div>
                <PageIndex>
                  <PageIndexButton id="collection" text="Collection Information"/>
                  <PageIndexButton id="availability" text="Availability"/>
                  <PageIndexButton id="items" text="Collection Items"/>
                </PageIndex>
              </div>
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
};

export default withRouter(Collection);
