import React from "react";
import Panel from "./Panel"
import _ from "lodash";
import { GetCollectionItems, UpdateCollectionItems } from "../../graphql/collection-queries";
import { Icon, ICONS } from "@brightcove/studio-components";
import Table, { CollectionItemColumns } from "../Table";
import ToggleButton from "../ToggleButton/ToggleButton";
import { withModal, AddCollectionItem } from "../Modals";

const AddItemButton = withModal(ToggleButton, AddCollectionItem);

export default class ManualItemsPanel extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      items: props.content.items.nodes
    };
  }

  componentDidUpdate(prevProps) {
    if(!_.isEqual(prevProps.content.items, this.props.content.items)) {
      this.setState({
        items: this.props.content.items.nodes
      })
    }
    if(prevProps.editing != this.props.editing) {
      this.setState({
        changes: {},
        items: this.props.content.items.nodes
      })
    }
  }

  onDelete = (id) => {
    return () => {
      this.setState({
        items: this.state.items.filter(item => item.id != id)
      })
    }
  }

  addItem = (item) => {
    this.setState({ items: _.concat(this.state.items, item) })
  }

  getChanges = () => {
    const { content } = this.props;
    const { items } = this.state;
    return {
      input: {
        id: content.id,
        type: content.type,
        status: items.length == 0 ? "INACTIVE" : content.status,
        items: items && items.map(item => item.id)
      }
    }
  }

  getColumns = () => {
    if(this.props.editing) {
      let editColumn: any = {
        Header: "",
        accessor: "id",
        resizable: false,
        maxWidth: 90,
        sortable: true,
        Cell: (props) => {
          return (
            <div className="edit-buttons" >
              <div onClick={this.onDelete(props.value)}><Icon name={ICONS.TRASHCAN} color="magenta"/></div>
              <div onMouseDown={props.tdProps.rest.onDragStart}><Icon name={ICONS.MOVE} color="teal"/></div>
            </div>
          )
        }
      };
      return _.concat(CollectionItemColumns, editColumn);
    }
    return CollectionItemColumns;
  }

  onDragEnd = (oldIndex, newIndex) => {
    let items = this.state.items.slice(0);
    const arrayMove = (arr, from, to) => {
      arr.splice(to, 0, arr.splice(from, 1)[0]);
      return arr;
    };
    
    items = arrayMove(items, oldIndex, newIndex);

    this.setState({ items });
  }

  refetch = (mutationResult) => {
    const { content } = this.props;
    return [{
      query: GetCollectionItems,
      variables: { id: content.id }
    }]
  }

  onFetchData = (state, instance) => {
    const { onPageChange } = this.props;
    onPageChange({})
  }

  render() {
    const { onSave, onEdit, onCancel, editing, disabled, itemVariables } = this.props;
    const { items } = this.state;

    return (
      <Panel
        id="items"
        title="Collection Items"
        changes={this.getChanges}
        mutation={UpdateCollectionItems}
        onSave={onSave}
        onEdit={onEdit}
        onCancel={onCancel}
        editing={editing}
        disabled={disabled}
        refetch={this.refetch}
      >
        <Table
          className="editable-list"
          columns={this.getColumns()}
          data={items}
          manual
          onFetchData={this.onFetchData}
          showPagination={false}
          fullWidth
          draggable
          onDragEnd={this.onDragEnd}
        />
        { editing && <AddItemButton type="add" text="Add Collection Item" onModalConfirm={this.addItem} modalProps={{ items, itemVariables }}/> }
      </Panel>
    )
  }
};
