import React from "react";
import { Route, Switch, Redirect } from "react-router-dom"
import { withRouter, matchPath } from "react-router";
import { compose } from 'react-apollo';
import SignIn from "../routes/SignIn/SignIn";
import Catalogs from "../routes/Catalogs/Catalogs";
import { withStore, StorageField, storageKey } from "../store";
import _ from "lodash";
import { ToasterManager, TreeNode, evaluateTree } from "../utils";

class NullRoute extends React.Component<any, any> {
  static defaultProps = {
    condition: true,
    location: { pathname: "" },
    path: "*"
  }

  componentDidMount() {
    this.forceUpdate();
  }

  componentDidUpdate() {
    const {condition, fn} = this.props;
    if(condition && this.pathMatches()) fn();
  }

  pathMatches = () => {
    const {path, exact, strict} = this.props;
    return matchPath(location.pathname, {path, exact, strict});
  }

  render() {
    return (this.props.condition && this.pathMatches() && <Route {...this.props} render={props => null} />)
  }
};

const RedirectToRoute: React.FunctionComponent<any> = (props) => {
  let redirectProps = _.pick(props, ["to", "push", "from", "exact", "strict"]);
  let routeProps = _.pick(props, ["match", "location", "history", "path", "component", "render", "exact", "strict", "sensitive"])
  let pathname = routeProps.path;

  return (
    <React.Fragment>
      {location.pathname != pathname && <Redirect {...redirectProps} to={pathname} />}
      <Route {...routeProps} />
    </React.Fragment>
  )
};

class AuthenticationSwitch extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      hasClientAuthenticationStatus: false,
      isClientAuthenticated: props.isAuthenticated
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.isAuthenticated && !this.props.isAuthenticated) {
      this.setState({
        hasClientAuthenticationStatus: false,
        isClientAuthenticated: false
      })
    }
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  async getClientStatus() {
    const { store: { authenticator }} = this.props;
    let status = await authenticator.isAuthenticated();

    this.setState({
      hasClientAuthenticationStatus: true,
      isClientAuthenticated: status
    });
  }

  isProtectedPath = (path, routes) => {
    for(let route of routes) {
      if(!route.props)
        continue;

      let pathInfo = {
        path: route.props.path,
        exact: route.props.exact,
        strict: route.props.strict
      };
      if(matchPath(path, pathInfo))
        return true;
    }
    return /login$|catalogs$|logout$|access_token|id_token|error/.test(path);
  }

  // NOTE: Not currently being used
  isNoCatalogRequiredPath = (path, routes) => {
    return /dynamic-string|service-message|maintenance-mode|activity-history/.test(path);
  }

  authTree = (): TreeNode => {
    const { store: { authenticator, catalog }, clearStore, changeCatalog, history, isAuthenticated, onSignIn, onSignOut, children } = this.props;
    const { hasClientAuthenticationStatus, isClientAuthenticated } = this.state;
    const path = history.location.pathname;
    const storageCatalog = localStorage.getItem(storageKey(StorageField.Catalog));
    const localCatalog = storageCatalog && JSON.parse(storageCatalog);

    return {
      description: "Auth entry",
      children: [
        {
          description: "Non protected path (doesn't need to be authentication)",
          condition: () => !this.isProtectedPath(path, children),
          render: () => <Switch>{children}</Switch>
        },
        {
          description: "Doesn't have client status",
          condition: () => !hasClientAuthenticationStatus,
          render: () => <NullRoute fn={() => this.getClientStatus()} />
        },
        {
          description: "Isn't authenticated",
          condition: () => !isAuthenticated,
          children: [
            {
              description: "Authentication requires login screen",
              condition: () => authenticator.requiresLoginScreen,
              render: () => (
                isClientAuthenticated ? <NullRoute fn={() => authenticator.renewSession(onSignIn)} />
                : <RedirectToRoute path="/login" exact render={props => <SignIn onSignIn={onSignIn} />} />
              )
            },
            {
              description: "Authentication uses oauth flow",
              condition: () => !authenticator.requiresLoginScreen,
              render: () => (
                <NullRoute fn={() => {
                  if(isClientAuthenticated) {
                    authenticator.renewSession(onSignIn);
                  }
                  else if(/access_token|id_token|error/.test(history.location.hash)) {
                    authenticator.handleAuthentication(onSignIn);
                  }
                  else {
                    authenticator.login()
                  }
                }} />
              )
            }
          ]
        },
        {
          description: "Is authenticated",
          condition: () => isAuthenticated,
          children: [
            {
              description: "Catalog has been selected",
              condition: () => catalog != undefined,
              render: () => (
                <Switch>
                  <Redirect from="/login" to="/" />
                  <Redirect from="/catalogs" to="/" />
                  <NullRoute path="/logout" exact fn={() => {
                    clearStore();
                    authenticator.logout(onSignOut);
                  }} />
                  {children}
                </Switch>
              )
            },
            {
              description: "Catalog metadata in local storage",
              condition: () => localCatalog != undefined,
              render: () => <NullRoute fn={() => (changeCatalog(localCatalog))} />
            },
            {
              description: "No catalog selected or present in local storage",
              render: () => <RedirectToRoute path="/catalogs" exact component={Catalogs} />
            }
          ]
        }
      ]
    }
  }

  render() {
    ToasterManager.dismiss();
    return evaluateTree(this.authTree(), {}, {}, false)
  }
};

export default compose(withStore, withRouter)(AuthenticationSwitch);
