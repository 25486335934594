import React from "react";
import { withRouter } from "react-router-dom";
import { Panel } from "@brightcove/studio-components";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import Loading from "../../components/Loading/Loading";
import "./Catalogs.scss";
import { compose, Query } from 'react-apollo';
import { GetCatalogs } from "../../graphql/catalog-queries";
import { withStore } from "../../store";
import { SVGIcon } from "../../icons";

const Catalogs: React.FunctionComponent<any> = ({ history, changeCatalog }) => {
  let onClick = (catalog) => {
    return () => {
      changeCatalog(catalog);
      history.replace("/");
    }
  };

  return (
    <Query query={GetCatalogs}>
    {({ loading, data }) => {
      let catalogs = data && data.catalogs || [];
      return (
        <React.Fragment>
          {loading && <Loading/>}
          <SectionHeader teal icon={SVGIcon.Catalog} title="Content Catalogs"/>
          {catalogs &&
            <Panel className="catalog-panel">
              <ul>
                {catalogs.map((catalog, index) => (
                  <li key={index} onClick={onClick(catalog)}>{catalog.name}</li>
                ))}
              </ul>
            </Panel>
          }
        </React.Fragment>
      )
    }}
    </Query>
  )
};

export default compose(withStore, withRouter)(Catalogs);
