import React from "react";
import { Input, Button, Panel, Flex } from "@brightcove/studio-components";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import "./SignIn.scss";
import { withStore } from "../../store";
import { ToasterManager } from "../../utils";

class SignIn extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
        email: "",
        password: "",
        success: false
    };
  }

  canSubmit = () => {
    const { email, password } = this.state;
    return (email && email.length > 0) && (password && password.length > 0);
  }

  onChangeFn = (field) => {
    return (value) => {
      ToasterManager.dismiss();
        this.setState({ [field]: value });
    }
  }

  onKeyDown = (event) => {
    if(event.key == "Enter" && this.canSubmit())
      this.onSubmit();
  }

  onSubmit = () => {
    const { store: { authenticator }, onSignIn } = this.props;
    const { email, password } = this.state;
    authenticator.authenticate(email, password, (result, error) => {
      if(error)
        ToasterManager.error({ message: error.message });
      else {
        ToasterManager.dismiss();
        onSignIn(true);
      }
    });
  }

  render() {
    const { email, password, success } = this.state;

    return (
      <React.Fragment>
        <SectionHeader teal title="Sign In" />
        {
          success ?
          null :
          <Panel className="sign-in-panel">
            <Input className="sign-in-field" label="Email" placeholder="Email" value={email} onChange={this.onChangeFn("email")} required/>
            <span onKeyDown={this.onKeyDown}>
              <Input className="sign-in-field" label="Password" placeholder="Password" type="password" value={password} onChange={this.onChangeFn("password")} required/>
            </span>
            <Flex justifyContent='flex-end'>
              <Button small text="SIGN IN" onClick={this.onSubmit} disabled={!this.canSubmit()}/>
            </Flex>
          </Panel>
        }
      </React.Fragment>
    )
  }
};

export default withStore(SignIn);
