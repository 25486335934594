import Catalog from "./catalog.svg";
import Collection from "./collection.svg";
import NavAllContent from "./side-nav-all-content.svg";
import NavEpisode from "./side-nav-episodes.svg";
import NavFeatures from "./side-nav-features.svg";
import NavImage from "./side-nav-image.svg";
import NavVideo from "./side-nav-video-extras.svg";
import NavShows from "./side-nav-shows.svg";
import NavContentScreen from "./side-nav-content-screen.svg";
import Logo from "./logo-colored.svg";
import AllContent from "./all-content.svg";
import Episodes from "./episodes.svg";
import Shows from "./shows.svg";
import Image from "./image.svg";
import ContentScreen from "./content-screens.svg";
import ServiceMessage from "./service-message.svg";
import MaintenanceMode from "./maintenance.svg";
import ActivityLog from "./activity-log.svg";
import Channels from "./channels.svg"

const SVGIcon = {
  Catalog: Catalog,
  Collection: Collection,
  Episodes: Episodes,
  Shows: Shows,
  Logo: Logo,
  NavAllContent: NavAllContent,
  NavEpisode: NavEpisode,
  NavFeatures: NavFeatures,
  NavImage: NavImage,
  NavVideo: NavVideo,
  NavShows: NavShows,
  AllContent: AllContent,
  Image: Image,
  ContentScreen: ContentScreen,
  NavContentScreen: NavContentScreen,
  ServiceMessage: ServiceMessage,
  MaintenanceMode: MaintenanceMode,
  ActivityLog: ActivityLog,
  Channels: Channels
};

export { SVGIcon };
