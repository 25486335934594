import React from "react";
import { Modal, Button } from "@brightcove/studio-components";
import Images from "../../routes/Images/Images"
import "./ImageSelectionModal.scss";

export default class ImageSelectionModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      selections: []
    }
  }

  handleSelections = () => {
    const { selections } = this.state;
    const { confirm } = this.props;
    confirm && confirm(selections);
  }

  onRowSelect = (selections) => {
    this.setState({selections})
  }

  render() {
    const { content, isOpen, close, loading, data, ...props } = this.props;
    const { selections } = this.state;

    return (
      <Modal
        className="image-selection-modal"
        title="Select Image"
        {...props}
        isOpen={isOpen}
        onClose={close}
        buttons={[
          <Button key="1" invert text="Cancel" onClick={close} />,
          <Button key="2" text="Select Image" disabled={!selections.length} onClick={this.handleSelections} />
        ]}
      >
        <Images embedded content={content} onRowSelect={this.onRowSelect}/>
      </Modal>
    )
  }
};
