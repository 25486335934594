export default {
  localStoragePrefix: "Reelz",
  auth0: {
    domain: "reelz-admin.auth0.com",
    clientID: "ERFDZS6T-11VKi-lrPzB8zn6eqCgVOdL"
  },
  cognito: {
    region: 'us-east-1',
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_CLIENT_ID
  }
};
