import React from "react"
import { Query } from "react-apollo"
import { GetServiceMessages } from "../../graphql/service-message-queries";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import Loading from "../../components/Loading/Loading";
import { SVGIcon } from "../../icons";
import Details from "../Details/Details";
import ServiceMessagePanel from "../../components/Panel/ServiceMessagePanel";
import { Button } from "@brightcove/studio-components";
import _ from "lodash";
import { ToasterManager } from "../../utils";

export default class ServiceMessages extends Details {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      tempMessage: null
    }
  }

  getPanelId = (id) => {
    return `service-message-${id}`
  }

  onCompleted = () => {
    ToasterManager.dismiss();
    this.setState({ editingPanel: null, tempMessage: null });
  }

  createUnsavedMessage = () => {
    let id =  _.uniqueId("temporary");

    this.setState({
      tempMessage: { id, status: "INACTIVE", platforms: [] },
      editingPanel: this.getPanelId(id)
    })
  }

  deleteUnsavedMessage = ()  => {
    this.setState({
      tempMessage: null,
      editingPanel: null
    })
  }

  render() {
    const { tempMessage, editingPanel } = this.state;

    return (
      <Query query={GetServiceMessages}>
        {({ loading, error, refetch, data }) => {
          let messages = data && data.serviceMessages || [];
          let allMessages = tempMessage ? _.concat(messages, tempMessage) : messages;
          if(error) ToasterManager.error(error)

          return (
            <React.Fragment>
              <SectionHeader icon={SVGIcon.ServiceMessage} teal title="Service Messages" />
              <div className="detail-main">
                <div className="detail-content full-width">
                  { loading && <Loading /> }
                  { allMessages.map(message => {
                      let id = this.getPanelId(message.id)
                      return (
                        <ServiceMessagePanel
                          key={id}
                          id={id}
                          unsaved={id.includes("temporary")}
                          message={message}
                          refetch={refetch}
                          onSave={this.onSave}
                          onEdit={this.onPanelEdit}
                          onCancel={this.onPanelCancel}
                          onUnsavedDelete={this.deleteUnsavedMessage}
                          editing={this.isEditing(id)}
                          disabled={this.isDisabled(id)}
                        />
                      )
                    })
                  }
                  <Button text="Create Service Message" onClick={this.createUnsavedMessage} disabled={editingPanel != null} />
                </div>
              </div>
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
};
