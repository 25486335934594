import gql from "graphql-tag";

export const GetServiceMessages = gql`
  query GetServiceMessages {
    serviceMessages {
      id
      message
      platforms
      status
      start
      end
    }
  }`
;

export const CreateServiceMessage = gql`
  mutation CreateServiceMessage($input: CreateServiceMessageInput!) {
    createServiceMessage(input: $input) {
      serviceMessage {
        id
        message
        platforms
        status
        start
        end
      }
    }
  }`
;

export const UpdateServiceMessage = gql`
  mutation UpdateServiceMessage($input: UpdateServiceMessageInput!) {
    updateServiceMessage(input: $input) {
      serviceMessage {
        id
        message
        platforms
        status
        start
        end
      }
    }
  }`
;

export const DeleteServiceMessages = gql`
  mutation DeleteServiceMessages($ids: [ID!]) {
    deleteServiceMessages(ids: $ids)
  }`
;
