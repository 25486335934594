import React from "react"
import { Query } from "react-apollo"
import { UpdateComponent, GetComponent } from "../../graphql/component-queries";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import { withRouter } from "react-router-dom";
import { PageIndex, PageIndexButton } from "../../components/PageIndex/PageIndex";
import Panel from "../../components/Panel/Panel";
import EditGroup from "../../components/EditGroup/EditGroup";
import { Input, TagListInput, ImageInput } from "../../components/ControlledComponents";
import Loading from "../../components/Loading/Loading";
import AvailabilityPanel from "../../components/Panel/AvailabilityPanel";
import _ from "lodash";
import Details from "../Details/Details";
import Row from "../../components/Row/Row";
import { getComponentType, ToasterManager } from "../../utils";
import ComponentContentsPanel from "../../components/Panel/ComponentContentsPanel";
import EpgComponentPanel from "../../components/Panel/EpgComponentPanel"

const defaultModel = {
  collection: {},
};

class Component extends Details {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      contentVars: {
        after: 0,
        first: 10,
        orderBy: { field: "UPDATED_AT", direction: "DESC" }
      }
    }
  }

  onCompleted = () => {
    this.setState({
      changes: null,
      editingPanel: null,
      contentVars: {
        after: 0,
        first: 10,
        orderBy: { field: "UPDATED_AT", direction: "DESC" }
      }
    });
  }

  render() {
    const { refetch, history, match, ...props } = this.props
    const { contentVars } = this.state;

    return (
      <Query query={GetComponent} variables={{ id: match.params.id,  ...contentVars }}>
        {({ loading, error, data }) => {
          let component: any = data && data.component || defaultModel;
          let changes = {
            input: {
              ..._.omit(component, ["__typename", "collection"]),
              ...this.state.changes,
              bannerImageUrl: this.state.changes && this.state.changes.bannerImageUrl || component.bannerImageUrl,
              channels: component.channels && component.channels.map(item => item.id)
            }
          };
          if(error) ToasterManager.error(error)

          return (
            <React.Fragment>
              <SectionHeader
                title={component.name}
                subtitleAbove="Back"
                black
                onSubtitleClick={() => { history.goBack() }} />
              <div className="detail-main">
                <div className="detail-content">
                  { loading && <Loading /> }
                  <Panel
                    title="Component Information"
                    id="component"
                    mutation={UpdateComponent}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("component")}
                    disabled={this.isDisabled("component")}
                    changes={changes}
                    onValidation={(changes) => {
                      let inputs = changes.input;
                      return inputs.name && inputs.name.length > 0 || false;
                    }}
                  >
                    <Row>
                      <EditGroup label="Component Name" text={component.name}>
                        <Input value={component.name} property="name" onChange={this.onChange}/>
                      </EditGroup>
                      <EditGroup label="ID" text={component.id}/>
                      <EditGroup label="Type" text={getComponentType(component.type)}/>
                    </Row>
                    { !["LIVE", "GENRES"].includes(component.type) &&
                      <Row>
                        <EditGroup label="Component Header" text={component.header}>
                          <Input value={component.header} property="header" onChange={this.onChange}/>
                        </EditGroup>
                        {
                          component.type == "EPG" ?
                          <EditGroup label="Graphic" image={component.bannerImageUrl}>
                            <ImageInput image={component.bannerImageUrl || ""} property="bannerImageUrl" content={component.bannerImageUrl} onChange={this.onChange} />
                          </EditGroup>
                          :
                          <EditGroup.Placeholder />
                        }
                        <EditGroup.Placeholder />
                      </Row>
                    }
                  </Panel>

                  <AvailabilityPanel
                    content={component}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("availability")}
                    disabled={this.isDisabled("availability")}
                  />

                { component.type == "RECOMMENDATIONS" ?
                  null
                  :
                  ["GRID", "CAROUSEL", "HERO", "COMINGSOON"].includes(component.type) ?
                  <ComponentContentsPanel
                    content={component}
                    contentVariables={contentVars}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("content")}
                    disabled={this.isDisabled("content")}
                  />
                  :
                  component.type == "EPG" ?
                  <EpgComponentPanel
                    content={component}
                    contentVariables={contentVars}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("content")}
                    disabled={this.isDisabled("content")}
                  />
                  :
                  <Panel
                    title="Component Content"
                    id="content"
                    mutation={UpdateComponent}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("content")}
                    disabled={this.isDisabled("content")}
                    changes={changes}
                    onValidation={(changes) => {
                      let inputs = changes.input;
                      return inputs.name && inputs.name.length &&
                        component.type == "GENRES" ? inputs.genres && inputs.genres.length :
                        component.type == "LIVE" ? inputs.url && inputs.url.length :
                        true
                    }}
                  >
                  { component.type == "GENRES" &&
                    <EditGroup label="Genres" text={component.genres.join(", ")}>
                      <TagListInput
                        value={component.genres}
                        property="genres"
                        onChange={this.onChange}/>
                    </EditGroup>
                  }
                  { component.type == "LIVE" &&
                    <EditGroup label="Stream URL" text={component.url}>
                      <Input value={component.url} property="url" onChange={this.onChange}/>
                    </EditGroup>
                  }
                  </Panel>
                }
                </div>
                <PageIndex>
                  <PageIndexButton id="component" text="Component Information"/>
                  <PageIndexButton id="availability" text="Availability"/>
                  <PageIndexButton id="content" text="Component Content"/>
                </PageIndex>
              </div>
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
};

export default withRouter(Component);
