import { createStore as createReduxStore } from "redux";
import { StoreState, GenericAction, StorageField, storageKey } from "./types";
import reducers, { initialState as defaultState, initLocalStorage } from "./reducers";
import withStore from "./mappers";

export default function createStore(initialState) {
  let state = {...defaultState, ...initialState};
  initLocalStorage(state);
  return createReduxStore<StoreState, GenericAction, any, any>(reducers, state);
};

export { StorageField, storageKey, withStore };
