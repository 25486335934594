import gql from "graphql-tag";

export const GetScreens = gql`
  query GetScreens($after: Int, $first: Int, $catalogId: ID, $type: ScreenType, $excludeType: ScreenType, $query: String, $orderBy: ScreenOrder) {
    screens(after: $after, first: $first, catalogId: $catalogId, type: $type, excludeType: $excludeType, query: $query, orderBy: $orderBy) {
      nodes {
        id
        name
        status
        createdAt
        updatedAt
        screenType

        images {
          id
          name
          url
        }
      }
      totalCount
    }
  }`
;

export const GetScreen = gql`
  query GetScreen($id: ID!) {
    screen(id: $id) {
      id
      extId
      name
      header
      body
      screenType

      status
      start
      end

      components {
        id
        type
        rank
        name
        status
        start
        end

        header
        genres
        url
        collection {
          id
        }
      }

      tile {
        id
        header
        subHeader
        badges
        image {
          id
          url
          width
          height
        }
      }

      slide {
        id
        header
        subHeader
        description
        badges
        ctaLabel
        image {
          id
          url
          width
          height
        }
      }

      images {
        id
        name
        url
      }
    }
  }`
;

export const CreateScreen = gql`
  mutation CreateScreen($input: CreateScreenInput!) {
    createScreen(input: $input) {
      screen {
        name
        id
        header
        screenType

        status
        start
        end

        components {
          id
        }

        tile {
          id
          image {
            id
            url
          }
        }

        slide {
          id
          header
          subHeader
          description
          badges
          ctaLabel
          image {
            id
            url
          }
        }

        images {
          id
          name
          url
        }
      }
    }
  }`
;

export const UpdateScreen = gql`
  mutation UpdateScreen($input: UpdateScreenInput!) {
    updateScreen(input: $input) {
      screen {
        id
        name
      }
    }
  }`
;

export const UpdateScreenContents = gql`
  mutation UpdateScreen($input: UpdateScreenInput!) {
    updateScreen(input: $input) {
      screen {
        id
        header
        body
        status

        components {
          id
          type
          rank
          name
          status
          start
          end

          header
          genres
          url
          collection {
            id
          }
        }
      }
    }
  }`
;

export const DeleteScreens = gql`
  mutation DeleteScreens($ids: [ID!]) {
    deleteScreens(ids: $ids)
  }`
;
