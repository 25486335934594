import React from "react";
import "./PageIndex.scss";
import { Panel } from "@brightcove/studio-components";

let scroll = (id) => {
  return () => {
    let el = document.getElementById(id);
    if(el) {
      window.scroll(0, el.getBoundingClientRect().top + window.pageYOffset);
    }
  }
}

const PageIndexButton: React.FunctionComponent<any> = ({id, text}) => {
  return <div className="page-index-button" onClick={scroll(id)}>{text}</div>
};

const PageIndex: React.FunctionComponent<any> = ({children}) => {
  return <Panel className="page-index">{children}</Panel>
};

export { PageIndex, PageIndexButton };
