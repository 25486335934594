import { changeLanguage, changeCatalog, clearStore } from "./actions";
import { connect } from 'react-redux';

const storePropsMapper = (state) => {
  return { store: state };
};

const storeActionsMapper = (dispatch) => {
  return {
    changeLanguage: (language) => dispatch(changeLanguage(language)),
    changeCatalog: (catalog, callback) => dispatch(changeCatalog(catalog, callback)),
    clearStore: () => dispatch(clearStore())
  };
};

const withStore = connect(storePropsMapper, storeActionsMapper);

export default withStore;
