import gql from "graphql-tag";

export const GetEpisode = gql`
  query GetEpisode($id: ID!) {
    contentItem(id: $id) {
      ... on Episode {
        id
        name
        extId
        seasonNumber
        episodeNumber
        runtime
        seriesId
        seasonId
        genres
        tags
        tuneInText
        originalAirDate
        duration

        status
        start
        end

        metadata {
          id
          description
          summary
          year
          rating
        }

        tile {
          id
          header
          subHeader
          badges
          image {
            id
            url
            width
            height
          }
        }

        slide {
          id
          header
          subHeader
          description
          badges
          ctaLabel
          image {
            id
            url
            width
            height
          }
        }

        series {
          id
          images {
            id
            name
            url
          }
        }

        season {
          id
        }
      }
    }
  }`
;

export const UpdateEpisode = gql`
  mutation UpdateEpisode($input: UpdateEpisodeInput!) {
    updateEpisode(input: $input) {
      episode {
        id
        name
        runtime
        genres
        tags
        tuneInText

        metadata {
          id
          description
          summary
          year
        }
      }
    }
  }`
;
