import React from "react";
import { SearchInput } from "../ControlledComponents";
import classnames from "classnames";
import "./SectionHeader.scss";

const SectionHeader: React.FunctionComponent<any> = ({icon, title, subtitle, subtitleAbove, searchQuery, onSearch, onSubtitleClick, teal, black, buttons}) => {
  let className = classnames({"section-header": true, teal, black});
  let textClassName = classnames({"section-header-text": true, vertical: subtitleAbove});

  return (
    <div className={className}>
      { icon && <div className="section-header-icon"><img src={icon}/></div> }
      <div className={textClassName}>
        { subtitleAbove && <div className="section-header-subtitle" onClick={onSubtitleClick}>{subtitleAbove}</div>}
        <div className="section-header-title">{title}</div>
        { subtitle && <div className="section-header-subtitle">{subtitle}</div>}
      </div>
      { onSearch &&
        <div className="section-header-search">
          <SearchInput noPad value={searchQuery || ""} placeholder="Search..." onChange={onSearch}/>
        </div>
      }
      { buttons &&
        <div className="section-header-buttons">
        {
          React.Children.map(buttons, (button, index) => {
            return React.cloneElement(button, { key: index })
          })
        }
        </div>
      }
    </div>
  )
};

export default SectionHeader;
