import React from "react";
import { Query, Mutation, compose } from "react-apollo";
import { withRouter } from "react-router-dom";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import DefaultSideMenu from "../../components/SideMenu/DefaultSideMenu";
import SectionControls from "../../components/SectionControls/SectionControls";
import { Button } from "@brightcove/studio-components";
import Table, { AllImagesColumns, AllImagesModalColumns } from "../../components/Table";
import { SVGIcon } from "../../icons";
import { GetImages, AddImagesToContentItems } from "../../graphql/image-queries";
import Loading from "../../components/Loading/Loading";
import _ from "lodash";
import { SearchInput } from "../../components/ControlledComponents";
import ModalControls from "../../components/ModalControls/ModalControls";
import SideMenu, { SideMenuItem } from "../../components/SideMenu/SideMenu";
import { withModal, AddToShowModal, AddToScreenModal, DeleteConfirmationModal } from "../../components/Modals";
import { handleErrors, formatFetchData, ToasterManager } from "../../utils";
import { withStore } from "../../store";

const AddButton: React.FunctionComponent<any> = ({ images, modal, onCompleted, ...props }) => {
  return (
    <Mutation mutation={AddImagesToContentItems} onCompleted={onCompleted} >
    {(add) => {
      const ModalButton = withModal(Button, modal);
      return <ModalButton {...props} onModalConfirm={(contentItems) => {
        let variables = {
          input: {
            ids: images.map(image => image.id),
            contentItemIds: contentItems.map(contentItem => contentItem.id)
          }
        };
        add({ variables });
      }} />
    }}
    </Mutation>
  )
};

class Images extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      graphqlQuery: GetImages,
      searchQuery: "",
      selections: [],
      first: 20,
      after: 0,
      contentItem: props.embedded && props.content ? props.content.id : null,
      orderBy: { field: "UPDATED_AT", direction: "DESC" }
    }
  }

  componentDidUpdate(prevProps) {
    if(!this.props.embedded && (prevProps.location.pathname != this.props.location.pathname)) {
      this.state.deselectAll && this.state.deselectAll();

      this.setState({
        graphqlQuery: GetImages,
        searchQuery: "",
        selections: [],
        first: 20,
        after: 0,
        contentItem: null,
        orderBy: { field: "UPDATED_AT", direction: "DESC" }
      })
    }
  }

  onFilterSelect = (filter) => {
    return () => {
      const { content } = this.props;
      this.setState({ contentItem: filter ? content.id : null })
    }
  }

  onSearch = (searchQuery) => {
    this.setState({ searchQuery, selections: [] })
  }

  onRowSelect = (selections, { deselectAll }) => {
    const { onRowSelect } = this.props;
    this.setState({ selections, deselectAll })
    onRowSelect && onRowSelect(selections);
  }

  onFetchData = (state) => {
    this.setState(formatFetchData(state, this.state.orderBy))
  }

  render() {
    const { embedded, history, store: { catalog } } = this.props;
    const { graphqlQuery, first, after, orderBy, searchQuery: query, contentItem, selections, deselectAll } = this.state;
    const DeleteButton = withModal(Button, DeleteConfirmationModal);

    return (
      <Query query={graphqlQuery} notifyOnNetworkStatusChange variables={{first, after, query, orderBy, contentItem, catalogId: catalog.id }} fetchPolicy={embedded ? "cache-and-network": "network-only"}>
        {({ loading, error, data: resp, refetch }) => {
          let images = resp && resp.images;
          let data = images && images.nodes || [];
          let totalCount = images && images.totalCount || 0;

          handleErrors(error);

          return (
            <div className="main">
              {
                embedded ?
                 <SideMenu>
                   <SideMenuItem icon={SVGIcon.NavImage} onClick={this.onFilterSelect(true)}>This Content</SideMenuItem>
                   <SideMenuItem icon={SVGIcon.NavImage} onClick={this.onFilterSelect(false)}>All Images</SideMenuItem>
                 </SideMenu>
                :
                 <DefaultSideMenu />
              }
              <div className="content">
                { loading && <Loading /> }
                {
                  embedded ?
                    <ModalControls>
                      <SearchInput noPad placeholder="Search..." onChange={this.onSearch}/>
                    </ModalControls>
                  :
                    <React.Fragment>
                      <SectionHeader
                        teal
                        icon={SVGIcon.Image}
                        title={"Images"}
                        subtitle={(totalCount > 1 || !totalCount) ? `${totalCount} Images` : `${totalCount} Images`}
                        onSearch={this.onSearch}
                      />
                      <SectionControls>
                        <DeleteButton
                          pink
                          text="Delete"
                          disabled={!selections.length}
                          modalProps={{ type: "image", selections }}
                          onModalConfirm={() => { refetch().then(deselectAll) }}
                        />
                        <AddButton
                          text="Add to Screen"
                          images={selections}
                          disabled={!selections.length}
                          modal={AddToScreenModal}
                          onCompleted={() => {
                            ToasterManager.info({ message: "Images added to Screens! Any images already added to the screen were omitted." })
                            deselectAll();
                          }}
                        />
                        <AddButton
                          text="Add to Show"
                          images={selections}
                          disabled={!selections.length}
                          modal={AddToShowModal}
                          onCompleted={() => {
                            ToasterManager.info({ message: "Images added to Shows! Any images already added to the show were omitted." })
                            deselectAll();
                          }}
                        />
                        <Button text="Upload Images" onClick={() => history.push("/images/upload")} />
                      </SectionControls>
                    </React.Fragment>
                }
                <Table
                  key={query}
                  fullWidth
                  selectable
                  manual
                  onFetchData={this.onFetchData}
                  pageSizeOptions={[20, 30, 40, 50]}
                  onSelection={this.onRowSelect}
                  singleSelection={embedded}
                  columns={embedded ? AllImagesModalColumns : AllImagesColumns}
                  totalCount={totalCount}
                  data={data}
                />
              </div>
            </div>
          )
        }}
      </Query>
    )
  }
};

export default compose(withStore, withRouter)(Images);
