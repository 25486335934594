import React from "react";
import { Flex } from "@brightcove/studio-components";
import "./Row.scss";

const Row: React.FunctionComponent<any> = ({children, ...props}) => {
  return (
    <Flex className="row" justifyContent="space-between">
      { React.Children.map(children, child => { return React.cloneElement(child, {...props}) }) }
    </Flex>
  )
};

export default Row;
