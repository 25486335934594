import React from "react";
import { formatDate } from "../../../utils";
import { Icon, ICONS } from "@brightcove/studio-components";
import { Link } from "react-router-dom";

export const ActivityLogColumns = [
  {
    Header: "Activity",
    accessor: "operation",
    minWidth: 190,
    resizable: false,
    Cell: ({ original: { operation, id } }) => {
      return (
        <div className="name-id">
          <div><Link to={`/activity/${id}`}>{operation}</Link></div>
          <div>{id}</div>
        </div>
      )
    }
  },
  {
    Header: "Content IDs",
    accessor: "resourceIds",
    minWidth: 240,
    resizable: false,
    Cell: ({ value }) => {
      let ids = value && value.replace(/[{}\"]/g, "").replace(/,/g, ", ");
      return <div className="activity-content-ids">{ids || "-"}</div>
    }
  },
  {
    Header: "User",
    accessor: "user",
    minWidth: 200,
    resizable: false,
    Cell: ({ original: { userEmail, userId } }) => {
      return (
        <div className="name-id">
          <div>{userEmail}</div>
          <div>{userId}</div>
        </div>
      )
    }
  },
  {
    Header: "Status",
    accessor: "success",
    resizable: false,
    minWidth: 75,
    maxWidth: 75,
    style: { display: "block", textAlign: "center" },
    Cell: ({ value }) => {
      return value ? <Icon name={ICONS.CHECKMARK} color='green'/> : <Icon name={ICONS.WARNING} color='magenta'/>
    }
  },
  {
    Header: "Catalog",
    accessor: "catalog",
    minWidth: 100,
    resizable: false,
    Cell: ({ original: { catalog } }) => {
      return catalog && catalog.name || "-"
    }
  },
  {
    Header: "Created",
    accessor: "createdAt",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  }
]
