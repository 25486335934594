import gql from "graphql-tag";

export const CollectionItemsFragment = gql`
  fragment CollectionItemsFragment on Collection {
    items(after: $after, first: $first, orderBy: $orderBy) {
      nodes {
        id
        name
        status
        start
        end

        tile {
          id
          image {
            id
            url
          }
        }
      }
      totalCount
    }
  }`
;

export const GetCollection = gql`
  query GetCollection($id: ID!, $after: Int, $first: Int, $orderBy: CollectionOrder) {
    collection(id: $id) {
      id
      name
      type

      status
      start
      end

      ruleOrderBy
      ruleOrderDirection
      ruleLimit

      rules {
        field
        relation
        value
      }
      ...CollectionItemsFragment
    }
  }
  ${CollectionItemsFragment}`
;

export const GetCollectionItems = gql`
  query GetCollection($id: ID!, $after: Int, $first: Int, $orderBy: CollectionOrder) {
    collection(id: $id) {
      id
      ...CollectionItemsFragment
    }
  }
  ${CollectionItemsFragment}`
;

export const GetComponentCollection = gql`
  query GetCollection($id: ID!, $after: Int, $first: Int, $orderBy: CollectionOrder) {
    collection(id: $id) {
      id
      name
      status
      start
      end
      type
      ...CollectionItemsFragment
    }
  }
  ${CollectionItemsFragment}`
;

export const GetCollections = gql`
  query GetCollections($after: Int, $first: Int, $catalogId: ID, $query: String, $orderBy: CollectionOrder, $type: CollectionType) {
    collections(after: $after, first: $first, catalogId: $catalogId, query: $query, orderBy: $orderBy, type: $type) {
      nodes {
        id
        name
        type
        status
        createdAt
        updatedAt
      }
      totalCount
    }
  }`
;

export const DeleteCollections = gql`
  mutation DeleteCollections($ids: [ID!]) {
    deleteCollections(ids: $ids)
  }`
;

export const AddToCollection = gql`
  mutation UpdateCollection($input: AddToCollectionInput!) {
    addToCollection(input: $input)
  }`
;

export const CreateCollection = gql`
  mutation CreateCollection($input: CreateCollectionInput!) {
    createCollection(input: $input) {
      collection {
        id
        name
        type
      }
    }
  }`
;

export const UpdateCollection = gql`
  mutation UpdateCollection($input: UpdateCollectionInput!) {
    updateCollection(input: $input) {
      collection {
        id
        name
      }
    }
  }`
;

export const UpdateCollectionItems = gql`
  mutation UpdateCollection($input: UpdateCollectionInput!) {
    updateCollection(input: $input) {
      collection {
        id
        status
      }
    }
  }`
;

export const UpdateCollectionRules = gql`
  mutation UpdateCollection($input: UpdateCollectionInput!) {
    updateCollection(input: $input) {
      collection {
        id
        ruleOrderBy
        ruleOrderDirection
        ruleLimit

        rules {
          field
          relation
          value
        }
      }
    }
  }`
;

export const UpdateCollectionAvailability = gql`
  mutation UpdateCollection($input: UpdateCollectionInput!) {
    updateCollection(input: $input) {
      collection {
        id
        status
        start
        end
      }
    }
  }`
;
