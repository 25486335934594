import React, { ReactNode } from "react";
import { Mutation } from "react-apollo";
import { Flex, Panel as StudioPanel, PanelTitleBar, Button } from "@brightcove/studio-components";
import "./Panel.scss";
import classnames from "classnames";
import _ from "lodash";
import { DocumentNode } from "graphql";
import { ToasterManager } from "../../utils";

export interface PanelProps {
  id: string
  title?: string
  uneditable?: boolean
  invalid?: boolean
  onEdit: (id: string) => void
  onCancel: Function
  onSave: (Object) => void
  onValidation: (changes: (Function | any)) => boolean
  changes?: (() => Object) | any
  disabled?: boolean
  editing?: boolean
  mutation?: DocumentNode
  refetch?: any
  update?: any
  className?: string
  titleBar?: JSX.Element | string
}

function ChildrenWithProps(oldChildren, props = {}): (JSX.Element[] | React.FunctionComponentElement<any>[]) {
  let children;
  children = React.Children.map(oldChildren, (child) => {
    if(!child)
      return <React.Fragment/>
    else if(child.type.toString() == React.Fragment.toString())
      return ChildrenWithProps((child as any).props.children, props);
    else if(["div", "span"].includes(child.type.toString()))
        return child;
    else
      return React.cloneElement(child as JSX.Element, { ...props });
  })
  return _.flattenDeep(children);
}

const PanelFooterBar: React.FunctionComponent<any> = ({children}) => {
  return <Flex className="panel-footer-bar" justifyContent="flex-end">{children}</Flex>
};

const SaveButton: React.FunctionComponent<any> = ({mutation, refetch, update, changes, onCompleted, onError, ...props}) => {
  return (
    mutation ?
      <Mutation mutation={mutation} onCompleted={onCompleted} onError={onError} awaitRefetchQueries={true} refetchQueries={refetch} update={update}>
      {(save) => {
        let onClick = () => {
          ToasterManager.dismiss();
          let variables = typeof changes == "function" && changes() || changes;
          save({ variables: variables });
        };
        return <Button small green text="Save" {...props} onClick={onClick} />
      }}
      </Mutation>
    : null
  )
};

export default class Panel extends React.Component<PanelProps, any> {
  static defaultProps = {
    editing: false,
    uneditable: false,
    invalid: false,
    onEdit: () => {},
    onCancel: () => {},
    onSave: () => {},
    onValidation: () => { return true }
  }

  componentDidUpdate(prevProps) {
    let prevChanges = typeof prevProps.changes == "function" && prevProps.changes() || prevProps.changes;
    let currentChanges = typeof this.props.changes == "function" && this.props.changes() || this.props.changes;
    if(!_.isEqual(prevChanges, currentChanges))
      this.forceUpdate()
  }

  onEdit = () => {
    this.props.onEdit(this.props.id);
  }

  onCompleted = (data) => {
    if(!data) {
      let error = { message: "Something went wrong. Please check the console for details." }
      this.props.onSave({ error });
    }
    else {
      this.props.onSave({ data });
    }
  }

  onError = (error) => {
    this.props.onSave({ error });
  }

  isValid = () => {
    const { onValidation, changes } = this.props;
    return onValidation(typeof changes == "function" && changes() || changes || {});
  }

  render() {
    const { id, title, children, uneditable, disabled, editing, onCancel, className: customClassName, titleBar } = this.props;
    const { mutation, refetch, update, changes } = this.props;
    let className = classnames([{ "panel": true, "no-edit": uneditable, "disabled": disabled }, customClassName]);

    return (
      <div id={id} key={`panel.${editing}`}>
        <StudioPanel className={className}>
          <PanelTitleBar title={title}>
          { titleBar || <React.Fragment/> }
          { editing ?
            <React.Fragment>
              <Button small invert text="Cancel" onClick={onCancel} />
              <SaveButton disabled={!this.isValid()} onCompleted={this.onCompleted} onError={this.onError} {...{ mutation, refetch, update, changes }}/>
            </React.Fragment>
            : <Button small green text="Edit" onClick={this.onEdit} />
          }
          </PanelTitleBar>
          <React.Fragment>
            { ChildrenWithProps(children, { editing })}
          </React.Fragment>
        </StudioPanel>
      </div>
    )
  }
};

export { Panel, PanelFooterBar, PanelTitleBar }
