import React from "react";
import { Modal, Button } from "@brightcove/studio-components";
import Channels from "../../routes/Channels/Channels";
import "./AddToCollectionModal.scss";


export default class AddToChannelModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selections: []
    }
  }

  handleSelections = () => {
    const { selections } = this.state;
    const { confirm, content } = this.props;
    confirm && confirm(selections);
  }

  onRowSelect = (selections) => {
    this.setState({ selections })
  }

  render() {
    const { isOpen, close, title, cta, type, itemVariables, ...props } = this.props;
    const { selections } = this.state;

    return (
      <Modal
        className="add-to-collection-modal"
        title={title || "Add a Channel"}
        {...props}
        isOpen={isOpen}
        onClose={close}
        buttons={[
          <Button key="1" invert text="Cancel" onClick={close} />,
          <Button key="2" text={cta || "Add a Channel"} disabled={!selections.length} onClick={this.handleSelections} />
        ]}
      >
        <Channels key={"modal.add-to-collection"} embedded type={type || itemVariables} onRowSelect={this.onRowSelect} />
      </Modal>
    )
  }
};
