import React from "react";
import AddToCollectionModal from "./AddToCollectionModal";
import ImageSelectionModal from "./ImageSelectionModal";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import AddToShowModal from "./AddToShowModal";
import AddToScreenModal from "./AddToScreenModal";
import ImageUploadModal from "./ImageUploadModal";
import AddComponentModal from "./AddComponentModal";
import AddCollectionItem from "./AddCollectionItemModal";
import AddToChannelModal from "./AddToChannelmodal";

function withModal(WrappedComponent, Modal) {
  return class ModalOpener extends React.Component<any, any> {
    static displayName = `withModal(${WrappedComponent.displayName || WrappedComponent.name || "Component"})`;

    constructor(props) {
      super(props);
      this.state = {
        isOpen: false
      }
    }

    closeModal = () => {
      this.setState({ isOpen: false });
    }

    openModal = () => {
      this.setState({ isOpen: true });
    }

    confirmModal = (data) => {
      const { onModalConfirm } = this.props;
      this.setState({ isOpen: false });
      onModalConfirm && onModalConfirm(data);
    }

    render() {
      const { modalProps, onModalConfirm, ...props } = this.props;
      const { isOpen } = this.state;

      return (
        <React.Fragment>
          <WrappedComponent {...props} onClick={this.openModal}/>
          <Modal {...modalProps} appElement={document.getElementById("root")} isOpen={isOpen} confirm={this.confirmModal} close={this.closeModal} onClose={this.closeModal}/>
        </React.Fragment>
      )
    }
  }
};

export {
  withModal,
  AddToCollectionModal,
  ImageSelectionModal,
  DeleteConfirmationModal,
  AddToShowModal,
  AddToScreenModal,
  ImageUploadModal,
  AddComponentModal,
  AddCollectionItem,
  AddToChannelModal
};
