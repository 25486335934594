import gql from "graphql-tag";




export const GetChannels = gql`
  query GetChannels($after: Int, $first: Int, $query: String, $orderBy: ChannelOrder, $catalogId: ID) {
    channels(after: $after, first: $first, query: $query, orderBy: $orderBy, , catalogId: $catalogId) {
      nodes {
        id
        name
        status
        createdAt
        updatedAt
        items{
          totalCount
        }
      }
      totalCount
    }
  }`
;

export const GetChannel = gql`
  query GetChannel($id: ID!) {
    channel(id: $id) {
      id
      name
      header
      icon
      image
      createdAt
      updatedAt
      status
      start
      end
      items{
        totalCount
        nodes{
          randomized
          collection{
            id
            name
            status
            createdAt
            updatedAt
          }
        }
      }
    }
  }`
;

export const DeleteChannels = gql`
  mutation DeleteChannels($ids: [ID!]) {
    deleteChannels(ids: $ids)
  }`
;

export const CreateChannel = gql`
  mutation CreateChannel($input: CreateChannelInput!) {
    createChannel(input: $input) {
      channel {
        id
        name
      }
    }
  }`
;

export const UpdateChannel = gql`
mutation UpdateChannelItem($input: UpdateChannelInput!) {
  updateChannel(input: $input) {
    channel {
        id
        name
        header
        icon
        image
      }
    }
  }`
;

export const UpdateChannelItemAvailability = gql`
  mutation UpdateChannelItem($input: UpdateChannelInput!) {
    updateChannel(input: $input) {
      channel {
        id
        status
        start
        end
      }
    }
  }`
;


