import React from "react"
import { Query, Mutation } from "react-apollo"
import { UpdateScreen, GetScreen } from "../../graphql/screen-queries";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import { withRouter } from "react-router-dom";
import { PageIndex, PageIndexButton } from "../../components/PageIndex/PageIndex";
import Panel from "../../components/Panel/Panel";
import EditGroup from "../../components/EditGroup/EditGroup";
import { Input } from "../../components/ControlledComponents";
import { Button } from "@brightcove/studio-components";
import Loading from "../../components/Loading/Loading";
import AvailabilityPanel from "../../components/Panel/AvailabilityPanel";
import _ from "lodash";
import Details from "../Details/Details";
import Row from "../../components/Row/Row";
import ImageListPanel from "../../components/Panel/ImageListPanel";
import DisplayItemPanel from "../../components/Panel/DisplayItemPanel";
import ScreenContentsPanel from "../../components/Panel/ScreenContentsPanel";
import { withModal, DeleteConfirmationModal } from "../../components/Modals";
import { UpdateContentItemAvailability } from "../../graphql/content-queries";
import { ToasterManager } from "../../utils";

const defaultModel = {
  tile: {
    image: {}
  },
  slide: {
    image: {}
  },
  components: []
};
const canBeActivated = (screen) => {
  return screen.screenType == "STATIC" || screen.components.length;
};
const DeleteButton = withModal(Button, DeleteConfirmationModal);
const ActivationButton: React.FunctionComponent<any> = ({ screen, onCompleted, onError }) => {
  let props = {
    text: screen.status == "ACTIVE" ? "Deactivate": "Activate",
    pink: screen.status == "ACTIVE",
    disabled: !canBeActivated(screen)
  };

  return (
    <Mutation mutation={UpdateContentItemAvailability} onCompleted={onCompleted} onError={onError}>
      {(save) => {
        return <Button {...props} onClick={() => {
          save({ variables: {
            input: {
              id: screen.id,
              type: screen.__typename.toUpperCase(),
              status: screen.status == "ACTIVE" ? "INACTIVE" : "ACTIVE",
              start: screen.start,
              end: screen.end
            }
          }})
        }} />
      }}
    </Mutation>
  )
};

class Screen extends Details {
  render() {
    const { history, match } = this.props

    return (
      <Query query={GetScreen} variables={{ id: match.params.id }}>
        {({ loading, error, data }) => {
          let screen: any = data && data.screen || defaultModel;
          if(error) ToasterManager.error(error)

          return (
            <React.Fragment>
              <SectionHeader
                title={screen.name}
                subtitleAbove="Back"
                black
                onSubtitleClick={() => { history.goBack() }}
                buttons={[
                  <ActivationButton screen={screen} onCompleted={this.onCompleted} onError={this.onError} />,
                  <DeleteButton
                    text="Delete"
                    pink
                    onModalConfirm={() => { history.goBack() }}
                    modalProps={{selections: [screen], type: "screen"}}
                  />

                ]}
              />
              <div className="detail-main">
                <div className="detail-content">
                  { loading && <Loading /> }
                  <Panel
                    title="Screen Information"
                    id="screen"
                    mutation={UpdateScreen}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("screen")}
                    disabled={this.isDisabled("screen")}
                    changes={() => {
                      let current = _.pick(screen, ["id", "name", "metadata"]);
                      current.metadata = current.metadata && _.omit(current.metadata, "__typename");

                      return {
                        input: {
                        ...current,
                        ...this.state.changes
                        }
                      }
                    }}
                    onValidation={(changes) => {
                      let inputs = changes.input;
                      return inputs.name && inputs.name.length > 0 || false;
                    }}
                  >
                    <Row>
                      <EditGroup label="Screen Name" text={screen.name}>
                        <Input value={screen.name} property="name" onChange={this.onChange}/>
                      </EditGroup>
                      <EditGroup label="ID" text={screen.id}/>
                      <EditGroup label="Screen Type" text={screen.screenType}/>
                    </Row>
                  </Panel>

                  <AvailabilityPanel
                    content={screen}
                    statusDisabled={!canBeActivated(screen)}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("availability")}
                    disabled={this.isDisabled("availability")}
                  />

                  <ScreenContentsPanel
                    content={screen}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("contents")}
                    disabled={this.isDisabled("contents")}
                  />

                  <DisplayItemPanel
                    type="tile"
                    content={screen}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("tile")}
                    disabled={this.isDisabled("tile")}
                  />
                  
                  <DisplayItemPanel
                    type="slide"
                    content={screen}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("slide")}
                    disabled={this.isDisabled("slide")}
                  />

                  <ImageListPanel
                    content={screen}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("images")}
                    disabled={this.isDisabled("images")}
                  />

                </div>
                <PageIndex>
                  <PageIndexButton id="screen" text="Screen Information"/>
                  <PageIndexButton id="availability" text="Availability"/>
                  <PageIndexButton id="contents" text="Screen Contents"/>
                  <PageIndexButton id="tile" text="Tile"/>
                  <PageIndexButton id="slide" text="Hero Slide"/>
                  <PageIndexButton id="images" text="Images"/>
                </PageIndex>
              </div>
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
};

export default withRouter(Screen);
