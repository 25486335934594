import { Toaster } from "@brightcove/studio-components";


class ToasterManager {
  toasters: number[] = [];

  error = (message) => {
    this.toasters.push(Toaster.error(message));
  }

  info = (message) => {
    this.toasters.push(Toaster.info(message));
  }

  warn = (message) => {
    this.toasters.push(Toaster.warn(message));
  }

  dismiss = (id?) => {
    if(id && this.toasters.includes(id)) {
      Toaster.dismiss(id);
      this.toasters = this.toasters.filter(toasterId => toasterId != id)
    }
    else {
      this.toasters.forEach(id => {
        Toaster.dismiss(id);
      })
      this.toasters = [];
    }
  }
}

export default new ToasterManager();
