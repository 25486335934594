import React from "react";
import Panel from "./Panel"
import _ from "lodash";
import { GetChannel, UpdateChannel } from "../../graphql/channel-queries";
import { Icon, ICONS } from "@brightcove/studio-components";
import Table, { ChannelCollectionItemColumns } from "../Table";
import ToggleButton from "../ToggleButton/ToggleButton";
import { withModal, AddToCollectionModal } from "../Modals";
import { Checkbox as StudioCheckbox, CheckboxProps } from "@brightcove/studio-components";
import "./ChannelCollection.scss";
const AddItemButton = withModal(ToggleButton, AddToCollectionModal);

export default class ChannelCollectionPanel extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      changes:{},
      items: props.content.items.nodes
    };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.content.items, this.props.content.items)) {
      this.setState({
        items: this.props.content.items.nodes
      })
    }
    if (prevProps.editing != this.props.editing) {
      this.setState({
        changes: {},
        items: this.props.content.items.nodes
      })
    }
  }

  onDelete = (id) => {
    return () => {
      this.setState({
        items: this.state.items.filter(item => item.collection.id != id)
      })
    }
  }

  addItem = (item) => {
    let addData = item.map(item => {
      return { collection: item };
    })
    this.setState({ items: _.concat(this.state.items, addData) })
  }

  getChanges = () => {
    const { content } = this.props;
    const { items } = this.state;
    let collectionData = items.map(data => {
      return { collectionId: data.collection.id, randomized: data.randomized };
    })
    return {
      input: {
        id: content.id,
        items: collectionData
      }
    }
  }

  onChange = (item) => {
    let stateItems = this.state.items;
    stateItems.forEach(element => {
      if (element.collection.id == item.collection.id) {
        element.randomized = (item.randomized == true) ? false : true;
      }
    });
    this.setState({ items: stateItems });
  };

  getColumns = () => {
    if (this.props.editing) {
      let editColumn: any = {
        Header: "",
        accessor: "id",
        resizable: false,
        maxWidth: 220,
        sortable: true,
        Cell: (props) => {
          return (
            <div className="edit-buttons" >
              <div className="status-check-box">
                <StudioCheckbox className="checkbox" {...props} 
                  checked={props.row.randomized == true} 
                  onChange={this.onChange.bind(this, props.row)}>
                  Randomize 
                </StudioCheckbox>
              </div> 
              <div onClick={this.onDelete(props.row.collection.id)}><Icon name={ICONS.TRASHCAN} color="magenta" /></div>
              <div onMouseDown={props.tdProps.rest.onDragStart}><Icon name={ICONS.MOVE} color="teal" /></div>
            </div>
          )
        }
      };
      return _.concat(ChannelCollectionItemColumns, editColumn);
    }
    return ChannelCollectionItemColumns;
  }

  onDragEnd = (oldIndex, newIndex) => {
    let items = this.state.items.slice(0);
    let oldIndexElement = items[oldIndex];
    let newIndexElement = items[newIndex];
    items[oldIndex] = newIndexElement;
    items[newIndex] = oldIndexElement

    this.setState({ items })
  }

  refetch = (mutationResult) => {
    const { content } = this.props;
    return [{
      query: GetChannel,
      variables: { id: content.id }
    }]
  }

  onFetchData = (state, instance) => {
    const { onPageChange } = this.props;
    onPageChange({})
  }

  render() {
    const { onSave, onEdit, onCancel, editing, disabled, itemVariables } = this.props;
    const { items } = this.state;
    return (
      <Panel
        id="items"
        title="Channel Collections"
        changes={this.getChanges}
        mutation={UpdateChannel}
        onSave={onSave}
        onEdit={onEdit}
        onCancel={onCancel}
        editing={editing}
        disabled={disabled}
        refetch={this.refetch}
      >
        {
        items && items.length > 0 ?
        <div className="Channel-loop-msg">All content in the channel will loop automatically</div>
        : null
        }
        {
          items && items.length > 0 ?
            <Table
              className="editable-list"
              columns={this.getColumns()}
              data={items}
              manual
              onFetchData={this.onFetchData}
              showPagination={false}
              fullWidth
              draggable
              onDragEnd={this.onDragEnd}
            />
            : null
          }
        {editing && <AddItemButton type="add" text="Add Collection Item" onModalConfirm={this.addItem} modalProps={{ items, itemVariables }} />}
      </Panel>
    )
  }
};
