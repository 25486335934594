import React from 'react';
import _ from "lodash";
import './Selectable.scss';
import { RadioInput, Checkbox } from '@brightcove/studio-components';

const defaultArrFn = () => ([]);
const defaultObjFn = (selected, instance) => {};
const defaultConfirmFn = (selected) => { return true }

export default class Selectable {
  selected: Array<any> = [];
  selectedAll: boolean = false;
  allowMultiple: boolean;
  idField?: any;
  maxSelections?: number;
  instance: any;
  onSelection: Function;
  confirmSelection: Function;
  items: Function;

  constructor(idField: any = null, allowMultiple = true, initialSelections: any[] = [], maxSelections = -1, items: Function = defaultArrFn, onSelection = defaultObjFn, confirmSelection = defaultConfirmFn) {
    this.selected = initialSelections;
    this.maxSelections = maxSelections > 0 ? maxSelections : undefined;
    this.items = items;
    this.allowMultiple = allowMultiple;
    this.idField = idField;
    this.onSelection = onSelection;
    this.confirmSelection = confirmSelection;
    this.instance = { selectAll: this.selectAll, deselectAll: this.deselectAll };

    if(this.selected.length) {
      this.onSelection(this.selected, this.instance);
    }
  }

  getID = (item) => {
    return this.idField && item[this.idField] || item
  };

  isSelected = (item) => {
    let id = this.getID(item)
    return this.selected.filter(item => (this.getID(item) == id)).length > 0;
  };

    getTrProps = (state, rowInfo, column) => {
      if(!rowInfo) {
        return {};
      }
      else {
        let selected = this.isSelected(rowInfo.original);
        return { className: selected ? "selectable-selected" : null };
      }
    };

    selectAll = () => {
      if(!this.allowMultiple || this.maxSelections || !this.confirmSelection(this.items())) return;
      this.selected = this.items().slice(0);
      this.selectedAll = true;
      this.onSelection(this.selected, this.instance);
    }

    deselectAll = () => {
      this.selected = [];
      this.selectedAll = false;
      this.onSelection(this.selected, this.instance);
    };

    onSelectAll = (event) => {
      if(this.selectedAll) {
        this.selected = [];
      }
      else if(!this.confirmSelection(this.items())) {
        return;
      }
      else {
        this.selected = this.items().slice(0);
      }
      this.selectedAll = !this.selectedAll;
      this.onSelection(this.selected, this.instance);
    };

    onSelect = (item) => {
      return (event) => {
        if(!this.confirmSelection([item])) {
          return;
        }
        if(!this.allowMultiple) {
          this.selected = [item];
        }
        else {
          let index = -1;
          let id = this.getID(item);
          this.selected.forEach((item, idx) => {
            if(this.getID(item) == id)
              index = idx;
          });

          if(index > -1) {
            this.selected.splice(index, 1);
            this.selectedAll = false;
          }
          else if(!(this.maxSelections && this.selected.length == this.maxSelections))
            this.selected.push(item);
        }
        this.onSelection(this.selected, this.instance);
      }
    };

    decorateColumns = (columns) => {
      var selectionColumn = {
        Header: () => {
          return (this.allowMultiple && !this.maxSelections) ? <Checkbox className="selectable-checkbox" label="" checked={this.selectedAll} onChange={this.onSelectAll}> </Checkbox> : null
        },
        minWidth: 50,
        maxWidth: 50,
        resizable: false,
        sortable: false,
        headerClassName: "selectable-column-header",
        className: "selectable-column",
        Cell: ({original}) => {
          let selected = this.isSelected(original);

          return (this.allowMultiple || this.maxSelections) ?
            <div><Checkbox className="selectable-checkbox" checked={selected} label="" onChange={this.onSelect(original)}> </Checkbox></div> :
            <div><RadioInput className="selectable-radio" checked={selected} name="selectable" onChange={this.onSelect(original)}/></div>
        }
      };
      return _.concat([selectionColumn], columns);
    };
};
