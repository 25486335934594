import React from "react";
import { PlainTextArea as StudioPlainTextArea, PlainTextAreaProps } from "@brightcove/studio-components";
import ControlledProps from "./ControlledProps";
import classnames from "classnames";

export default class TextArea extends React.Component<PlainTextAreaProps & ControlledProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value || ""
    }
  }

  componentDidMount() {
    const { onChange, property } = this.props;
    const { value } = this.state;
    onChange && onChange(value, property);
  }

  onChange = (value) => {
    const { onChange, property } = this.props;
    this.setState({value})
    onChange && onChange(value, property);
  }

  render() {
    const { property, className, ...props } = this.props;
    return (
      <StudioPlainTextArea className={classnames(["textarea", className])} rows={5} {...props} value={this.state.value} onChange={this.onChange}>
        {this.props.children}
      </StudioPlainTextArea>
    )
  }
}
