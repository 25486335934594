import React from "react";
import { Query, compose } from "react-apollo";
import { withRouter } from "react-router-dom";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import SectionControls from "../../components/SectionControls/SectionControls";
import { Button } from "@brightcove/studio-components";
import Table, { AllChannelsColumns, ChannelModalColumns } from "../../components/Table";
import { SVGIcon } from "../../icons";
import { GetChannels } from "../../graphql/channel-queries";
import Loading from "../../components/Loading/Loading";
import { withModal, DeleteConfirmationModal } from "../../components/Modals";
import _ from "lodash";
import ModalControls from "../../components/ModalControls/ModalControls";
import { SearchInput } from "../../components/ControlledComponents";
import { handleErrors, formatFetchData } from "../../utils";
import { withStore } from "../../store";

class Channels extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      type: props.type || null,
      searchQuery: "",
      selections: [],
      first: 20,
      after: 0,
      orderBy: { field: "UPDATED_AT", direction: "DESC" }
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.location.pathname != this.props.location.pathname) {
      this.setState({
        searchQuery: "",
        selections: [],
        first: 20,
        after: 0,
        orderBy: { field: "UPDATED_AT", direction: "DESC" }
      })
    }
  }

  onSearch = (searchQuery) => {
    this.setState({ searchQuery, selections: [] })
  }

  onRowSelect = (selections, instance) => {
    const { onRowSelect } = this.props;
    const { deselectAll } = instance;
    this.setState({ selections, deselectAll })
    onRowSelect && onRowSelect(selections);
  }

  onFetchData = (state, instance) => {
    this.setState(formatFetchData(state, this.state.orderBy))
  }

  render() {
    const { history, embedded, store: { catalog } } = this.props;
    const { selections, deselectAll, first, after, orderBy, type, searchQuery: query } = this.state;
    const DeleteButton = withModal(Button, DeleteConfirmationModal);

    return (
      <Query query={GetChannels} notifyOnNetworkStatusChange variables={{first, after, query, orderBy, catalogId: catalog.id }} fetchPolicy="network-only">
        {({ loading, error, data: resp, refetch }) => {
          let channels = resp && resp.channels;
          let data = channels && channels.nodes || [];
          let totalCount = channels && channels.totalCount || 0;
          let refresh = () => {
            deselectAll();
            refetch();
          };
          handleErrors(error);

          return (
            <div className="main">
              <div className="content">
                { loading && <Loading /> }
                {
                  embedded ?
                    <ModalControls>
                      <SearchInput noPad placeholder="Search..." onChange={this.onSearch}/>
                    </ModalControls>
                  :
                    <React.Fragment>
                      <SectionHeader
                        teal
                        icon={SVGIcon.Channels}
                        title="Channels"
                        onSearch={this.onSearch}
                      />
                      <SectionControls>
                        <DeleteButton
                          pink
                          text="Delete"
                          disabled={!selections.length}
                          modalProps={{ type: "channel", selections }}
                          onModalConfirm={refresh}
                        />
                        <Button text="Create Channel" onClick={() => { history.push("/channels/new") }} />
                      </SectionControls>
                    </React.Fragment>
                }
                <Table
                  key={query}
                  fullWidth
                  selectable
                  singleSelection={embedded}
                  manual
                  onFetchData={this.onFetchData}
                  pageSizeOptions={[20, 30, 40, 50]}
                  onSelection={this.onRowSelect}
                  columns={embedded ? ChannelModalColumns : AllChannelsColumns}
                  totalCount={totalCount}
                  data={data}
                />
              </div>
            </div>
          )
        }}
      </Query>
    )
  }
};

export default compose(withStore, withRouter)(Channels);
