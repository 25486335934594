import React from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../../utils";
import { Icon, ICONS } from "@brightcove/studio-components";
import classnames from "classnames";
import _ from "lodash";

export const AllShowsColumns = [
  {
    Header: "Programs",
    minWidth: 100,
    resizable: false,
    accessor: "tile.image.url",
    Cell: ({ value }) => {
      let className = classnames({"table-image": true, placeholder: !value});
      return <img className={className} src={value} />
    }
  },
  {
    Header: () => {
      return <React.Fragment>Name/Title<span className="language-code">en</span></React.Fragment>
    },
    accessor: "name",
    minWidth: 320,
    Cell: ({original: { name, id }}) => {
      return (
        <div className="name-id">
          <div><Link to={`/show/${id}`}>{name}</Link></div>
          <div>{id}</div>
        </div>
      )
    }
  },
  {
    Header: "Status",
    accessor: "status",
    resizable: false,
    minWidth: 75,
    maxWidth: 75,
    style: { display: "block", textAlign: "center" },
    Cell: ({value}) => {
      return value == "ACTIVE" ? <Icon name={ICONS.CHECKMARK} color='green'/> : <Icon name={ICONS.BAN} color='magenta'/>
    }
  },
  {
    Header: "Created",
    accessor: "createdAt",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  },
  {
    Header: "Updated",
    accessor: "updatedAt",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  }
];

export const ShowModalColumns = [
  {
    Header: "Programs",
    minWidth: 100,
    resizable: false,
    accessor: "tile.image.url",
    Cell: ({ value }) => {
      let className = classnames({"table-image": true, placeholder: !value});
      return <img className={className} src={value} />
    }
  },
  {
    Header: () => {
      return <React.Fragment>Name/Title<span className="language-code">en</span></React.Fragment>
    },
    accessor: "name",
    minWidth: 320,
    Cell: ({original: { type, name, id }}) => {
      return (
        <div className="name-id">
          <div>{name}</div>
          <div>{id}</div>
        </div>
      )
    }
  },
  {
    Header: "Status",
    accessor: "status",
    resizable: false,
    minWidth: 75,
    maxWidth: 75,
    style: { display: "block", textAlign: "center" },
    Cell: ({value}) => {
      return value == "ACTIVE" ? <Icon name={ICONS.CHECKMARK} color='green'/> : <Icon name={ICONS.BAN} color='magenta'/>
    }
  },
  {
    Header: "Created",
    accessor: "createdAt",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  },
  {
    Header: "Updated",
    accessor: "updatedAt",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  }
];

export const SeasonColumns = [
  {
    Header: "",
    minWidth: 40,
    maxWidth: 40,
    resizable: false,
    accessor: "seasonNumber"
  },
  {
    Header: "Name",
    resizable: false,
    accessor: "name",
    Cell: ({original: { name, id }}) => {
      return (
        <div className="name-id">
          <div><Link to={`/season/${id}`}>{name}</Link></div>
          <div>{id}</div>
        </div>
      )
    }
  },
  {
    Header: "Status",
    accessor: "status",
    resizable: false,
    minWidth: 75,
    maxWidth: 75,
    style: { display: "block", textAlign: "center" },
    Cell: ({value}) => {
      return value == "ACTIVE" ? <Icon name={ICONS.CHECKMARK} color='green'/> : <Icon name={ICONS.BAN} color='magenta'/>
    }
  },
  {
    Header: "Start Date",
    accessor: "start",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  },
  {
    Header: "End Date",
    accessor: "end",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  }
];
