import React from "react"
import { UpdateServiceMessage, CreateServiceMessage, GetServiceMessages } from "../../graphql/service-message-queries";
import Panel from "../../components/Panel/Panel";
import EditGroup from "../../components/EditGroup/EditGroup";
import { TextArea, RadioGroup, CheckboxGroup } from "../../components/ControlledComponents";
import { Button } from "@brightcove/studio-components";
import { Column, Columns }from "../../components/Columns/Columns";
import SchedulePicker from "../../components/SchedulePicker/SchedulePicker";
import { withStore } from "../../store";
import { windowText, getTextFromOptions, PlatformOptions } from "../../utils";
const DeleteButton = withModal(Button, DeleteConfirmationModal);
import { withModal, DeleteConfirmationModal } from "../../components/Modals";
import "./ServiceMessagePanel.scss";
import _ from "lodash";

class ServiceMessagePanel extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      changes: null
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.editing && !this.props.editing) {
      this.setState({ changes: null })
    }
  }

  onChange = (change, property) => {
    if(property == "window") {
      this.setState({
        changes: {
          ...this.state.changes,
          ...change
        }
      })
    }
    else {
      this.setState({
        changes: {
          ...this.state.changes,
          [property]: change
        }
      })
    }
  }

  getChanges = () => {
    const { unsaved, message } = this.props;
    const { changes } = this.state;

    return {
      input: {
        id: unsaved ? undefined: message.id,
        ..._.omit(message, ["id", "__typename"]),
        ...changes
      }
    }
  }

  getRefetch = () => {
    return [{
      query: GetServiceMessages
    }]
  }

  render() {
    const { id, refetch, unsaved, onUnsavedDelete, message, store: { language }, ...props } = this.props;

    return (
      <Panel
        id={id}
        title="Service Message"
        className="service-message"
        mutation={unsaved ? CreateServiceMessage : UpdateServiceMessage}
        {...props}
        onCancel={onUnsavedDelete}
        changes={this.getChanges}
        refetch={this.getRefetch}
        titleBar={
          unsaved ?
          <Button text="Delete" pink small onClick={onUnsavedDelete} /> :
          <DeleteButton
            text="Delete"
            pink
            small
            onModalConfirm={() => { refetch() }}
            modalProps={{selections: [message], type: "service message"}}
          />
        }
      >
        <Columns>
          <Column>
            <EditGroup label="ID" text={unsaved ? undefined : message.id} />
            <EditGroup label="Message" text={message.message}>
              <TextArea property="message" value={message.message} onChange={this.onChange}/>
            </EditGroup>
          </Column>
          <Column>
            <EditGroup
              label="Platforms"
              html={(message.platforms || []).map(p => getTextFromOptions(p, PlatformOptions)).join("\n")}
            >
              <CheckboxGroup
                property="platforms"
                onChange={this.onChange}
                values={(message.platforms || [])}
                options={PlatformOptions}
              />
            </EditGroup>
          </Column>
          <Column>
            <EditGroup label="Status" text={message.status}>
              <RadioGroup
                name="status radio"
                inline
                value={message.status}
                property="status"
                onChange={this.onChange}
                options={[{label: "Active", value: "ACTIVE"}, {label: "Inactive", value: "INACTIVE"}]}
              />
            </EditGroup>
            <EditGroup label="License Window" text={windowText(message.start, message.end, language)}>
              <SchedulePicker start={message.start} end={message.end} onChange={this.onChange}/>
            </EditGroup>
          </Column>
        </Columns>
      </Panel>
    )
  }
}

export default withStore(ServiceMessagePanel);
