import React from "react";
import { PlainRadioGroup as StudioPlainRadioGroup, PlainRadioGroupProps } from "@brightcove/studio-components";
import ControlledProps from "./ControlledProps";
import classnames from "classnames";

export default class RadioGroup extends React.Component<PlainRadioGroupProps & ControlledProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value
    }
  }

  componentDidMount() {
    const { onChange, property } = this.props;
    const { value } = this.state;
    onChange && onChange(value, property);
  }

  onChange = (value) => {
    const { onChange, property } = this.props;
    this.setState({value})
    onChange && onChange(value, property);
  }

  render() {
    const { property, className, ...props } = this.props;
    return (
      <StudioPlainRadioGroup className={classnames(["radio-group", className])} inline {...props} value={this.state.value} onChange={this.onChange}>
        {this.props.children}
      </StudioPlainRadioGroup>
      )
  }
}
