import React from "react";
import Panel from "./Panel"
import _ from "lodash";
import EditGroup from "../EditGroup/EditGroup";
import { UpdateImage } from "../../graphql/image-queries";
import { Input, Select, ItemListInput } from "../ControlledComponents";
import { AddToShowModal, AddToScreenModal } from "../Modals";
import { Progress, Icon, ICONS } from "@brightcove/studio-components";
import { isValidImage, ToasterManager } from "../../utils";
import "./ImagePanel.scss";

export default class ImagePanel extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      changes: {},
      invalidImage: false
    };
  }

  componentDidMount() {
    this.props.upload && this.props.upload();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.editing && !this.props.editing) {
      this.setState({
        changes: {},
        invalidImage: false
      })
    }
  }

  getImage = () => {
    return this.props.image || { contentItems: [] };
  }

  onChange = (change, property) => {
    let image = this.getImage();
    let invalidImage = this.state.invalidImage;

    if(property == "type") {
      let valid = isValidImage(image.width, image.height, change);
      invalidImage = valid.result == false;

      if(invalidImage) {
        this.setState({ invalidImage: true })
        ToasterManager.error(valid.error!)
        return;
      }
    }

    ToasterManager.dismiss();
    this.setState({
      changes: {
        ...this.state.changes,
        [property]: change,
      },
      invalidImage: invalidImage
    })
  }


  getChanges = () => {
    const { changes } = this.state;
    const image = this.getImage();
    let updatedImage = {
      ...image,
      ...changes,
    }
    if(changes.shows)
      updatedImage.contentItems = _.concat(changes.shows, image.contentItems.filter(item => item.__typename == "Screen"))
    if(changes.screens)
      updatedImage.contentItems = _.concat(changes.screens, image.contentItems.filter(item => item.__typename == "Series"))
    updatedImage.contentItemIds = updatedImage.contentItems.map(item => item.id);

    return { input: _.omit(updatedImage, ["contentItems", "screens", "shows", "__typename"]) };
  }

  render() {
    const { image: _, file, progress, status, upload, ...props } = this.props;
    const { invalidImage } = this.state;
    const image = this.getImage();
    let shows = image.contentItems.filter(item => item.__typename == "Series");
    let screens = image.contentItems.filter(item => item.__typename == "Screen");

    return (
     (file && status != "SUCCESS") ?
        <Panel {...props} id="image">
          { !status ?
              <div className="upload-progress">
                <div className="upload-progress-label">{`Uploaded... ${progress}%`}</div>
                <Progress className="upload-progress-bar" complete={progress} />
                <EditGroup label="Source File name" text={file.name} />
              </div>
            :
              <div className="upload-progress-label">Upload Failed <Icon name={ICONS.BAN} color='magenta'/></div>
          }
        </Panel>
      :
        <Panel
          title="Image"
          id="Image"
          mutation={UpdateImage}
          {...props}
          changes={this.getChanges()}
          onValidation={(changes) => {
            if(!changes.input || invalidImage)
              return false;
            else if(changes.input.name != undefined)
              return changes.input.name.length > 0
            else
              return true;
          }}
        >
          { status == "SUCCESS" &&
            <div className="upload-progress-label"><span>{image.id}</span>Uploaded <Icon name={ICONS.CHECKMARK} color='green'/></div>
          }
          <EditGroup label="Image Name" text={image.name}>
            <Input value={image.name} property="name" onChange={this.onChange}/>
          </EditGroup>
          <EditGroup label="Image Type" text={image.type}>
            <Select
              property="type"
              value={image.type}
              onChange={this.onChange}
              options={[
                { label: "Banner", value: "BANNER" },
                { label: "General", value: "GENERAL" },
                { label: "Tile", value: "TILE" },
                { label: "Slide", value: "SLIDE" },
                { label: "Key Art", value: "KEYART" }
              ]}
            />
          </EditGroup>
          { file && status != "SUCCESS" && <EditGroup label="ID" text={image.id}/> }
          <EditGroup label="Shows" text={shows.map(show => show.name).join(", ")}>
            <ItemListInput
              property="shows"
              items={shows}
              getLabel={(item) => item.name}
              getId={(item) => item.id}
              addText="Add to Show"
              addModal={AddToShowModal}
              onChange={this.onChange}
            />
          </EditGroup>
          <EditGroup label="Screens" text={screens.map(screen => screen.name).join(", ")}>
            <ItemListInput
              property="screens"
              items={screens}
              getLabel={(item) => item.name}
              getId={(item) => item.id}
              addText="Add to Screen"
              addModal={AddToScreenModal}
              onChange={this.onChange}
            />
          </EditGroup>
          <EditGroup label="Image"image={image.url}/>
        </Panel>
    )
  }
};
