import moment from "moment";
import _ from "lodash";
import ToasterManager from "./ToasterManager";
import { TreeNode, evaluateTree } from "./RenderTree";

export const PlatformOptions = [
  { label: "Web", value: "WEB"},
  { label: "Roku", value: "ROKU"},
  { label: "Android", value: "ANDROID"},
  { label: "iPhone", value: "IPHONE"},
  { label: "Apple TV", value: "APPLE_TV"}
];

export const ImageRatios = {
  BANNER: undefined,
  GENERAL: undefined,
  TILE: { width: 16, height: 9 },
  SLIDE: { width: 16, height: 9 },
  KEYART: { width: 16, height: 9 }
};

export const isValidImage = (width, height, type) => {
  let target = ImageRatios[type];
  if(!target)
    return { result: true };

  let error = { message: `Incorrect image aspect ratio. Please choose another (${target.width}x${target.height} required)` };

  if(!(width || height))
    return { result: false, error };

  let targetRatio = target.width / target.height;
  let ratio = width / height;

  if(Math.abs(ratio - targetRatio) < 0.1)
    return { result: true };

  return { result: false, error };
}


export const handleErrors = (error) => {
  if(error) {
    if(error.graphQLErrors && error.graphQLErrors.length > 0)
      ToasterManager.error({ message: error.message })
    else if(error.networkError && error.networkError.type == "WriteError") {
      // location.reload()
      console.log(error.networkError.message)
    }
  }
};

export const formatDate = (date, withTime = true) => {
  let format = withTime ? "YYYY/DD/MM h:mm A" : "YYYY/DD/MM"
  return date ? moment(date).format(format) : "-"
};

export const adminContentType = (type, lowercase = false) => {
  let contentType;
  switch(type.toLowerCase()) {
    case "series": contentType = "Show"; break
    default: contentType = type;
  }
  return lowercase ? contentType.toLowerCase() : contentType;
};

export const getTextFromOptions = (key, ...args) => {
  let text = {}
  let options = _.concat(args[0])

  for(let option of options)
    text[option.value] = option.label;

  return text[key];
};

export const capitalize = (str) => {
  return _.startCase(_.toLower(str))
};

export const convertDate = (date) => {
  if(!date)
    return null;
  return date instanceof Date ? date : new Date(date);
};

export const windowText = (start, end, language) => {
  let startStr = start && formatDate(convertDate(start)!.toLocaleString(language)) || undefined;
  let endStr = end && formatDate(convertDate(end)!.toLocaleString(language)) || undefined;

  if(!(start || end))
    return "Always Available";
  else if(start && end)
    return `Available from ${startStr} to ${endStr}`;
  else if(!start)
    return `Available until ${endStr}`;
  else if(!end)
    return `Available starting ${startStr}`
};

export const formatFetchData = (state, currentOrderBy) => {
  let orderBy = currentOrderBy;

  if(state.sorted.length) {
    let id = state.sorted[0].id,
      field = id == "__typename" ? "TYPE" : _.toUpper(_.snakeCase(state.sorted[0].id)),
      direction = state.sorted[0].desc ? "ASC" : "DESC";

    orderBy = {
      field: field,
      direction: direction
    }
  }

  return {
    first: state.pageSize,
    after: state.pageSize * state.page,
    orderBy
  }
};

export const getImageDimensions = (source: File | string): Promise<{ name: string, width: number, height: number }> => {
  return new Promise(resolve => {
    let type = source instanceof File ? "FILE" : "URL";
    let name = source instanceof File ? source.name : source;
    let image: HTMLImageElement;

    let onImageLoad = () => {
      resolve({ name, width: image.width, height: image.height });
    };

    if(type == "URL") {
      image = new Image();
      image.onload = onImageLoad;
      image.src = source as string;
    }
    else {
      let reader = new FileReader();
      reader.onload = (event) => {
        image = new Image();
        image.onload = onImageLoad
        image.src = event && event.target && (event.target as any).result;
      }
      reader.readAsDataURL(source as File)
    }
  })
};

export const getComponentType = (type) => {
  return type == "COMINGSOON" ? "Coming Soon" : capitalize(type);
}

export const formatDuration = (milliseconds) => {
  let totalSeconds = milliseconds / 1000;
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds % 3600) / 60);
  let seconds = Math.ceil(totalSeconds - (hours * 3600) - (minutes * 60));

  if(seconds == 60) {
    minutes += 1;
    seconds = 0;
  }

  let duration = "";
  if(hours) duration += `${hours}h `;
  if(minutes) duration += `${minutes}m `
  if(seconds && !hours) duration += `${seconds}s`;

  return duration;
}

export { ToasterManager, evaluateTree }
export type TreeNode = TreeNode;
