import React from "react";
import "./Column.scss";
import { Flex, FlexItem } from "@brightcove/studio-components";

const Columns: React.FunctionComponent<any> = ({children, gap, ...props}) => {
  return (
    <Flex className="columns" gap={"medium"}>
      { React.Children.map(children, child => { return React.cloneElement(child, {...props}) }) }
    </Flex>
  )
}

const Column: React.FunctionComponent<any> = ({children, flex, number, ...props}) => {
  return (
    <FlexItem className="column" flex={flex || "1 1 auto"}>
      { React.Children.map(children, child => { return React.cloneElement(child, {...props}) }) }
    </FlexItem>
  )
};

export { Columns, Column };
