import React from "react";
import { Link } from "react-router-dom";
import { formatDate, adminContentType } from "../../../utils";
import { Icon, ICONS } from "@brightcove/studio-components";
import classnames from "classnames";

export const AllCollectionsColumns = [
  {
    Header: "Collection Name",
    accessor: "name",
    minWidth: 320,
    Cell: ({original: { type, name, id }}) => {
      return (
        <div className="name-id">
          <div><Link to={`/collection/${id}`}>{name}</Link></div>
          <div>{id}</div>
        </div>
      )
    }
  },
  {
    Header: "Type",
    accessor: "type",
    minWidth: 100,
    resizable: false
  },
  {
    Header: "Status",
    accessor: "status",
    resizable: false,
    minWidth: 75,
    maxWidth: 75,
    style: { display: "block", textAlign: "center" },
    Cell: ({value}) => {
      return value == "ACTIVE" ? <Icon name={ICONS.CHECKMARK} color='green'/> : <Icon name={ICONS.BAN} color='magenta'/>
    }
  },
  {
    Header: "Created",
    accessor: "createdAt",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  },
  {
    Header: "Updated",
    accessor: "updatedAt",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  }
];


export const CollectionModalColumns = [
  {
    Header: () => {
      return <React.Fragment>Collection Name<span className="language-code">en</span></React.Fragment>
    },
    accessor: "name",
    minWidth: 320,
    Cell: ({original: { type, name, id }}) => {
      return (
        <div className="name-id">
          <div>{name}</div>
          <div>{id}</div>
        </div>
      )
    }
  },
  {
    Header: "Status",
    accessor: "status",
    resizable: false,
    minWidth: 75,
    maxWidth: 75,
    style: { display: "block", textAlign: "center" },
    Cell: ({value}) => {
      return value == "ACTIVE" ? <Icon name={ICONS.CHECKMARK} color='green'/> : <Icon name={ICONS.BAN} color='magenta'/>
    }
  },
  {
    Header: "Created",
    accessor: "createdAt",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  },
  {
    Header: "Updated",
    accessor: "updatedAt",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  }
];

export const CollectionItemColumns = [
  {
    Header: "Collection Item",
    accessor: "name",
    minWidth: 320,
    Cell: ({ original: contentItem }) => {
      let image = contentItem.__typename == "SCREEN" ?
        contentItem.slide && contentItem.slide.image.url :
        contentItem.tile && contentItem.tile.image.url;

      let className = classnames({ "table-image": true, placeholder: !image });
      return (
        <div className="image-name-id">
          <img className={className} src={image} />
          <div className="name-id">
            <div><Link to={`/${adminContentType(contentItem.__typename, true)}/${contentItem.id}`}>{contentItem.name}</Link></div>
            <div>{contentItem.id}</div>
          </div>
        </div>
      )
    }
  },
  {
    Header: "Type",
    accessor: "__typename",
    minWidth: 120,
    resizable: false,
    Cell: ({value}) => {
      return adminContentType(value)
    }
  },
  {
    Header: "Status",
    accessor: "status",
    resizable: false,
    minWidth: 75,
    maxWidth: 75,
    style: { display: "block", textAlign: "center" },
    Cell: ({value}) => {
      return value == "ACTIVE" ? <Icon name={ICONS.CHECKMARK} color='green'/> : <Icon name={ICONS.BAN} color='magenta'/>
    }
  },
  {
    Header: "Start Date",
    accessor: "start",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  },
  {
    Header: "End Date",
    accessor: "end",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  }
];
