import React from "react";
import { RadioInput } from "@brightcove/studio-components";
import _ from "lodash";
import { withStore } from "../../store";
import { DateTimePicker } from 'react-widgets'
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/lib/scss/react-widgets.scss';
import { convertDate } from "../../utils";
import "./SchedulePicker.scss";

class SchedulePicker extends React.Component<any, any> {
  constructor(props) {
    super(props);
    Moment.locale(props.store.language || "en");
    momentLocalizer()
    let start = convertDate(props.start);
    let end = convertDate(props.end);

    this.state = {
      noStart: !start,
      start: start,
      noEnd: !end,
      end: end
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(!_.isEqual(prevState, this.state))
      this.props.onChange && this.props.onChange(_.pick(this.state, ["start", "end"]), "window")
  }

  changeTimeBound = (windowSide) => {
    return (noValue) => {
      if(windowSide == "start") {
        this.setState({ noStart: noValue, start: null })
      }
      else if(windowSide == "end") {
        this.setState({ noEnd: noValue, end: this.state.start && !noValue ? this.state.start : null })
      }
    }
  }

  changeTime = (windowSide) => {
    return (value) => {
      let { start, end } = this.state;

      if(windowSide == "start") {
        if(end && (end - value) <= 0)
          this.setState({ start: value, end: null, noEnd: true });
        else
          this.setState({ start: value })
      }
      else if(windowSide == "end") {
        this.setState({ end: value })
      }
    }
  }

  render() {
    const { start, end, noStart, noEnd } = this.state;

    return (
      <div className="schedule-picker">
        <div className="schedule-picker-bound">
          <div className="schedule-picker-label">Scheduled Start Time</div>
          <RadioInput checked={noStart} value={true} onChange={this.changeTimeBound("start")} name="start immediately">
            Available Immediately
          </RadioInput>
          <RadioInput className="schedule-radio" checked={!noStart} value={false} onChange={this.changeTimeBound("start")} name='start at date'>
            <DateTimePicker
              min={new Date()}
              disabled={noStart}
              value={start}
              placeholder="Select date"
              onChange={this.changeTime("start")}
            />
          </RadioInput>
        </div>
        <div className="schedule-picker-bound">
          <div className="schedule-picker-label">Scheduled End Time</div>
          <RadioInput checked={noEnd} value={true} onChange={this.changeTimeBound("end")} name="dont end">
            Available Indefinitely
          </RadioInput>
          <RadioInput className="schedule-radio" checked={!noEnd} value={false} onChange={this.changeTimeBound("end")} name='end at date'>
            <DateTimePicker
              disabled={noEnd}
              min={start}
              value={end}
              placeholder="Select date"
              onChange={this.changeTime("end")}
            />
          </RadioInput>
        </div>
    </div>
    )
  }
};

export default withStore(SchedulePicker);
