export const CHANGE_CATALOG = "CHANGE_CATALOG";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CLEAR = "CLEAR";

export const changeCatalog = (catalog, callback = undefined) => ({
  type: CHANGE_CATALOG,
  data: { catalog, callback }
});

export const changeLanguage = (language) => ({
  type: CHANGE_LANGUAGE,
  data: { language }
});

export const clearStore = () => ({
  type: CLEAR,
  data: null
});
