import React from "react";
import { Query } from "react-apollo";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import SectionControls from "../../components/SectionControls/SectionControls";
import Table, { ActivityLogColumns } from "../../components/Table";
import { SVGIcon } from "../../icons";
import { GetActivityLogsAndCatalogs } from "../../graphql/activity-log-queries";
import Loading from "../../components/Loading/Loading";
import _ from "lodash";
import { SearchInput, Select } from "../../components/ControlledComponents";
import { handleErrors, formatFetchData } from "../../utils";
import "./ActivityLogs.scss";
import EditGroup from "../../components/EditGroup/EditGroup";

export default class ActivityLogs extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      type: props.type || null,
      searchQuery: "",
      first: 20,
      after: 0,
      orderBy: { field: "CREATED_AT", direction: "DESC" }
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.location.pathname != this.props.location.pathname) {
      this.setState({
        searchQuery: "",
        first: 20,
        after: 0,
        orderBy: { field: "CREATED_AT", direction: "DESC" }
      })
    }
  }

  onChange = (value, property) => {
    this.setState({
      [property]: value == "" ? undefined : value
    })
  }

  onFetchData = (state, instance) => {
    this.setState(formatFetchData(state, this.state.orderBy))
  }

  render() {
    const { first, after, orderBy, operation, resourceId, user, success, catalogId } = this.state;
    return (
      <Query query={GetActivityLogsAndCatalogs} notifyOnNetworkStatusChange variables={{ first, after, orderBy, operation, resourceId, user, success, catalogId }} fetchPolicy="network-only">
        {({ loading, error, data: resp }) => {
          let activityLogs = resp && resp.activityLogs;
          let data = activityLogs && activityLogs.nodes || [];
          let totalCount = activityLogs && activityLogs.totalCount || 0;

          let catalogs = resp && resp.catalogs;
          let catalogList = _.concat(
            [{ label: "Global", value: "" }],
            (catalogs && catalogs.map(catalog => ({ label: catalog.name, value: catalog.id })) || [])
          )

          handleErrors(error);

          return (
            <div className="main">
              <div className="content">
                { (loading && !data) && <Loading /> }
                <React.Fragment>
                  <SectionHeader
                    teal
                    icon={SVGIcon.ActivityLog}
                    title="Activity History"
                  />
                  <SectionControls>
                    <div className="activity-filters-label"><span>FILTERS:</span></div>
                    <EditGroup label="Activities" editing>
                      <Select
                        gray
                        property="operation"
                        value=""
                        options={[
                          { label: "All", value: "" },
                          { label: "Episode", value: "episode" },
                          { label: "Season", value: "season" },
                          { label: "Show", value: "show" },
                          { label: "Image", value: "image" },
                          { label: "Screen", value: "screen" },
                          { label: "Collection", value: "collection" },
                          { label: "Dynamic String", value: "dynamicstring" },
                          { label: "Service Message", value: "servicemessage" },
                          { label: "Maintenance Mode", value: "maintenancemode" }
                        ]}
                        onChange={this.onChange}
                      />
                    </EditGroup>
                    <EditGroup label="Content ID" editing>
                      <SearchInput
                        placeholder="Filter by Content ID"
                        value=""
                        property="resourceId"
                        onChange={this.onChange}
                      />
                    </EditGroup>
                    <EditGroup label="User" editing>
                      <SearchInput
                        placeholder="Filter by User ID or Email"
                        value=""
                        property="user"
                        onChange={this.onChange}
                      />
                    </EditGroup>
                    <EditGroup label="Status" editing>
                      <Select
                        gray value=""
                        property="success"
                        options={[
                          { label: "All", value: "" },
                          { label: "Success", value: "true" },
                          { label: "Fail", value: "false" }
                        ]}
                        onChange={this.onChange}
                      />
                    </EditGroup>
                    <EditGroup label="Catalog" editing>
                      { catalogList &&
                        <Select gray property ="catalogId" value="" options={catalogList} onChange={this.onChange}/>
                      }
                    </EditGroup>
                  </SectionControls>
                </React.Fragment>
                <Table
                  fullWidth
                  manual
                  onFetchData={this.onFetchData}
                  pageSizeOptions={[20, 30, 40, 50]}
                  columns={ActivityLogColumns}
                  totalCount={totalCount}
                  data={data}
                />
              </div>
            </div>
          )
        }}
      </Query>
    )
  }
};
