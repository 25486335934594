import React from "react";
import { Query } from "react-apollo";
import { withRouter } from "react-router-dom";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import SectionControls from "../../components/SectionControls/SectionControls";
import { Button } from "@brightcove/studio-components";
import Table, { DynamicStringColumns } from "../../components/Table";
import { SVGIcon } from "../../icons";
import { GetDynamicStrings } from "../../graphql/dynamic-string-queries";
import Loading from "../../components/Loading/Loading";
import { withModal, DeleteConfirmationModal } from "../../components/Modals";
import _ from "lodash";
import { handleErrors, formatFetchData } from "../../utils";
import "./DynamicStrings.scss";

class DynamicStrings extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      type: props.type || null,
      searchQuery: "",
      selections: [],
      first: 20,
      after: 0,
      orderBy: { field: "UPDATED_AT", direction: "DESC" }
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.location.pathname != this.props.location.pathname) {
      this.setState({
        searchQuery: "",
        selections: [],
        first: 20,
        after: 0,
        orderBy: { field: "UPDATED_AT", direction: "DESC" }
      })
    }
  }

  onSearch = (searchQuery) => {
    this.setState({ searchQuery, selections: [] })
  }

  onRowSelect = (selections) => {
    const { onRowSelect } = this.props;
    this.setState({ selections })
    onRowSelect && onRowSelect(selections);
  }

  onFetchData = (state, instance) => {
    this.setState(formatFetchData(state, this.state.orderBy))
  }

  render() {
    const { history } = this.props;
    const { selections, first, after, orderBy, type, searchQuery: query } = this.state;
    const DeleteButton = withModal(Button, DeleteConfirmationModal);

    return (
      <Query query={GetDynamicStrings} notifyOnNetworkStatusChange variables={{first, after, query, orderBy, type}} fetchPolicy="network-only">
        {({ loading, error, data: resp, refetch }) => {
          let dynamicStrings = resp && resp.dynamicStrings;
          let data = dynamicStrings && dynamicStrings.nodes || [];
          let totalCount = dynamicStrings && dynamicStrings.totalCount || 0;
          let refresh = () => { refetch() };

          handleErrors(error);

          return (
            <div className="main">
              <div className="content">
                { loading && <Loading /> }
                <SectionHeader
                  teal
                  icon={SVGIcon.Catalog}
                  title="Dynamic Strings"
                  subtitle={(totalCount > 1 || !totalCount) ? `${totalCount} Strings` : `${totalCount} String`}
                  onSearch={this.onSearch}
                />
                <SectionControls>
                  <DeleteButton
                    pink
                    text="Delete"
                    disabled={!selections.length}
                    modalProps={{ type: "dynamic string", selections }}
                    onModalConfirm={refresh}
                  />
                  <Button text="Create a String" onClick={() => { history.push("/dynamic-strings/new") }} />
                </SectionControls>
                <Table
                  key={query}
                  className="dynamic-strings"
                  fullWidth
                  selectable
                  manual
                  onFetchData={this.onFetchData}
                  pageSizeOptions={[20, 30, 40, 50]}
                  onSelection={this.onRowSelect}
                  columns={DynamicStringColumns}
                  totalCount={totalCount}
                  data={data}
                />
              </div>
            </div>
          )
        }}
      </Query>
    )
  }
};

export default withRouter(DynamicStrings);
