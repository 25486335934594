import React from "react";
import { Query, compose } from "react-apollo";
import { withRouter } from "react-router-dom";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import SectionControls from "../../components/SectionControls/SectionControls";
import { Button } from "@brightcove/studio-components";
import Table, { StaticScreenColumns, ContentScreenColumns, ScreenModalColumns } from "../../components/Table";
import { SVGIcon } from "../../icons";
import { GetScreens } from "../../graphql/screen-queries";
import Loading from "../../components/Loading/Loading";
import _ from "lodash";
import ModalControls from "../../components/ModalControls/ModalControls";
import { SearchInput, Select } from "../../components/ControlledComponents";
import SideMenu, { SideMenuItem } from "../../components/SideMenu/SideMenu";
import { handleErrors, formatFetchData } from "../../utils";
import { withStore } from "../../store";

class Screens extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      selections: [],
      first: 20,
      after: 0,
      orderBy: { field: "UPDATED_AT", direction: "DESC" },
      filter: undefined
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.location.pathname != this.props.location.pathname) {
      this.setState({
        searchQuery: "",
        selections: [],
        first: 20,
        after: 0,
        orderBy: { field: "UPDATED_AT", direction: "DESC" },
        filter: undefined
      })
    }
  }

  onFilter = (filter) => {
    this.setState({ filter });
  }

  onSearch = (searchQuery) => {
    this.setState({ searchQuery, selections: [] })
  }

  onRowSelect = (selections) => {
    const { onRowSelect } = this.props;
    this.setState({ selections })
    onRowSelect && onRowSelect(selections);
  }

  onFetchData = (state, instance) => {
    this.setState(formatFetchData(state, this.state.orderBy))
  }

  render() {
    const { history, match, embedded, singleSelection, store: { catalog } } = this.props;
    const { first, after, orderBy, searchQuery: query, filter } = this.state;
    let type, excludeType, title, columns, icon, showFilter;

    if(embedded) {
      type = excludeType = undefined;
      columns = ScreenModalColumns
    }
    else {
      switch (match.params.type) {
        case "static":
          title = "Static Screens";
          type = "STATIC"
          columns = StaticScreenColumns;
          icon = SVGIcon.Image;
          break;

        default:
          title = "Content Screens"
          excludeType = "STATIC"
          columns = ContentScreenColumns;
          icon = SVGIcon.ContentScreen;
          showFilter = true;
          break;
      }
    }
    if(filter) {
      switch(filter) {
        case "ALL":
          excludeType = "STATIC"
          type = undefined;
          break;
        case "CONTENT":
          type = "CONTENT"
          excludeType = undefined;
          break;
        case "LIVE":
          type = "LIVE";
          excludeType = undefined;
          break;
      }
    }

    return (
      <Query query={GetScreens} notifyOnNetworkStatusChange variables={{first, after, type, excludeType, query, orderBy, catalogId: catalog.id}} fetchPolicy="network-only">
        {({ loading, error, data: resp }) => {
          let screens = resp && resp.screens;
          let data = screens && screens.nodes || [];
          let totalCount = screens && screens.totalCount || 0;

          handleErrors(error);

          return (
            <div className="main">
              { !embedded &&
                <SideMenu autoSelect>
                  <SideMenuItem link="/screens/content" icon={SVGIcon.NavContentScreen}>Content Screens</SideMenuItem>
                  <SideMenuItem link="/screens/static" icon={SVGIcon.NavImage}>Static Screens</SideMenuItem>
                </SideMenu>
              }
              <div className="content">
                { loading && <Loading /> }
                {
                  embedded ?
                    <ModalControls>
                      <SearchInput noPad placeholder="Search..." onChange={this.onSearch}/>
                    </ModalControls>
                  :
                    <React.Fragment>
                      <SectionHeader
                        teal
                        icon={icon}
                        title={title}
                        subtitle={(totalCount > 1 || !totalCount) ? `${totalCount} Screens` : `${totalCount} Screen`}
                        onSearch={this.onSearch}
                      />
                      <SectionControls>
                        <Button text="Create Screen" onClick={() => { history.push("/screens/new") }} />
                        { showFilter &&
                          <Select gray onChange={this.onFilter} value="ALL" options={[
                            { label: "All Types", value: "ALL" },
                            { label: "Content", value: "CONTENT" },
                            { label: "Live", value: "LIVE" }
                          ]} />
                        }
                      </SectionControls>
                    </React.Fragment>
                }
                <Table
                  key={`${type}${query}`}
                  fullWidth
                  manual
                  onFetchData={this.onFetchData}
                  pageSizeOptions={[20, 30, 40, 50]}
                  selectable={embedded}
                  onSelection={this.onRowSelect}
                  singleSelection={singleSelection}
                  columns={columns}
                  totalCount={totalCount}
                  data={data}
                />
              </div>
            </div>
          )
        }}
      </Query>
    )
  }
};

export default compose(withStore, withRouter)(Screens);
