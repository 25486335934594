import React from "react";
import { Pager, Select } from "@brightcove/studio-components";
import "./TablePager.scss";

export default class TablePager extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      page: props.page,
      pageSize: props.pageSize
    }
  }

  onPageSizeChange = (pageSize) => {
    this.setState({pageSize, page: 0})
    this.props.onPageSizeChange(pageSize, 0);
  }

  onPageChange = (page) => {
    this.setState({page})
    this.props.onPageChange(page);
  }

  getPaginationProps = () => {
    let { pageSizeOptions, totalCount } = this.props.getPaginationProps();
    let options = null;
    if(pageSizeOptions)
      options = pageSizeOptions.map(option => ({ label: `${option} Rows`, value: option }));
    return { options, totalCount };
  }

  render() {
    const { page, pageSize, ...props } = this.state;
    const { options, totalCount } = this.getPaginationProps();

    return (
      <div className="table-pager">
        { options &&
          <div className="table-pager-page-size">
            <span>Show:</span>
            <Select
              value={pageSize}
              options={options}
              onChange={this.onPageSizeChange}
              />
          </div>
        }
        <Pager
          className="table-pager-controls"
          currentPage={page}
          itemsPerPage={pageSize}
          totalItemCount={totalCount}
          onChange={this.onPageChange}
        />
      </div>
    )
  }
};
