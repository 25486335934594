import React from "react";
import { Query, compose } from "react-apollo";
import { withRouter } from "react-router-dom";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import Table, {
  ChannelItemColumns,
} from "../../components/Table";
import { GetCollections } from "../../graphql/collection-queries";
import Loading from "../../components/Loading/Loading";
import "./NewChannel.scss";
import _ from "lodash";
import { SearchInput } from "../../components/ControlledComponents";
import { handleErrors, formatFetchData } from "../../utils";
import { withStore } from "../../store";
import { Mutation } from "react-apollo";
import { Button } from "@brightcove/studio-components";
import { Panel as PanelChannel } from "../../components/Panel/Panel";
import { Panel as PanelCollection } from "@brightcove/studio-components";
import EditGroup from "../../components/EditGroup/EditGroup";
import { Input, Select, Checkbox } from "../../components/ControlledComponents";
import { CreateChannel } from "../../graphql/channel-queries";
import { ToasterManager } from "../../utils";
import Details from "../Details/Details";


const SaveButton: React.FunctionComponent<any> = ({
  changes,
  data,
  onCompleted,
  onError,
  ...props
}) => {
  return (
    <Mutation
      mutation={CreateChannel}
      onCompleted={onCompleted}
      onError={onError}
    >
      {(save) => {
        let onClick = () => {
          let collectionData = data.map(data => {
            return { collectionId : data.id};
          })
          let variables = {
            input: {
              ...changes,
              items: collectionData,
              status: "INACTIVE",
            },
          };
          save({ variables: variables });
        };
        return <Button {...props} onClick={onClick} />;
      }}
    </Mutation>
  );
};

class Channels extends Details {
  constructor(props) {
    super(props);
    this.state = {
      changes: {},
      searchQuery: "",
      selections: [],
      after: 0,
      type:"SCHEDULE",
      orderBy: { field: "UPDATED_AT", direction: "DESC" },
    };
  }

  onChange = (change, property) => {
    ToasterManager.dismiss();
    this.setState(
      {
        changes: {
          ...this.state.changes,
          [property]: change,
        },
      });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname != this.props.location.pathname) {
      this.setState({
        searchQuery: "",
        selections: [],
        after: 0,
        orderBy: { field: "UPDATED_AT", direction: "DESC" },
      });
    }
  }

  onSearch = (searchQuery) => {
    this.setState({
      searchQuery,
      selections: this.state.selections,
      isSearchClear: searchQuery == ""
    });
  };

  onRowSelect = (selections) => {
    const { onRowSelect } = this.props;
    this.setState({ selections });
    onRowSelect && onRowSelect(selections);
  };

  confirmRowSelect = (selections) => {
    return this.props.confirmRowSelect ? this.props.confirmRowSelect(selections) : true;
  }

  onFetchData = (state, instance) => {
    this.setState(formatFetchData(state, this.state.orderBy));
  };

  onError = (error) => {
    ToasterManager.error(error);
  };

  onSave = ({ data, error }) => {
    if (error) {
      this.onError(error);
    }
    else {
      this.onCompleted(data);
    }
  }

  onCompleted = (data) => {
    if (!(data && data.createChannel && data.createChannel.channel)) {
      ToasterManager.error({
        message: "Something went wrong. Please check the console for details.",
      });
    } else {
      const { history } = this.props;
      history.replace(`/channel/${data.createChannel.channel.id}`);
    }
  };

  render() {
    const {
      history,
      store: { catalog },
    } = this.props;
    const {
      changes,
      selections,
      first,
      after,
      orderBy,
      isSearchClear,
      type,
      searchQuery: query,
    } = this.state;
    
    return (
      <Query
        query={GetCollections}
        notifyOnNetworkStatusChange
        variables={{
          first,
          after,
          query,
          orderBy,
          type,
          catalogId: catalog.id,
        }}
      >
        {({ loading, error, data: resp, refetch }) => {
          let collections = resp && resp.collections;
          let data = (collections && collections.nodes) || [];
          let totalCount = collections && collections.totalCount || 0;
          let selectionsIns = this.state.selections;
          // preserve already selected collections in UI when search is cleared.
          if (this.state.isSearchClear) {
            for (let i = 0; i < data.length; i++) {
              let modifiedCell = selectionsIns.find(x => x.id === data[i].id);
              if (modifiedCell) {
                data[i] = modifiedCell;
              }
            }
            this.setState({ isSearchClear: false });
          }
          let refresh = () => {
            refetch();
          };
          let titleBar = <SearchInput className="search"
            placeholder="Search..." onChange={this.onSearch} />
          handleErrors(error);
          return (
            <div className="main">
              <div className="content">
                {loading && <Loading />}
                {
                  <React.Fragment>
                    <SectionHeader
                      title="New Channel"
                      subtitleAbove="Back"
                      black
                      onSubtitleClick={() => {
                        history.goBack();
                      }}
                    />
                    <div className="channel-detail-main">
                      <div className="detail-content">
                        <PanelCollection title="Channel Info">
                          <EditGroup label="Channel Name" editing required >
                            <Input
                              value=""
                              property="name"
                              onChange={this.onChange}
                            />
                          </EditGroup>
                          <EditGroup editing>
                            <Checkbox
                              checked={false}
                              label="Randomize schedule order"
                              property="randomized"
                              onChange={this.onChange}
                            />
                          </EditGroup>
                        </PanelCollection>
                      </div>
                      <div className="detail-content-table">
                        <PanelChannel
                          title="ADD COLLECTIONS AS SUB CHANNELS *"
                          id="collections"
                          uneditable
                          titleBar={titleBar}
                        >
                          <Table
                            className="collections"
                            fullWidth
                            selectable
                            onSelection={this.onRowSelect}
                            confirmSelection={this.confirmRowSelect}
                            showPagination={false}
                            TheadComponent={_ => null}
                            columns={ChannelItemColumns}
                            totalCount={totalCount}
                            data={data} />
                        </PanelChannel>
                      </div>
                    </div>
                    <div className="channel-create-footer">
                      <Button className="create-cancel"
                        small
                        invert
                        text="Cancel"
                        onClick={() => {
                          history.goBack();
                        }}
                      />
                      <SaveButton className="create-save"
                        small
                        disabled={!changes.name || !selections.length}
                        text="Create Channel"
                        changes={changes}
                        data={selections}
                        onError={this.onError}
                        onCompleted={this.onCompleted}
                      />
                    </div>
                  </React.Fragment>
                }
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default compose(withStore, withRouter)(Channels);