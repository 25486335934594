import React from "react";
import Panel, { PanelProps } from "./Panel"
import _ from "lodash";
import Table, { ImageColumns } from "../Table"
import { Icon, ICONS, Button } from "@brightcove/studio-components";
import "./ImageListPanel.scss";
import { withModal, ImageUploadModal } from "../Modals";
import { UpdateContentItemImages } from "../../graphql/content-queries"

const UploadButton = withModal(Button, ImageUploadModal);

export default class ImageListPanel extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      images: props.content.images
    }
  }

  componentDidUpdate(prevProps) {
    if(!_.isEqual(prevProps.content.images, this.props.content.images) || prevProps.editing != this.props.editing) {
      this.setState({ images: this.props.content.images })
    }
  }

  onDelete = (id) => {
    return () => {
      this.setState({ images: this.state.images.filter(image => image.id != id) })
    }
  }

  getChanges = () => {
    const { content } = this.props;
    const { images } = this.state;
    return {
      input: {
        id: content.id,
        type: content.__typename && content.__typename.toUpperCase(),
        imageIds: images && images.map(image => image.id)
      }
    }
  }

  getColumns = () => {
    if(this.props.editing) {
      let editColumn = {
        Header: "",
        accessor: "id",
        resizable: false,
        maxWidth: 50,
        sortable: true,
        Cell: ({ value }) => {
          return (
            <div className="image-delete-button" onClick={this.onDelete(value)}>
              <Icon name={ICONS.TRASHCAN} color="magenta"/>
              </div>
            )
        }
      };
      return _.concat(ImageColumns, editColumn);
    }
    return ImageColumns;
  }

  render() {
    const { content, ...props } = this.props;
    const { images } = this.state;
    let titleBar = <UploadButton className="image-upload-button" text="Upload New Images" small modalProps={{ content }} />

    return (
      <Panel
        {...props}
        title="Images"
        id="images"
        mutation={UpdateContentItemImages}
        changes={this.getChanges}
        titleBar={titleBar}
      >
        <Table className="images" fullWidth showPagination={false} columns={this.getColumns()} data={images}/>
      </Panel>
    )
  }
};
