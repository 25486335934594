import gql from "graphql-tag";

export const GetSeason = gql`
  query GetSeason($id: ID!) {
    season(id: $id) {
      id
      name
      seasonNumber
      genres
      tags

      status
      start
      end

      series {
        id
        images {
          id
          name
          url
        }
      }

      metadata {
        id
        description
        summary
        year
      }

      episodes {
        id
        name
        episodeNumber
        start
        end
        status

        tile {
          id
          image {
            id
            name
            url
            width
            height
          }
        }
      }
    }
  }`
;

export const UpdateSeason = gql`
  mutation UpdateSeason($input: UpdateSeasonInput!) {
    updateSeason(input: $input) {
      season {
        id
        name
        genres
        tags

        metadata {
          id
          description
          summary
          year
        }
      }
    }
  }`
;

export const UpdateSeasonAvailability = gql`
  mutation UpdateSeason($input: UpdateSeasonInput!) {
    updateSeason(input: $input) {
      season {
        id
        status
        start
        end
      }
    }
  }`
;
