import React from "react";
import Panel from "./Panel";
import _ from "lodash";
import EditGroup from "../EditGroup/EditGroup";
import { Select } from "../ControlledComponents";
import { UpdateCollectionRules, GetCollectionItems } from "../../graphql/collection-queries";
import CollectionRule from "../CollectionRule/CollectionRule";
import ToggleButton from "../ToggleButton/ToggleButton";
import { getTextFromOptions, formatFetchData } from "../../utils";
import "./DynamicItemsPanel.scss";
import Table, { CollectionItemColumns } from "../Table";
import Stepper from "../Stepper/Stepper";

const sortOptions = [
  { label: "Name (A-Z)", value: "NAME:ASC" },
  { label: "Name (Z-A)", value: "NAME:DESC" },
  { label: "Updated At (Oldest to Newest)", value: "UPDATED_AT:ASC" },
  { label: "Updated At (Newest to Oldest)", value: "UPDATED_AT:DESC" },
  { label: "Created At (Oldest to Newest)", value: "CREATED_AT:ASC" },
  { label: "Created At (Newest to Oldest)", value: "CREATED_AT:DESC" },
];
const newRule = () => {
  return { id: _.uniqueId(), field: "GENRES", relation: "CONTAINS" }
};

export default class DynamicItemsPanel extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      changes: {},
      rules: props.content.rules.map(rule => ({ id: _.uniqueId(), ...rule }))
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.editing != this.props.editing) {
      this.setState({
        changes: {},
        rules: this.props.content.rules.map(rule => ({ id: _.uniqueId(), ...rule }))
      })
    }
  }

  getChanges = () => {
    const { content } = this.props;
    const { changes, rules } = this.state;
    return {
      input: {
        ..._.pick(content, ["id", "type", "ruleLimit", "ruleOrderBy", "ruleOrderDirection"]),
        ...changes,
        rules: rules.map(rule => _.omit(rule, ["id", "__typename"]))
      }
    }
  }

  onChange = (change, property) => {
    if(property == "ruleSort") {
      let [ruleOrderBy, ruleOrderDirection] = change.split(":")
      this.setState({
        changes: { ...this.state.changes, ruleOrderBy, ruleOrderDirection }
      })
    }
    else if(property == "rule") {
      let updatedRule = change;
      this.setState({
        rules: this.state.rules.map(rule => (rule.id == updatedRule.id ? updatedRule : rule))
      })
    }
    else {
      this.setState({
        changes: { ...this.state.changes, [property]: change }
      })
    }
  }

  onRemoveRule = (id) => {
    this.setState({
      rules: this.state.rules.filter(rule => rule.id != id)
    })
  }

  onAddRule = () => {
    this.setState({
      rules: _.concat(this.state.rules, newRule())
    })
  }

  onFetchData = (state, instance) => {
    const { content, itemVariables, onPageChange } = this.props;
    let currentOrderBy = { field: content.ruleOrderBy, direction: content.ruleOrderDirection } || itemVariables.orderBy;
    onPageChange(formatFetchData(state, currentOrderBy))
  }

  refetch = (mutationResult) => {
    const { content, itemVariables } = this.props;
    return [{
      query: GetCollectionItems,
      variables: { id: content.id, ...itemVariables }
    }]
  }

  render() {
    const { rules } = this.state;
    const { content: { items, ruleLimit, ruleOrderBy, ruleOrderDirection } } = this.props;
    const editing = this.props.editing;
    let ruleSort = `${ruleOrderBy}:${ruleOrderDirection}`;

    return (
      <Panel
        title="Collection Items"
        id="items"
        mutation={UpdateCollectionRules}
        refetch={this.refetch}
        {...this.props}
        changes={this.getChanges}
      >
        <div className="dynamic-collection-rules">
          {
            rules.map(rule => {
              return (
                <CollectionRule
                  key={rule.id}
                  {...rule}
                  onChange={this.onChange}
                  onRemove={this.onRemoveRule}
                  editing={editing}
                />
              )
            })
          }
          { editing &&
            <ToggleButton type="add" text="And Include" onClick={this.onAddRule} />
          }
          <EditGroup className="rule-limit" editing={editing} label="Max # of Items" text={ruleLimit}>
            <Stepper property="ruleLimit" value={ruleLimit} onChange={this.onChange} min={0} max={200} />
          </EditGroup>
          <EditGroup className="rule-sort" editing={editing} label="Default Sort" text={getTextFromOptions(ruleSort, sortOptions)}>
            <Select gray property="ruleSort" value={ruleSort} options={sortOptions} onChange={this.onChange} />
          </EditGroup>
          <Table
            fullWidth
            manual
            onFetchData={this.onFetchData}
            pageSizeOptions={[10, 20, 30]}
            columns={CollectionItemColumns}
            totalCount={items.totalCount || 0}
            data={items.nodes || []}
          />
        </div>
      </Panel>
    )
  }
};
