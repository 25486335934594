import React from "react";

export interface TreeNode {
  description?: string;
  condition?: (props?: any, state?: any) => boolean;
  render?: (props?: any, state?: any) => (React.Component | JSX.Element | null);
  children?: TreeNode[];
};

export const evaluateTree = (root: TreeNode, props = {}, state = {}, verbose = false): (React.Component | JSX.Element | null) => {
  (root.description && verbose) && console.log(root.description);
  if(root.children) {
    for(let i=0; i<root.children.length; i++) {
      let child = root.children[i];
      if((child.condition && child.condition(props, state)) ||( i == root.children.length - 1))
        return evaluateTree(child, props, state);
    }
    return null;
  }
  else if(root.condition && root.condition(props, state)) {
    return root.render && root.render(props, state) || null;
  }
  else if(root.render) {
    return root.render(props, state);
  }
  else {
    return null
  }
};
