import React from "react";
import Panel from "./Panel"
import _ from "lodash";
import EditGroup from "../EditGroup/EditGroup";
import { UpdateScreenContents } from "../../graphql/screen-queries";
import { Input, TextArea } from "../ControlledComponents";
import { Icon, ICONS } from "@brightcove/studio-components";
import Table, { ComponentColumns } from "../Table";
import ToggleButton from "../ToggleButton/ToggleButton";
import { withModal, AddComponentModal } from "../Modals";

const AddComponentButton = withModal(ToggleButton, AddComponentModal);

export default class ScreenContentsPanel extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      changes: {},
      components: props.content.components
    };
  }

  componentDidMount() {
    this.props.upload && this.props.upload();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.content != this.props.content) {
      this.setState({
        components: this.props.content.components
      })
    }
    if(prevProps.editing != this.props.editing) {
      this.setState({
        changes: {},
        components: this.props.content.components
      })
    }
  }

  getScreenContents = () => {
    return this.props.image || { contentItems: [] };
  }

  onDelete = (id) => {
    return () => {
      this.setState({
        components: this.state.components.filter(component => component.id != id)
      })
    }
  }

  onChange = (change, property) => {
    this.setState({
      changes: {
        ...this.state.changes,
        [property]: change
      }
    })
  }

  getChanges = () => {
    const { content } = this.props;
    const { components, changes } = this.state;
    let shouldBeInactive = content.screenType != "STATIC" && !components.length;

    let updates = {
      input: {
        id: content.id,
        status: shouldBeInactive ? "INACTIVE" : content.status,
        type: content.__typename && content.__typename.toUpperCase(),
        header: content.header,
        body: content.body,
        ...changes,
        components: components && components.map((component, index) => {
          return {
            name: component.name,
            header: component.header,
            bannerImageUrl: component.bannerImageUrl,
            type: component.type,
            rank: index,
            screenId: content.id,
            collectionId: component.collection && component.collection.id,
            genres: (component.data && component.data.genres) || component.genres,
            url: (component.data && component.data.url) || component.url,
            status: component.status,
            start: component.start,
            end: component.end,
            channels: component.channel && [component.channel.id] || component.channels && component.channels.map(data => data.id)
          }
        })
      }
    }
    return updates;
  }

  getColumns = () => {
    if(this.props.editing) {
      let editColumn: any = {
        Header: "",
        accessor: "id",
        resizable: false,
        maxWidth: 90,
        sortable: true,
        Cell: (props) => {
          return (
            <div className="edit-buttons" >
              <div onClick={this.onDelete(props.value)}><Icon name={ICONS.TRASHCAN} color="magenta"/></div>
              <div onMouseDown={props.tdProps.rest.onDragStart}><Icon name={ICONS.MOVE} color="teal"/></div>
            </div>
          )
        }
      };
      return _.concat(ComponentColumns, editColumn);
    }
    return ComponentColumns;
  }

  onDragEnd = (oldIndex, newIndex) => {
    let components = this.state.components.slice(0);
    let oldIndexElement = components[oldIndex];
    let newIndexElement = components[newIndex];
    components[oldIndex] = newIndexElement;
    components[newIndex] = oldIndexElement

    this.setState({ components })
  }

  addComponent = (component) => {
    this.setState({ components: _.concat(this.state.components, component) })
  }

  render() {
    const { content, onSave, onEdit, onCancel, editing, disabled } = this.props;
    const { components } = this.state;

    return (
        <Panel
        id="contents"
        title="Screen Contents"
        changes={this.getChanges}
        mutation={UpdateScreenContents}
        onSave={onSave}
        onEdit={onEdit}
        onCancel={onCancel}
        editing={editing}
        disabled={disabled}
      >
        <EditGroup label="Screen Header" text={content.header}>
          <Input value={content.header} property="header" onChange={this.onChange} />
        </EditGroup>
        <EditGroup label="Body" html={(content.body || "").replace(/\n/g,"")}>
          <TextArea value={content.body} property="body" onChange={this.onChange} rows={10} />
        </EditGroup>
        { content.screenType != "STATIC" &&
          <React.Fragment>
            <Table
              className="editable-list"
              fullWidth
              draggable
              onDragEnd={this.onDragEnd}
              showPagination={false}
              columns={this.getColumns()}
              data={components}
            />
            { editing && <AddComponentButton type="add" text="Add a Component" onModalConfirm={this.addComponent}/>}
          </React.Fragment>
        }
      </Panel>
    )
  }
};
