import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { version } from "../../../package.json";
import "./SideMenu.scss";

export const SideMenuItem: React.FunctionComponent<any> = ({icon, link, onClick, children, selected}) => {
  let className = classnames({"side-menu-item": true, selected: selected });
  let contents = (
    <React.Fragment>
      <div className="side-menu-item-icon"><img src={icon}/></div>
      <div className="side-menu-item-text">{children}</div>
    </React.Fragment>
  );

  return (
    <div className={className} onClick={onClick}>
      { link ? <Link to={link}>{contents}</Link> : contents }
    </div>
  )
};

class SideMenu extends React.Component<any, any> {
  constructor(props) {
      super(props);
      this.state = {
          selectedIndex: props.initialSelectedIndex || 0
      };
  }

  componentDidMount() {
    if(this.props.autoSelect) {
      this.getItem();
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.autoSelect && prevProps.location.pathname != this.props.history.location.pathname) {
      this.getItem();
    }
  }

  getItem = () => {
    const { history: { location }, children } = this.props;
    // let path = (/\/[a-zA-Z]*/g.exec(location.pathname) || [])[0];
    let path = location.pathname;
    let selectedIndex = 0;

    React.Children.forEach(children, (child, index) => {
      let link = (child as React.ReactElement<any>).props.link;
      if(link == path)
        selectedIndex = index;
    });

    if(selectedIndex != this.state.selectedIndex)
      this.setState({selectedIndex})
  }

  handleClick = (index, fn) => {
      return () => {
          this.setState({ selectedIndex: index });
          if(fn) fn();
      };
  }

  render() {
    const { children } = this.props;
    const { selectedIndex } = this.state;

    return (
      <div className="side-menu">
        {
          React.Children.map(children, (child, index) => {
            let item = child as React.ReactElement<any>;
            return React.cloneElement(item, {
              key: index,
              selected: index == selectedIndex,
              onClick: item.props.onClick && this.handleClick(index, item.props.onClick)
            })
          })
        }
        <div className="version-number">Version: {version}</div>
      </div>
    )
  }
};

export default withRouter(SideMenu);
