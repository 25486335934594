import gql from "graphql-tag";

export const GetShow = gql`
  query GetShow($id: ID!) {
    contentItem(id: $id) {
      ... on Series {
        id
        extId
        name
        contentOwner
        episodeCount
        genres
        tags
        previewVideoId

        status
        start
        end

        images {
          id
          name
          url
        }

        metadata {
          id
          description
          summary
          year
        }

        tile {
          id
          header
          subHeader
          badges
          image {
            id
            url
            width
            height
          }
        }

        slide {
          id
          header
          subHeader
          description
          badges
          ctaLabel
          image {
            id
            url
            width
            height
          }
        }

        keyArt {
          id
          header
          subHeader
          description
          badges
          ctaLabel
          image {
            id
            url
            width
            height
          }
        }

        seasons {
          id
          name
          status
          start
          end
          seasonNumber
        }
      }
    }
  }`
;

export const UpdateShow = gql`
  mutation UpdateShow($input: UpdateSeriesInput!) {
    updateSeries(input: $input) {
      series {
        id
        name
        contentOwner
        genres
        tags

        metadata {
          id
          description
          summary
          year
        }
      }
    }
  }`
;

export const UpdateShowPreview = gql`
  mutation UpdateShow($input: UpdateSeriesInput!) {
    updateSeries(input: $input) {
      series {
        id
        previewVideoId
      }
    }
  }`
;
