import gql from "graphql-tag";

export const GetEPG = gql`
  query GetEPG($id: ID) {
    epg(id: $id) {
      id
      name
      status
      start
      end
      bannerImageUrl
      header
      channels {
        id
        name
        header
        createdAt
        updatedAt
        status
        start
        end
        items {
          totalCount
        }
      }
    }
  }`
;

export const UpdateEPGAvailability = gql`
  mutation UpdateEPG($input: UpdateEPGInput!) {
    updateEPG(input: $input) {
      epg {
        id
        status
        start
        end
      }
    }
  }`
;

export const UpdateEPG = gql`
  mutation UpdateEPG($input: UpdateEPGInput!) {
    updateEPG(input: $input) {
      epg {
        id
        name
        header
        bannerImageUrl
      }
    }
  }`
;

export const UpdateEPGContents = gql`
  mutation UpdateEPG($input: UpdateEPGInput!) {
    updateEPG(input: $input) {
      epg {
        id
      }
    }
  }`
;
