import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "./Table.scss";
import TablePager from "../TablePager/TablePager";
import classnames from "classnames";
import Selectable from "./Selectable/Selectable";
import _ from "lodash";
import Draggable from "./Draggable/Draggable";

// interface TableProps {
//   fullWidth?: boolean
//   totalCount?: number
// }
//
// interface SelectableProps {
//   selectable?: boolean
//   singleSelection?: boolean
//   onSelection: Function
//   idField?: string
//   initialSelections?: any[]
//   maxSelections?: number
//   items?: Function
// }

export default class Table extends React.Component<any, any> {
  ref: any;

  constructor(props) {
    super(props);
    this.state = {
      Selectable: null,
      Draggable: null,
      pageSize: this.getPageSize(props.pageSizeOptions, props.defaultPageSize),
      page: props.page || 0
    }
  }

  componentDidMount() {
    let selectable, draggable;

    if(this.props.selectable) {
      const { onSelection, singleSelection, idField, initialSelections, maxSelections, confirmSelection } = this.props;

      let items = () => (this.props.data);
      let onChange = (selected, instance) => {
        this.forceUpdate();
        onSelection && onSelection(selected, instance);
      };
      selectable = new Selectable(idField, !singleSelection, initialSelections, maxSelections, items, onChange, confirmSelection);
    }
    if(this.props.draggable) {
      draggable = new Draggable(this.props.onDragEnd);
    }

    if(selectable || draggable) {
      this.setState({ Selectable: selectable, Draggable: draggable })
    }
  }

  getPageSize(pageSizeOptions, defaultPageSize) {
    let pageSize = undefined;
    if(pageSizeOptions) {
      if(defaultPageSize && pageSizeOptions.includes(defaultPageSize))
        pageSize = defaultPageSize;
      else
        pageSize = pageSizeOptions[0];
    }
    return pageSize;
  }

  onPageSizeChange = (pageSize, page) => {
    this.setState({pageSize, page});
  }

  onPageChange = (page) => {
    this.setState({page});
  }

  getTrProps = (state, rowInfo, column) => {
    const { getTrProps } = this.props;
    const { Selectable } = this.state;

    return _.merge(
      {},
      getTrProps && getTrProps(state, rowInfo, column),
      Selectable && Selectable.getTrProps(state, rowInfo, column)
    )
  }

  getTdProps = (state, rowInfo, column, instance) => {
    const { getTdProps } = this.props;
    const { Draggable } = this.state;

    return _.merge(
      {},
      getTdProps && getTdProps(state, rowInfo, column),
      Draggable && Draggable.getTdProps(state, rowInfo, column)
    )
  }

  getColumns = () => {
    const { columns } = this.props;
    const { Selectable } = this.state;
    return Selectable ? Selectable.decorateColumns(columns) : columns;
  }

  render() {
    const { data, columns, fullWidth, pageSizeOptions, defaultPageSize, totalCount, className: customClassName, ...props } = this.props;
    const { pageSize, page, Draggable } = this.state;
    let className = classnames([{"table": true, "full-width": fullWidth}, customClassName]);

    return (
      <React.Fragment>
        <ReactTable
          {...props as any}
          ref={Draggable ? Draggable.getRef() : (node => (this.ref = node))}
          className={className}
          columns={this.getColumns()}
          getTrProps={this.getTrProps}
          getTdProps={this.getTdProps}
          data={data}
          pageSize={pageSize}
          page={page}
          PaginationComponent={TablePager}
          onPageSizeChange={this.onPageSizeChange}
          onPageChange={this.onPageChange}
          getPaginationProps={() => ({ pageSizeOptions, totalCount })}
          minRows={0}
        />
      </React.Fragment>
    )
  }
};
