import React from "react"
import { Query } from "react-apollo"
import { GetEPG, UpdateEPG } from "../../graphql/epg-queries";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import { PageIndex, PageIndexButton } from "../../components/PageIndex/PageIndex";
import Panel from "../../components/Panel/Panel";
import EditGroup from "../../components/EditGroup/EditGroup";
import { Input, ImageInput } from "../../components/ControlledComponents";
import Loading from "../../components/Loading/Loading";
import AvailabilityPanel from "../../components/Panel/AvailabilityPanel";
import _ from "lodash";
import Row from "../../components/Row/Row";
import { ToasterManager } from "../../utils";
import EpgComponentPanel from "../../components/Panel/EpgComponentPanel"
import { withRouter } from "react-router-dom";
import Details from "../Details/Details";
const defaultModel = {
  collection: {},
};

class EPGDetails extends Details {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      contentVars: {
        after: 0,
        first: 10,
        orderBy: { field: "UPDATED_AT", direction: "DESC" }
      }
    }
  }

  onCompleted = () => {
    this.setState({
      changes: null,
      editingPanel: null,
      contentVars: {
        after: 0,
        first: 10,
        orderBy: { field: "UPDATED_AT", direction: "DESC" }
      }
    });
  }

  render() {
    const { history } = this.props

    return (
      <Query query={GetEPG}>
        {({ loading, error, data }) => {
          let epg: any = data && data.epg || defaultModel;
          let changes = {
            input: {
              ..._.omit(epg, ["__typename"]),
              ...this.state.changes,
              bannerImageUrl: this.state.changes && this.state.changes.bannerImageUrl || epg.bannerImageUrl,
              channels: epg.channels && epg.channels.map(item => item.id)
            }
          };
          if(error) ToasterManager.error(error)

          return (
            <React.Fragment>
              <SectionHeader
                title={epg.name}
                subtitleAbove="Back"
                black
                onSubtitleClick={() => { history.goBack() }} />
              <div className="detail-main">
                <div className="detail-content">
                  { loading && <Loading /> }
                  <Panel
                    title="EPG Information"
                    id="epg"
                    mutation={UpdateEPG}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("epg")}
                    disabled={this.isDisabled("epg")}
                    changes={changes}
                    onValidation={(changes) => {
                      let inputs = changes.input;
                      return inputs.name && inputs.name.length > 0 || false;
                    }}
                  >
                    <Row>
                      <EditGroup label="EPG Name" text={epg.name}>
                        <Input value={epg.name} property="name" onChange={this.onChange}/>
                      </EditGroup>
                      <EditGroup label="ID" text={epg.id}/>
                      <EditGroup.Placeholder />
                    </Row>
                    <Row>
                      <EditGroup label="EPG Header" text={epg.header}>
                        <Input value={epg.header} property="header" onChange={this.onChange}/>
                      </EditGroup>
                      <EditGroup label="Graphic" image={epg.bannerImageUrl}>
                          <ImageInput image={epg.bannerImageUrl || ""} property="bannerImageUrl" content={epg.bannerImageUrl} onChange={this.onChange} />
                        </EditGroup>
                      <EditGroup.Placeholder />
                      </Row>
                  </Panel>

                  <AvailabilityPanel
                    content={epg}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("availability")}
                    disabled={this.isDisabled("availability")}
                  />

                  <EpgComponentPanel
                    content={epg}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("content")}
                    disabled={this.isDisabled("content")}
                  />
                </div>
                <PageIndex>
                  <PageIndexButton id="epg" text="EPG Information"/>
                  <PageIndexButton id="availability" text="Availability"/>
                  <PageIndexButton id="content" text="Content"/>
                </PageIndex>
              </div>
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
};

export default withRouter(EPGDetails);
