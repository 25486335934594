import React from "react";
import Panel from "./Panel";
import EditGroup from "../EditGroup/EditGroup";
import Row from "../Row/Row";
import { windowText, capitalize, formatFetchData } from "../../utils";
import { withStore } from "../../store";
import ToggleButton from "../ToggleButton/ToggleButton";
import { withModal, AddToCollectionModal } from "../Modals";
import { ApolloConsumer } from "react-apollo";
import { GetComponentCollection } from "../../graphql/collection-queries";
import Table, { CollectionItemColumns } from "../Table";
import _ from "lodash";
import { Link } from "react-router-dom";
import { UpdateComponentContents, GetComponent } from "../../graphql/component-queries";
import "./ComponentContents.scss";

const AddButton = withModal(ToggleButton, AddToCollectionModal);

class ComponentContentsPanel extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      collection: props.content.collection || {},
      variables: props.contentVariables
    }
  }

  componentDidUpdate(prevProps) {
    if((!_.isEqual(prevProps.content.collection, this.props.content.collection)) ||
      (prevProps.editing && !this.props.editing)) {
      this.setState({
        collection: this.props.content.collection,
        variables: this.props.contentVariables
      })
    }
  }

  onChange = (change, property) => {
    this.setState({ [property]: change })
  }

  getChanges = () => {
    const { content, contentVariables } = this.props;
    const { collection } = this.state;

    return {
      input: {
        id: content.id,
        type: content.type,
        collectionId: collection && collection.id
      }
    }
  }

  refetch = (mutationResult) => {
    const { content, contentVariables } = this.props;

    return [{
      query: GetComponent,
      variables: { id: content.id, ...contentVariables }
    }]
  }

  removeCollection = () => {
    this.setState({ collection: undefined })
  }

  onFetchData = (client) => {
    return async (state, instance) => {
      const { collection, variables } = this.state;
      
      const orderBy = {
        field: collection.ruleOrderBy,
        direction: collection.ruleOrderDirection,
      }
      let newVariables = formatFetchData(state, orderBy)

      const { data } = await client.query({
        query: GetComponentCollection,
        variables: { id: collection.id, ...newVariables },
        fetchPolicy: "network-only"
      });
      this.setState({ collection: data.collection, variables: newVariables })
    }
  }

  render() {
    const { collection } = this.state;
    const { content, store: { language }, contentVariables, ...props } = this.props;
    const editing = this.props.editing;

    return (
      <Panel
        className="component-contents"
        title="Component Content"
        id="content"
        {...props}
        changes={this.getChanges}
        mutation={UpdateComponentContents}
        refetch={this.refetch}
      >
        <Row>
          <EditGroup label="Collection Name" text={collection && <Link to={`/collection/${collection.id}`}>{collection.name}</Link>}/>
          <EditGroup label="Collection ID" text={collection && collection.id}/>
          <EditGroup label="Collection Type" text={collection && collection.type}/>
        </Row>
        <Row>
          <EditGroup label="Collection Status" text={collection && capitalize(collection.status)}/>
          <EditGroup label="Collection Scheduling" text={collection && windowText(collection.start, collection.end, language)}/>
          <EditGroup.Placeholder />
        </Row>
        <ApolloConsumer>
          { client => {
            return (
              <React.Fragment>
                { editing && collection && <ToggleButton type="remove" text="Remove Collection" onClick={this.removeCollection} /> }
                { editing && !collection &&
                  <AddButton
                    type="add"
                    text="Add Collection"
                    modalProps={{ title: "Add a Collection", cta: "Add a Collection" }}
                    onModalConfirm={async (selections) => {
                      const { data } = await client.query({
                        query: GetComponentCollection,
                        variables: { id: selections[0].id, ...contentVariables },
                        fetchPolicy: "network-only"
                      });
                      this.setState({ collection: data.collection, variables: contentVariables })
                    }}
                  />
                }
                { collection && collection.items && collection.items.nodes &&
                  <Table
                    manual
                    onFetchData={this.onFetchData(client)}
                    pageSizeOptions={[10, 20, 30]}
                    totalCount={collection.items.totalCount || 0}
                    fullWidth
                    showPagination={true}
                    columns={CollectionItemColumns}
                    data={collection.items.nodes}
                  />
                }
              </React.Fragment>
            )
          }}
        </ApolloConsumer>
      </Panel>
    )
  }
}

export default withStore(ComponentContentsPanel);
