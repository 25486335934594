import { CHANGE_CATALOG, CHANGE_LANGUAGE, CLEAR } from "../actions";
import { StoreState, GenericAction, StorageField, storageKey } from "../types";

function updateLocalStorage(key: StorageField, value: any) {
  localStorage.setItem(storageKey(key), typeof value == "object" ? JSON.stringify(value) : value);
}

function clearLocalStorage() {
  for(let field in StorageField) {
    localStorage.removeItem(storageKey(field));
  }
}

export function initLocalStorage(values) {
  for(let field in StorageField) {
    let key = field.toLowerCase();
    let keyInStorage = storageKey(field);
    let valueInStorage = localStorage.getItem(keyInStorage);

    if(key in values && !valueInStorage) {
      updateLocalStorage(StorageField[StorageField[field]], values[key]);
    }
  }
}

export const initialState: StoreState = {
  authenticator: undefined,
  catalog: null,
  language: "en"
};

export default function(state: StoreState = initialState, action: GenericAction) {
  switch(action.type) {
    case CHANGE_CATALOG:
      updateLocalStorage(StorageField.Catalog, action.data.catalog);
      if(action.data.callback) action.data.callback();
      return { ...state, catalog: action.data.catalog };

    case CHANGE_LANGUAGE:
      updateLocalStorage(StorageField.Language, action.data.language);
      return { ...state, language: action.data.language };

    case CLEAR:
      clearLocalStorage();
      return {...initialState, authenticator: state.authenticator}

    default:
      return state;
  }
};
