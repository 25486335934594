import React from "react";
import { Link } from "react-router-dom";
import { formatDate, adminContentType } from "../../../utils";
import { Icon, ICONS } from "@brightcove/studio-components";

export const AllChannelsColumns = [
  {
    Header: "Channel Name",
    accessor: "name",
    minWidth: 400,
    Cell: ({original: { type, name, id }}) => {
      return (
        <div className="name-id">
          <div><Link to={`/channel/${id}`}>{name}</Link></div>
          <div>{id}</div>
        </div>
      )
    }
  },
  {
    Header: "Status",
    accessor: "status",
    resizable: false,
    minWidth: 180,
    maxWidth: 180,
    style: { display: "block", textAlign: "left" },
    Cell: ({value}) => {
      return value == "ACTIVE" ? <Icon name={ICONS.CHECKMARK} color='green'/> : <Icon name={ICONS.BAN} color='magenta'/>
    }
  },
  {
    Header: "Collections",
    accessor: "items.totalCount",
    minWidth: 180,
    maxWidth: 180,
    style: { display: "block", textAlign: "left" },
    Cell: ({value}) => {
      return value
    }
  },
  
  {
    Header: "Created",
    accessor: "createdAt",
    resizable: false,
    minWidth: 240,
    maxWidth: 240,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  },
  {
    Header: "Updated",
    accessor: "updatedAt",
    resizable: false,
    minWidth: 240,
    maxWidth: 240,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  }
];


export const ChannelModalColumns = [
  {
    Header: () => {
      return <React.Fragment>Channel Name<span className="language-code">en</span></React.Fragment>
    },
    accessor: "name",
    minWidth: 320,
    Cell: ({original: { type, name, id }}) => {
      return (
        <div className="name-id">
          <div>{name}</div>
          <div>{id}</div>
        </div>
      )
    }
  },
  {
    Header: "Status",
    accessor: "status",
    resizable: false,
    minWidth: 75,
    maxWidth: 75,
    style: { display: "block", textAlign: "center" },
    Cell: ({value}) => {
      return value == "ACTIVE" ? <Icon name={ICONS.CHECKMARK} color='green'/> : <Icon name={ICONS.BAN} color='magenta'/>
    }
  },
  {
    Header: "Created",
    accessor: "createdAt",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  },
  {
    Header: "Updated",
    accessor: "updatedAt",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  }
];


export const ComponentChannelModalColumns = [
  {
    Header: "Channel Name",
    accessor: "name",
    minWidth: 180,
    Cell: ({ original: { type, name, id } }) => {
      return (
        <div className="name-id">
          <div><Link to={`/channel/${id}`}>{name}</Link></div>
          <div>{id}</div>
        </div>
      )
    }
  },
  {
    Header: "Collections",
    accessor: "items.totalCount",
    minWidth: 120,
    maxWidth: 120,
    style: { display: "block", textAlign: "left" },
    Cell: ({ value }) => {
      return value
    }
  },
  {
    Header: "Channel Scheduling",
    accessor: "start",
    resizable: false,
    minWidth: 200,
    maxWidth: 200,
    sortable: true,
    Cell: ({ value }) => { return formatDate(value) }
  },
  {
    Header: "Last Edited",
    accessor: "updatedAt",
    resizable: false,
    minWidth: 120,
    maxWidth: 120,
    sortable: true,
    Cell: ({ value }) => { return formatDate(value, false) }
  },
  {
    Header: "Status",
    accessor: "status",
    resizable: false,
    minWidth: 75,
    maxWidth: 75,
    style: { display: "block", textAlign: "center" },
    Cell: ({ value }) => {
      return value == "ACTIVE" ? <Icon name={ICONS.CHECKMARK} color='green' /> : <Icon name={ICONS.BAN} color='magenta' />
    }
  }
];

export const ChannelItemColumns = [
  {
    accessor: "name",
    minWidth: 320,
    Cell: ({original: { type, name, id }}) => {
      return (
        <div className="name-id">
          <div><Link to={`/channel/${id}`}>{name}</Link></div>
          <div>{id}</div>
        </div>
      )
    }
  },
];

export const ChannelCollectionItemColumns = [
  {
    Header: "Collection Name",
    accessor: "collection",
    minWidth: 250,
    Cell: ({value: { name, id }}) => {
      return (
        <div className="name-id">
          <div><Link to={`/collection/${id}`}>{name}</Link></div>
          <div>{id}</div>
        </div>
      )
    }
  },
  {
    Header: "Randomize Schedule Order",
    accessor: "randomized",
    resizable: false,
    minWidth: 200,
    maxWidth: 200,
    style: { display: "block", textAlign: "center" },
    Cell: ({ value }) => {
      return value == null || value == false ? "No" : "Yes"
    }
  },
  {
    Header: "Status",
    accessor: "collection.status",
    resizable: false,
    minWidth: 75,
    maxWidth: 75,
    style: { display: "block", textAlign: "center" },
    Cell: ({value}) => {
      return value == "ACTIVE" ? <Icon name={ICONS.CHECKMARK} color='green'/> : <Icon name={ICONS.BAN} color='magenta'/>
    }
  }
];
