import React from "react";
import { Modal, Button } from "@brightcove/studio-components";
import Content from "../../routes/Content/Content";
import SideMenu, { SideMenuItem } from "../SideMenu/SideMenu";
import { SVGIcon } from "../../icons";
import "./AddCollectionItemModal.scss";
import { ToasterManager } from "../../utils";
import _ from "lodash";

export default class AddCollectionItemModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      type: undefined,
      selections: []
    }
  }

  componentDidUpdate() {
    ToasterManager.dismiss();
  }

  handleSelections = () => {
    const { selections } = this.state;
    const { confirm } = this.props;
    confirm && confirm(selections);
  }

  onRowSelect = (selections) => {
    this.setState({ selections })
  }

  confirmRowSelect = (selections) => {
    const { items } = this.props;
    if(!items) return true;

    let selectionIds = selections.map(item => item.id);
    for(let item of items) {
      if(selectionIds.includes(item.id)) {
        ToasterManager.dismiss();
        ToasterManager.error({ message: "Collections cannot contain duplicate items." });
        return false;
      }
    }
    return true;
  }

  onMenuClick = (type?) => {
    return () => {
      this.setState({ type })
    }
  }

  render() {
    const { isOpen, close, loading, data, itemVariables, ...props } = this.props;
    const { selections, type } = this.state;
    let embedMenu;
    if (itemVariables != "SCHEDULE"){
      embedMenu = (
        <SideMenu>
          <SideMenuItem onClick={this.onMenuClick()} icon={SVGIcon.NavAllContent}>All Content</SideMenuItem>
          <SideMenuItem onClick={this.onMenuClick("episodes")} icon={SVGIcon.NavEpisode}>Episodes</SideMenuItem>
          <SideMenuItem onClick={this.onMenuClick("shows")} icon={SVGIcon.NavShows}>Shows</SideMenuItem>
          <SideMenuItem onClick={this.onMenuClick("screens")} icon={SVGIcon.NavContentScreen}>Screens</SideMenuItem>
        </SideMenu>
      )
    }

    return (
      <Modal
        className="add-collection-item-modal"
        title="Add a Collection Item"
        {...props}
        isOpen={isOpen}
        onClose={close}
        buttons={[
          <Button key="1" invert text="Cancel" onClick={close} />,
          <Button key="2" text="Add to Collection" disabled={!selections.length} onClick={this.handleSelections} />
        ]}
      >
        <Content confirmRowSelect={this.confirmRowSelect} type={this.props.itemVariables == "SCHEDULE" ? "episodes" : type} embedded embedMenu={embedMenu} onRowSelect={this.onRowSelect} />
      </Modal>
    )
  }
};
