import React from "react";
import { Mutation } from "react-apollo";
import { PanelFooterBar } from "../../components/Panel/Panel";
import { withRouter } from "react-router";
import { Panel, Button } from "@brightcove/studio-components";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import EditGroup from "../../components/EditGroup/EditGroup";
import Row from "../../components/Row/Row";
import { Input, Select } from "../../components/ControlledComponents";
import { CreateCollection } from "../../graphql/collection-queries";
import { ToasterManager } from "../../utils";

const SaveButton: React.FunctionComponent<any> = ({ changes, onCompleted, onError, ...props }) => {
  return (
    <Mutation mutation={CreateCollection} onCompleted={onCompleted} onError={onError} >
    {(save) => {
      let onClick = () => {
        let variables = {
          input: {
            ...changes,
            status: "INACTIVE"
          }
        };
        save({ variables: variables });
      };
      return <Button {...props} onClick={onClick} />
    }}
    </Mutation>
  )
};

class NewCollection extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      valid: false,
      changes: {}
    }
  }

  onChange = (change, property) => {
    ToasterManager.dismiss();
    this.setState({
      changes: {
        ...this.state.changes,
        [property]: change
      }
    }, () => {
      this.setState({ valid: this.validate(this.state.changes) })
    })
  }

  validate = ({ name, type }) => {
    return (name != undefined && name.length > 0 && type) || false;
  }

  onError = (error) => {
    ToasterManager.error(error);
  }

  onCompleted = (data) => {
    if(!(data && data.createCollection && data.createCollection.collection)) {
      ToasterManager.error({ message: "Something went wrong. Please check the console for details." });
    }
    else {
      const { history } = this.props;
      history.replace(`/collection/${data.createCollection.collection.id}`);
    }
  }

  render() {
    const { history } = this.props;
    const { valid, changes } = this.state;

    return (
      <React.Fragment>
        <SectionHeader
          title="New Collection"
          subtitleAbove="Back"
          black
          onSubtitleClick={() => { history.goBack() }} />
        <div className="detail-main">
          <div className="detail-content new">
            <Panel className="panel" title="Collection Info">
              <Row>
                <EditGroup label="Collection Name" editing required>
                  <Input value="" property="name" onChange={this.onChange}/>
                </EditGroup>
                <EditGroup label="Collection Type" editing required>
                  <Select
                    value="MANUAL"
                    property="type"
                    onChange={this.onChange}
                    options={[
                      { label: "Manual", value: "MANUAL" },
                      { label: "Dynamic", value: "DYNAMIC" },
                      { label: "Schedule", value: "SCHEDULE" },
                      { label: "Continue Watching", value: "CONTINUE_WATCHING" },
                    ]}
                  />
                </EditGroup>
              </Row>

              <PanelFooterBar>
                <Button small invert text="Cancel" onClick={() => { history.goBack() }} />
                <SaveButton
                  small
                  disabled={!valid}
                  text="Create"
                  changes={changes}
                  onError={this.onError}
                  onCompleted={this.onCompleted}
                />
              </PanelFooterBar>
            </Panel>
          </div>
        </div>
    </React.Fragment>
    )
  }
}

export default withRouter(NewCollection);
