import gql from "graphql-tag";

const ActivityLogFragment = gql`
  fragment ActivityLogFragment on ActivityLog {
    id
    operation
    resourceIds
    userEmail
    userId
    catalog {
      name
    }
    success
    createdAt
  }`
;

export const GetActivityLogsAndCatalogs = gql`
  query GetActivityLogsAndCatalogs($after: Int, $first: Int, $orderBy: ActivityLogOrder, $catalogId: ID, $resourceId: String, $success: String, $operation: String, $user: String) {
    activityLogs(after: $after, first: $first, orderBy: $orderBy, catalogId: $catalogId, resourceId: $resourceId, success: $success, operation: $operation, user: $user) {
      nodes {
        ... ActivityLogFragment
      }
      totalCount
    }
    catalogs {
      id
      name
    }
  }
  ${ActivityLogFragment}`
;

export const GetActivityLog = gql`
  query GetActivityLog($id: ID!) {
    activityLog(id: $id) {
      ... ActivityLogFragment
      body
      headers
      errors
    }
  }
  ${ActivityLogFragment}`
;
