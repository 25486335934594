import React from "react";
import { Modal, Button } from "@brightcove/studio-components";
import Content from "../../routes/Content/Content";
import "./AddToShowModal.scss";

export default class AddToShowModal extends React.Component<any, any> {
  static defaultProps = {
    selections: []
  }

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selections: []
    }
  }

  handleSelections = () => {
    const { selections } = this.state;
    const { confirm, content } = this.props;
    confirm && confirm(selections);
  }

  onRowSelect = (selections) => {
    this.setState({ selections })
  }

  render() {
    const { isOpen, close, singleSelection, title, cta, ...props } = this.props;
    const { selections } = this.state;

    return (
      <Modal
        className="add-to-show-modal"
        title={title || "Add to Show"}
        {...props}
        isOpen={isOpen}
        onClose={close}
        buttons={[
          <Button key="0" invert text="Cancel" onClick={close} />,
          <Button key="1" text={cta || "Add to Show"} disabled={!selections.length} onClick={this.handleSelections} />
        ]}
      >
        <Content key={"modal.add-to-show"} embedded type="shows" onRowSelect={this.onRowSelect} singleSelection={singleSelection}/>
      </Modal>
    )
  }
};
