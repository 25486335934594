import gql from "graphql-tag";
import { CollectionItemsFragment } from "./collection-queries";

const CollectionFragment = gql`
  fragment CollectionFragment on Collection {
    id
    type
    name
    status
    start
    end
    ruleOrderBy
    ruleOrderDirection
    ruleLimit
    ...CollectionItemsFragment
  }
  ${CollectionItemsFragment}`
;

export const GetComponent = gql`
  query GetComponent($id: ID!, $after: Int, $first: Int, $orderBy: CollectionOrder) {
    component(id: $id) {
      id
      name
      type
      status
      start
      end
      header
      genres
      url
      collection {
        ...CollectionFragment
      }
    }
  }
  ${CollectionFragment}`
;

export const UpdateComponentAvailability = gql`
  mutation UpdateComponent($input: UpdateComponentInput!) {
    updateComponent(input: $input) {
      component {
        id
        status
        start
        end
      }
    }
  }`
;

export const UpdateComponent = gql`
  mutation UpdateComponent($input: UpdateComponentInput!) {
    updateComponent(input: $input) {
      component {
        id
        name
        header
        genres
        url
      }
    }
  }`
;

export const UpdateComponentContents = gql`
  mutation UpdateComponent($input: UpdateComponentInput!) {
    updateComponent(input: $input) {
      component {
        id
      }
    }
  }`
;
