import React from "react"
import { Query } from "react-apollo"
import { UpdateSeason, GetSeason } from "../../graphql/season-queries";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import { withRouter } from "react-router-dom";
import { PageIndex, PageIndexButton } from "../../components/PageIndex/PageIndex";
import Panel from "../../components/Panel/Panel";
import EditGroup from "../../components/EditGroup/EditGroup";
import { Input } from "../../components/ControlledComponents";
import Loading from "../../components/Loading/Loading";
import AvailabilityPanel from "../../components/Panel/AvailabilityPanel";
import Table, { EpisodeColumns } from "../../components/Table";
import _ from "lodash";
import Details from "../Details/Details";
import { ToasterManager } from "../../utils";

const defaultModel = {
  episodes: [],
  metadata: {}
};

class Season extends Details {
  render() {
    const { history, match } = this.props

    return (
      <Query query={GetSeason} variables={{ id: match.params.id }}>
        {({ loading, error, data }) => {
          let season: any = data && data.season || defaultModel;
          if(error) ToasterManager.error(error)

          return (
            <React.Fragment>
              <SectionHeader
                title={season.name}
                subtitleAbove="Back"
                black
                onSubtitleClick={() => { history.goBack() }} />
              <div className="detail-main">
                <div className="detail-content">
                  { loading && <Loading /> }
                  <Panel
                    title="Season Information"
                    id="season"
                    mutation={UpdateSeason}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("season")}
                    disabled={this.isDisabled("season")}
                    changes={() => {
                      let current = _.pick(season, ["id", "name", "metadata"]);
                      current.metadata = current.metadata && _.omit(current.metadata, "__typename");

                      return {
                        input: {
                        ...current,
                        ...this.state.changes
                        }
                      }
                    }}
                    onValidation={(changes) => {
                      let inputs = changes.input;
                      return inputs.name && inputs.name.length > 0 || false;
                    }}
                  >
                    <EditGroup label="Season Name" text={season.name}>
                      <Input value={season.name} property="name" onChange={this.onChange}/>
                    </EditGroup>
                    <EditGroup label="ID" text={season.id}/>
                    <EditGroup label="Season Number" text={season.seasonNumber}/>
                    <EditGroup label="Description" text={season.metadata.description}/>
                    <EditGroup label="Year" text={season.metadata.year}/>
                  </Panel>

                  <AvailabilityPanel
                    staticWindow
                    content={season}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("availability")}
                    disabled={this.isDisabled("availability")}
                  />

                  <Panel title="Episodes" id="episodes" uneditable>
                    <Table columns={EpisodeColumns} data={season.episodes} showPagination={false} fullWidth />
                  </Panel>

                </div>
                <PageIndex>
                  <PageIndexButton id="season" text="Season Information"/>
                  <PageIndexButton id="availability" text="Availability"/>
                  <PageIndexButton id="episodes" text="Episodes"/>
                </PageIndex>
              </div>
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
};

export default withRouter(Season);
