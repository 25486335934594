import gql from "graphql-tag";

export const GetContentItems = gql`
  query GetContentItems($after: Int, $first: Int, $catalogId: ID, $type: String, $excludeType: String, $query: String, $orderBy: ContentItemOrder) {
    contentItems(after: $after, first: $first, catalogId: $catalogId, type: $type, excludeType: $excludeType, query: $query, orderBy: $orderBy) {
      nodes {
        id
        status
        __typename
        createdAt
        updatedAt
        name

        tile {
          id
          image {
            id
            url
          }
        }

        ... on Episode {
          series {
            id
            name
          }
        }

        ... on Screen {
          screenType
          slide {
            id
            image {
              id
              url
            }
          }

        }
      }
      totalCount
    }
  }`
;

export const UpdateContentItemAvailability = gql`
  mutation UpdateContentItem($input: UpdateContentItemInput!) {
    updateContentItem(input: $input) {
      contentItem {
        id
        status
        start
        end
      }
    }
  }`
;

export const UpdateContentItemImages = gql`
  mutation UpdateContentItem($input: UpdateContentItemInput!) {
    updateContentItem(input: $input) {
      contentItem {
        id
        images {
          id
          name
          url
        }
      }
    }
  }`
;

export const GetContentItemImages = gql`
  query GetContentItem($id: ID!) {
    contentItem(id: $id) {
      id
      images {
        id
        name
        url
      }
    }
  }`
;
