import React from "react";
import { Link } from "react-router-dom";
import { formatDate, capitalize, getComponentType } from "../../../utils";
import _ from "lodash";
import { ICONS, Icon } from "@brightcove/studio-components";

export const StaticScreenColumns = [
  {
    Header: "Screen Name",
    accessor: "name",
    Cell: ({original: { name, id }}) => {
      return (
        <div className="name-id">
          <div><Link to={`/screen/${id}`}>{name}</Link></div>
          <div>{id}</div>
        </div>
      )
    }
  },
  {
    Header: "Status",
    accessor: "status",
    resizable: false,
    minWidth: 75,
    maxWidth: 75,
    sortable: true,
    style: { display: "block", textAlign: "center" },
    Cell: ({ value }) => {
      return value == "ACTIVE" ? <Icon name={ICONS.CHECKMARK} color='green'/> : <Icon name={ICONS.BAN} color='magenta'/>
    }
  },
  {
    Header: "Created",
    accessor: "createdAt",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  },
  {
    Header: "Updated",
    accessor: "updatedAt",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  }
];

export const ContentScreenColumns = [
  {
    Header: "Screen Name",
    accessor: "name",
    Cell: ({original: { name, id }}) => {
      return (
        <div className="name-id">
          <div><Link to={`/screen/${id}`}>{name}</Link></div>
          <div>{id}</div>
        </div>
      )
    }
  },
  {
    Header: "Screen Type",
    accessor: "screenType",
    resizable: false,
    minWidth: 150,
    maxWidth: 150,
  },
  {
    Header: "Status",
    accessor: "status",
    resizable: false,
    minWidth: 75,
    maxWidth: 75,
    sortable: true,
    style: { display: "block", textAlign: "center" },
    Cell: ({ value }) => {
      return value == "ACTIVE" ? <Icon name={ICONS.CHECKMARK} color='green'/> : <Icon name={ICONS.BAN} color='magenta'/>
    }
  },
  {
    Header: "Created",
    accessor: "createdAt",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  },
  {
    Header: "Updated",
    accessor: "updatedAt",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  }
];

export const ScreenModalColumns = [
  {
    Header: "Screen Name",
    accessor: "name",
    Cell: ({original: { name, id }}) => {
      return (
        <div className="name-id">
          <div>{name}</div>
          <div>{id}</div>
        </div>
      )
    }
  },
  {
    Header: "Screen Type",
    accessor: "screenType",
    resizable: false,
    minWidth: 150,
    maxWidth: 150,
  },
  {
    Header: "Status",
    accessor: "status",
    resizable: false,
    minWidth: 75,
    maxWidth: 75,
    sortable: true,
    style: { display: "block", textAlign: "center" },
    Cell: ({ value }) => {
      return value == "ACTIVE" ? <Icon name={ICONS.CHECKMARK} color='green'/> : <Icon name={ICONS.BAN} color='magenta'/>
    }
  },
  {
    Header: "Created",
    accessor: "createdAt",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  },
  {
    Header: "Updated",
    accessor: "updatedAt",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  }
];

export const ComponentColumns = [
  {
    Header: "Component Name",
    sortable: false,
    Cell: ({original: { name, id }}) => {
      return (
        <div className="name-id">
          { id.includes("placeholder") ?
            <React.Fragment>
              <div>{name}</div>
              <div></div>
            </React.Fragment>
            :
            <React.Fragment>
              <div><Link to={`/component/${id}`}>{name}</Link></div>
              <div>{id}</div>
            </React.Fragment>
          }
        </div>
      )
    }
  },
  {
    Header: "Type",
    sortable: false,
    accessor: "type",
    resizable: false,
    minWidth: 150,
    maxWidth: 150,
    Cell: ({ value }) => getComponentType(value)
  },
  {
    Header: "Status",
    accessor: "status",
    resizable: false,
    minWidth: 75,
    maxWidth: 75,
    sortable: false,
    style: { display: "block", textAlign: "center" },
    Cell: ({ value }) => {
      return value == "ACTIVE" ? <Icon name={ICONS.CHECKMARK} color='green'/> : <Icon name={ICONS.BAN} color='magenta'/>
    }
  },
  {
    Header: "Start Date",
    accessor: "start",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: false,
    Cell: ({value}) => { return formatDate(value) }
  },
  {
    Header: "End Date",
    accessor: "end",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: false,
    Cell: ({value}) => { return formatDate(value) }
  }
];
