import React from "react";
import { Modal, Button } from "@brightcove/studio-components";
import "./DeleteConfirmationModal.scss";
import { Mutation } from "react-apollo";
import { DeleteCollections } from "../../graphql/collection-queries";
import { DeleteChannels } from "../../graphql/channel-queries"
import { DeleteImages } from "../../graphql/image-queries";
import { DeleteScreens } from "../../graphql/screen-queries";
import { DeleteDynamicStrings } from "../../graphql/dynamic-string-queries";
import { DeleteServiceMessages } from "../../graphql/service-message-queries";
import { DeleteMaintenanceModes } from "../../graphql/maintenance-mode-queries";

const DeleteButton: React.FunctionComponent<any> = ({ mutation, selections, onCompleted, ...props }) => {
  return (
    mutation ?
      <Mutation mutation={mutation} onCompleted={onCompleted} >
      {(sendDelete) => {
        let onClick = () => {
          let variables = { ids: selections.map(selection => selection.id) };
          sendDelete({ variables })
        };
        return <Button {...props} onClick={onClick} />
      }}
      </Mutation>
    : null
  )
};

export default class DeleteConfirmationModal extends React.Component<any, any> {
  static defaultProps = {
    selections: []
  }

  onDelete = (data) => {
    if(data.errors) return;

    const { confirm } = this.props;
    confirm && confirm();
  }

  getTypeParams = () => {
    const { type, selections } = this.props;
    let params = {
      text: `${selections.length} ${type}${selections.length > 1 ? "s": ""}`,
      mutation: undefined
    }

    switch(type) {
      case "collection":
        params.mutation = DeleteCollections;
        break;
      case "channel":
        params.mutation = DeleteChannels;
        break;
      case "image":
        params.mutation = DeleteImages;
        break;
      case "screen":
        params.mutation = DeleteScreens;
        break;
      case "dynamic string":
        params.mutation = DeleteDynamicStrings;
        break;
      case "service message":
        params.mutation = DeleteServiceMessages;
        break;
      case "maintenance mode":
        params.mutation = DeleteMaintenanceModes;
    }
    return params;
  }

  render() {
    const { type, selections, isOpen, close, ...props } = this.props;
    let typeParams = this.getTypeParams();

    return (
      <Modal
        className="delete-confirmation-modal"
        title="Delete"
        {...props}
        isOpen={isOpen}
        onClose={close}
        buttons={[
          <Button key="1" invert text="Cancel" onClick={close} />,
          <DeleteButton key="2" text="Delete" pink mutation={typeParams.mutation} onCompleted={this.onDelete} selections={selections}/>
        ]}
      >
        <div>{`Permanently delete ${typeParams.text} from the system? This cannot be undone.`}</div>
      </Modal>
    )
  }
};
