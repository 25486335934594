import React from "react";
import { TagListInput as StudioTagListInput, PlainTagListInputProps } from "@brightcove/studio-components";
import ControlledProps from "./ControlledProps";
import classnames from "classnames";
import _ from "lodash";

interface TagListInputProps {
  maxLength?: number
  normalize?: boolean;
}

export default class TagListInput extends React.Component<PlainTagListInputProps & ControlledProps & TagListInputProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      values: props.value || ""
    }
  }

  componentDidMount() {
    const { onChange, property } = this.props;
    const { values } = this.state;
    onChange && onChange(values, property);
  }

  onChange = (values) => {
    const { onChange, property, normalize } = this.props;
    if(normalize) values = values.map(value => _.capitalize(value));

    this.setState({values})
    onChange && onChange(values, property);
  }

  render() {
    const { property, className: customClassName, maxLength, ...props } = this.props;
    const { values } = this.state;

    let className = classnames(["tag-list-input", { "max-length": maxLength && values.length >= maxLength }, customClassName])
    return (
      <StudioTagListInput className={className} {...props} value={values} onChange={this.onChange}>
        {this.props.children}
      </StudioTagListInput>
    )
  }
}
