import React from "react";
import { Query, Mutation, compose } from "react-apollo";
import { withRouter } from "react-router-dom";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import DefaultSideMenu from "../../components/SideMenu/DefaultSideMenu";
import SectionControls from "../../components/SectionControls/SectionControls";
import { Button } from "@brightcove/studio-components";
import Table, { AllContentColumns, AllEpisodesColumns, AllShowsColumns, ShowModalColumns, ContentScreenColumns } from "../../components/Table";
import { SVGIcon } from "../../icons";
import { GetContentItems } from "../../graphql/content-queries";
import { AddToCollection } from "../../graphql/collection-queries";
import Loading from "../../components/Loading/Loading";
import { withModal, AddToCollectionModal } from "../../components/Modals";
import _ from "lodash";
import ModalControls from "../../components/ModalControls/ModalControls";
import { SearchInput } from "../../components/ControlledComponents";
import { handleErrors, formatFetchData, ToasterManager } from "../../utils";
import { withStore } from "../../store";

const AddButton: React.FunctionComponent<any> = ({ contentItems, onCompleted, ...props }) => {
  return (
    <Mutation mutation={AddToCollection} onCompleted={onCompleted} >
    {(add) => {
      const ModalButton = withModal(Button, AddToCollectionModal);
      return <ModalButton {...props} modalProps={{ type: "MANUAL" }} onModalConfirm={(collections) => {
        let variables = {
          input: {
            id: collections[0].id,
            contentItemIds: contentItems.map(contentItem => contentItem.id)
          }
        };
        add({ variables });
      }} />
    }}
    </Mutation>
  )
};

class Content extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      selections: [],
      first: 20,
      after: 0,
      orderBy: { field: "UPDATED_AT", direction: "DESC" }
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.location.pathname != this.props.location.pathname) {
      this.setState({
        searchQuery: "",
        selections: [],
        first: 20,
        after: 0,
        orderBy: { field: "UPDATED_AT", direction: "DESC" }
      })
    }
  }

  onSearch = (searchQuery) => {
    this.setState({ searchQuery, selections: [] })
  }

  onRowSelect = (selections, { deselectAll }) => {
    const { onRowSelect } = this.props;
    this.setState({ selections, deselectAll })
    onRowSelect && onRowSelect(selections);
  }

  confirmRowSelect = (selections) => {
    return this.props.confirmRowSelect ? this.props.confirmRowSelect(selections) : true;
  }

  onFetchData = (state, instance) => {
    this.setState(formatFetchData(state, this.state.orderBy))
  }

  render() {
    const { history, embedMenu, singleSelection, store: { catalog } } = this.props;
    const { selections, first, after, orderBy, searchQuery: query, deselectAll } = this.state;
    const embedded = this.props.embedded || embedMenu;
    let type, excludeType, title, columns, icon;

    switch (this.props.type || history.location.pathname.replace("/","")) {
      case "shows":
        title = "Shows";
        type = "SERIES";
        columns = embedded ? ShowModalColumns : AllShowsColumns;
        icon = SVGIcon.Shows;
        break

      case "episodes":
        title = "Episodes";
        type = "EPISODE";
        columns = AllEpisodesColumns;
        icon = SVGIcon.Episodes;
        break

      case "screens":
        title = "Screens";
        type = "SCREEN";
        columns = ContentScreenColumns;
        icon = SVGIcon.ContentScreen;
        break

      default:
        title = "All Content"
        columns = AllContentColumns;
        icon = SVGIcon.AllContent;
        excludeType = "SCREEN"
    }

    // notifyOnNetworkStatusChange suggested by https://github.com/apollographql/react-apollo/issues/2575
    return (
      <Query query={GetContentItems} notifyOnNetworkStatusChange variables={{first, after, type, query, orderBy, excludeType, catalogId: catalog.id }}>
        {({ loading, error, data: resp }) => {
          let contentItems = resp && resp.contentItems;
          let data = contentItems && contentItems.nodes || [];
          let totalCount = contentItems && contentItems.totalCount || 0;

          handleErrors(error);

          return (
            <div className="main">
              { embedMenu }
              { !embedded && <DefaultSideMenu /> }
              <div className="content">
                { loading && <Loading /> }
                {
                  embedded ?
                    <ModalControls>
                      <SearchInput noPad placeholder="Search..." onChange={this.onSearch}/>
                    </ModalControls>
                  :
                    <React.Fragment>
                      <SectionHeader
                        teal
                        icon={icon}
                        title={title}
                        subtitle={(totalCount > 1 || !totalCount) ? `${totalCount} Items` : `${totalCount} Item`}
                        onSearch={this.onSearch}
                      />
                      <SectionControls>
                        <AddButton
                          text="Add to Collection"
                          disabled={!selections.length}
                          contentItems={selections}
                          onCompleted={() => {
                            ToasterManager.info({ message: "Content Items added to Collection! Any content items already added to the collection were omitted." })
                            deselectAll();
                          }}
                        />
                      </SectionControls>
                    </React.Fragment>
                }
                <Table
                  key={`${type}${query}`}
                  fullWidth
                  selectable
                  manual
                  onFetchData={this.onFetchData}
                  pageSizeOptions={[20, 30, 40, 50]}
                  onSelection={this.onRowSelect}
                  confirmSelection={this.confirmRowSelect}
                  singleSelection={singleSelection}
                  columns={columns}
                  totalCount={totalCount}
                  data={data}
                />
              </div>
            </div>
          )
        }}
      </Query>
    )
  }
};

export default compose(withStore, withRouter)(Content);
