import React from "react";
import { Checkbox as StudioCheckbox } from "@brightcove/studio-components";
import ControlledProps from "./ControlledProps";
import classnames from "classnames";

interface CheckboxGroupProps {
  values: any[]
  options: any[]
}

export default class CheckboxGroup extends React.Component<CheckboxGroupProps & ControlledProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      values: props.values
    }
  }

  componentDidMount() {
    const { onChange, property } = this.props;
    const { values } = this.state;
    onChange && onChange(values, property);
  }

  onChange = (checked, value) => {
    const { onChange, property } = this.props;
    const { values: currentValues } = this.state;
    const included = currentValues.includes(value);
    let values = currentValues.slice(0);

    if(checked && !included)
      values.push(value);
    else if(!checked && included)
      values = values.filter(v => v != value)

    this.setState({ values })
    onChange && onChange(values, property);
  }

  render() {
    const { property, className, options, ...props } = this.props;
    const { values } = this.state;

    return (
      <div className={classnames(["checkbox-group", className])}>
        { options.map((option, index) => (
            <StudioCheckbox
              key={index}
              className="checkbox"
              checked={values.includes(option.value)}
              onChange={(checked) => { this.onChange(checked, option.value) }}
            >
              {option.label}
            </StudioCheckbox>
          ))
        }
      </div>
    )
  }
}
