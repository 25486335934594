import React from "react"
import { Query } from "react-apollo"
import { GetChannel, UpdateChannel } from "../../graphql/channel-queries";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import { withRouter } from "react-router-dom";
import { PageIndex, PageIndexButton } from "../../components/PageIndex/PageIndex";
import Panel from "../../components/Panel/Panel";
import EditGroup from "../../components/EditGroup/EditGroup";
import { Input, ImageInput } from "../../components/ControlledComponents";
import { Button } from "@brightcove/studio-components";
import Loading from "../../components/Loading/Loading";
import AvailabilityPanel from "../../components/Panel/AvailabilityPanel";
import ChannelCollectionPanel from "../../components/Panel/ChannelCollectionPanel";
import _ from "lodash";
import Row from "../../components/Row/Row";
import Details from "../Details/Details";
import { withModal, DeleteConfirmationModal } from "../../components/Modals";
import { ToasterManager } from "../../utils";
import "./Channel.scss";

const canBeActivated = (channel) => {
  return channel.items.nodes && channel.items.nodes.length
};
const DeleteButton = withModal(Button, DeleteConfirmationModal);
const defaultModel = {
  items: []
};

class Channel extends Details {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      itemVars: {
        after: 0,
        first: 10,
        orderBy: { field: "UPDATED_AT", direction: "DESC" }
      }
    }
  }

  onChange = (change, property) => {
    if (property == "icon" || property == "image" && change.url) {
      this.setState({
        changes: {
          ...this.state.changes,
          [property]: change.url
        }
      })
    } else {
      this.setState({
        changes: {
          ...this.state.changes,
          [property]: change
        }
      })
    }
  }

  onCompleted = () => {
    this.setState({
      changes: null,
      editingPanel: null,
      itemVars: {
        after: 0,
        first: 10,
        orderBy: { field: "UPDATED_AT", direction: "DESC" }
      }
    });
  }

  updateItemPaging = (itemVars) => {
    this.setState({ itemVars })
  }

  render() {
    const { history, match } = this.props
    const { itemVars } = this.state;

    return (
      <Query query={GetChannel} variables={{ id: match.params.id, ...itemVars }}>
        {({ loading, error, data }) => {
          let channel: any = data && data.channel || defaultModel;
          if (error) ToasterManager.error(error)

          return (
            <React.Fragment>
              <SectionHeader
                title={channel.name}
                subtitleAbove="Back"
                black
                onSubtitleClick={() => { history.goBack() }}
                buttons={[
                  <DeleteButton
                    text="Delete"
                    pink
                    onModalConfirm={() => { history.goBack() }}
                    modalProps={{ selections: [channel], type: "channel" }}
                  />
                ]} />
              <div className="detail-main">
                <div className="detail-content">
                  {loading && <Loading />}
                  <Panel
                    title="Channel Information"
                    id="channel"
                    mutation={UpdateChannel}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("channel")}
                    disabled={this.isDisabled("channel")}
                    changes={() => {
                      let current = _.pick(channel, ["id", "name", "header", "icon", "image", "metadata"]);
                      current.metadata = current.metadata && _.omit(current.metadata, "__typename");
                      return {
                        input: {
                          ...current,
                          ...this.state.changes
                        }
                      }
                    }}
                    onValidation={(changes) => {
                      let inputs = changes.input;
                      return inputs.name && inputs.name.length > 0 && inputs.image && inputs.image != null || false;
                    }}
                  >
                    <Row>
                      <EditGroup label="Channel Name" text={channel.name}>
                        <Input value={channel.name} property="name" onChange={this.onChange} />
                      </EditGroup>
                      <EditGroup label="Channel Header" text={channel.header || channel.name}>
                        <Input value={channel.header || channel.name} property="header" onChange={this.onChange} />
                      </EditGroup>
                      <EditGroup label="ID" text={channel.id} />
                      <EditGroup label="Icon" image={channel.icon}>
                        <ImageInput image={channel.icon || ""} property="icon" content={channel.icon} onChange={this.onChange} />
                      </EditGroup>
                      <EditGroup label="Graphic" image={channel.image}>
                        <ImageInput image={channel.image || ""} property="image" content={channel.image} onChange={this.onChange} />
                      </EditGroup>
                    </Row>
                  </Panel>

                  <AvailabilityPanel
                    content={channel}
                    statusDisabled={!canBeActivated(channel)}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("availability")}
                    disabled={this.isDisabled("availability")}
                  />

                  {
                    channel.items.nodes ?
                      <ChannelCollectionPanel
                        content={channel}
                        onPageChange={this.updateItemPaging}
                        itemVariables="SCHEDULE"
                        onSave={this.onSave}
                        onEdit={this.onPanelEdit}
                        onCancel={this.onPanelCancel}
                        editing={this.isEditing("items")}
                        disabled={this.isDisabled("items")}
                      />
                      : null
                  }
                </div>
                <PageIndex>
                  <PageIndexButton id="channel" text="Channel Information" />
                  <PageIndexButton id="availability" text="Channel Availability" />
                  <PageIndexButton id="items" text="Channel Collections" />
                </PageIndex>
              </div>
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
};

export default withRouter(Channel);
