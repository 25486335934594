import gql from "graphql-tag";

export const GetMaintenanceModes = gql`
  query GetMaintenanceModes {
    maintenanceModes {
      id
      message
      platforms
      status
    }
  }`
;

export const CreateMaintenanceMode = gql`
  mutation CreateMaintenanceMode($input: CreateMaintenanceModeInput!) {
    createMaintenanceMode(input: $input) {
      maintenanceMode {
        id
        message
        platforms
        status
      }
    }
  }`
;

export const UpdateMaintenanceMode = gql`
  mutation UpdateMaintenanceMode($input: UpdateMaintenanceModeInput!) {
    updateMaintenanceMode(input: $input) {
      maintenanceMode {
        id
        message
        platforms
        status
      }
    }
  }`
;

export const DeleteMaintenanceModes = gql`
  mutation DeleteMaintenanceModes($ids: [ID!]) {
    deleteMaintenanceModes(ids: $ids)
  }`
;
