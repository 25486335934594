import { InMemoryCache } from "apollo-cache-inmemory"
import { ApolloClient } from "apollo-client"
import { ApolloLink } from "apollo-link"
import { setContext } from "apollo-link-context"
import { onError } from "apollo-link-error"
import { ServerError } from "apollo-link-http-common"
import { createUploadLink } from "apollo-upload-client"
import React from "react"
import { ApolloProvider } from "react-apollo"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import CognitoAuthenticator from "./auth/Auth-Cognito";
import Authenticator from "./auth/Authenticator";
import Header from "./components/Header/Header";
import Collection from "./routes/Collections/Collection"
import Collections from "./routes/Collections/Collections"
import NewCollection from "./routes/Collections/NewCollection"
import Channel from "./routes/Channels/Channel"
import Channels from "./routes/Channels/Channels"
import NewChannel from "./routes/Channels/NewChannel"
import Component from "./routes/Component/Component"
import NewDynamicString from "./routes/DynamicStrings/NewDynamicString"
import DynamicString from "./routes/DynamicStrings/DynamicString"
import DynamicStrings from "./routes/DynamicStrings/DynamicStrings"
import Content from "./routes/Content/Content"
import Episode from "./routes/Episode/Episode"
import Images from "./routes/Images/Images";
import Image from "./routes/Images/Image";
import Screen from "./routes/Screens/Screen"
import Screens from "./routes/Screens/Screens"
import NewScreen from "./routes/Screens/NewScreen"
import Season from "./routes/Season/Season"
import ServiceMessages from "./routes/ServiceMessages/ServiceMessages";
import Show from "./routes/Show/Show"
import EPG from "./routes/EPG/EPG";
import AuthenticationSwitch from "./auth/AuthenticationSwitch"
import UploadImages from "./routes/Images/UploadImages"
import MaintenanceMode from "./routes/MaintenanceMode/MaintenanceMode";
import ActivityLog from "./routes/ActivityLogs/ActivityLog";
import ActivityLogs from "./routes/ActivityLogs/ActivityLogs";
import { initialize } from '@brightcove/studio-components'
import createStore, { storageKey, StorageField } from "./store";
import { Provider as ReduxProvider } from 'react-redux'
import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory"
import introspectionQueryResultData from "./fragment-types.json"
import { Toaster } from "@brightcove/studio-components";
import { customFetch } from "./customFetch";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionQueryResultData as any,
})



const dataIdFromObject = (object) => {
  return object.id || object._id || null
}

initialize();

const auth: Authenticator = new CognitoAuthenticator();
const store = createStore({ authenticator: auth });

class App extends React.Component {
  state = {
    isAuthenticated: false,
    user: null
  }

  client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          )
        }
        if (networkError) {
          if ((networkError as ServerError).statusCode === 401) {
            auth.logout(this.onSignOut);
          }
          console.log(networkError)
        }
      }),
      setContext(async (_, {headers}) => {
        if(auth.hasExpired()) {
          await new Promise(resolve => {
            auth.renewSession(resolve)
          })
        }
        const token = auth.getIdToken();
        const language = localStorage.getItem(storageKey(StorageField.Language));
        const catalog = localStorage.getItem(storageKey(StorageField.Catalog))

        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
            "content-language": language,
            "content-catalog": catalog && (JSON.parse(catalog) || {}).id
          },
        }
      }),
      createUploadLink({
        uri: process.env.REACT_APP_GRAPHQL_URL,
        credentials: "same-origin",
        fetch: customFetch as any
      }),
    ]),
    cache: new InMemoryCache({ fragmentMatcher, dataIdFromObject }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "all",
      },
      query: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "all",
      },
      mutate: {
        errorPolicy: "all",
      },
    },
  })

  onSignIn = async (isAuthenticated) => {
    let user = await auth.userInfo();
    this.setState({ isAuthenticated, user })
  };

  onSignOut = () => {
    this.setState({ isAuthenticated: false, user: null })
  }

  render() {
    return (
      <div style={{display: "flex", flexDirection: "column", height: "100vh"}}>
        <Toaster />
        <Router>
          <ApolloProvider client={this.client}>
            <ReduxProvider store={store}>
                <Header user={this.state.user}/>
                <Switch>
                  <AuthenticationSwitch isAuthenticated={this.state.isAuthenticated} onSignIn={this.onSignIn} onSignOut={this.onSignOut}>
                    <Route path={["/", "/shows", "/episodes"]} exact component={Content} />
                    <Route path="/component/:id" exact component={Component} />
                    <Route path="/collections/" exact component={Collections} />
                    <Route path="/collections/new" exact component={NewCollection} />
                    <Route path="/collection/:id" component={Collection} />
                    <Route path="/channels/" exact component={Channels} />
                    <Route path="/channels/new" exact component={NewChannel} />
                    <Route path="/channel/:id" exact component={Channel} />
                    <Route path="/screens/new" exact component={NewScreen} />
                    <Route path={["/screens", "/screens/:type"]} exact component={Screens} />
                    <Route path="/screen/:id" component={Screen} />
                    <Route path="/episode/:id" component={Episode} />
                    <Route path="/season/:id" component={Season} />
                    <Route path="/show/:id" component={Show} />
                    <Route path="/images/upload" exact component={UploadImages} />
                    <Route path="/images/" exact component={Images} />
                    <Route path="/image/:id" exact component={Image} />
                    <Route path="/epg" exact component={EPG} />
                    <Route path="/dynamic-strings/" exact component={DynamicStrings} />
                    <Route path="/dynamic-strings/new" exact component={NewDynamicString} />
                    <Route path="/dynamic-string/:id" exact component={DynamicString} />
                    <Route path="/service-messages/" exact component={ServiceMessages} />
                    <Route path="/maintenance-mode/" exact component={MaintenanceMode} />
                    <Route path="/activity-history/" exact component={ActivityLogs} />
                    <Route path="/activity/:id" exact component={ActivityLog} />
                  </AuthenticationSwitch>
                </Switch>
              </ReduxProvider>
          </ApolloProvider>
        </Router>
      </div>
    )
  }
}

export default App;
