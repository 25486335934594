import React from "react"
import { UpdateMaintenanceMode, CreateMaintenanceMode, GetMaintenanceModes } from "../../graphql/maintenance-mode-queries";
import Panel from "../../components/Panel/Panel";
import EditGroup from "../../components/EditGroup/EditGroup";
import { TextArea, RadioGroup, CheckboxGroup } from "../../components/ControlledComponents";
import { Button } from "@brightcove/studio-components";
import { Column, Columns }from "../../components/Columns/Columns";
import { getTextFromOptions, PlatformOptions } from "../../utils";
const DeleteButton = withModal(Button, DeleteConfirmationModal);
import { withModal, DeleteConfirmationModal } from "../../components/Modals";
import "./MaintenanceModePanel.scss";
import _ from "lodash";

const statusTranslation = {
  "ACTIVE": "ON",
  "INACTIVE": "OFF"
};

export default class MaintenanceModePanel extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      changes: null
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.editing && !this.props.editing) {
      this.setState({ changes: null })
    }
  }

  onChange = (change, property) => {
    if(property == "window") {
      this.setState({
        changes: {
          ...this.state.changes,
          ...change
        }
      })
    }
    else {
      this.setState({
        changes: {
          ...this.state.changes,
          [property]: change
        }
      })
    }
  }

  getChanges = () => {
    const { unsaved, message } = this.props;
    const { changes } = this.state;

    return {
      input: {
        id: unsaved ? undefined: message.id,
        ..._.omit(message, ["id", "__typename"]),
        ...changes
      }
    }
  }

  getRefetch = () => {
    return [{
      query: GetMaintenanceModes
    }]
  }

  render() {
    const { id, refetch, unsaved, onUnsavedDelete, message, ...props } = this.props;

    return (
      <Panel
        id={id}
        title="Maintenance Mode"
        className="maintenance-mode"
        mutation={unsaved ? CreateMaintenanceMode : UpdateMaintenanceMode}
        {...props}
        onCancel={onUnsavedDelete}
        changes={this.getChanges}
        refetch={this.getRefetch}
        titleBar={
          unsaved ?
          <Button text="Delete" pink small onClick={onUnsavedDelete} /> :
          <DeleteButton
            text="Delete"
            pink
            small
            onModalConfirm={() => { refetch() }}
            modalProps={{selections: [message], type: "maintenance mode"}}
          />
        }
      >
        <Columns>
          <Column>
            <EditGroup label="Maintenance Mode" text={statusTranslation[message.status]}>
              <RadioGroup
                name="status radio"
                inline
                value={message.status}
                property="status"
                onChange={this.onChange}
                options={[{label: "Active", value: "ACTIVE"}, {label: "Inactive", value: "INACTIVE"}]}
              />
            </EditGroup>
            <EditGroup label="Message" text={message.message}>
              <TextArea property="message" value={message.message} onChange={this.onChange}/>
            </EditGroup>
          </Column>
          <Column>
            <EditGroup
              label="Platforms"
              html={(message.platforms || []).map(p => getTextFromOptions(p, PlatformOptions)).join("\n")}
            >
              <CheckboxGroup
                property="platforms"
                onChange={this.onChange}
                values={(message.platforms || [])}
                options={PlatformOptions}
              />
            </EditGroup>
          </Column>
        </Columns>
      </Panel>
    )
  }
}
