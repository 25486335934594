import React from "react";
import "./EditGroup.scss";
import classnames from "classnames";

export default class EditGroup extends React.Component<any, any> {
  static Placeholder: React.FunctionComponent<any> = () => { return <div className="edit-group"/> };

  parseJSON = (text, special?: Boolean) => {
    try {
      if(special) {
        let cleaned = text.replace(/\\\\\\"/g,"'").replace(/\\"/g,"QT").replace(/"/g,"").replace(/QT/g,'"');
        cleaned = cleaned.substring(1, cleaned.length - 1);
        return JSON.stringify(JSON.parse(cleaned), undefined, 2);
      }
      else {
        let cleaned = text.replace(/\n/g,"");
        return JSON.stringify(JSON.parse(cleaned), undefined, 2);
      }
    }
    catch(err) {}
    return "-";
  }

  render() {
    const { label, text, image, html, json, errorJson, editing, children, language, required, warning, showAll, className } = this.props;
    let labelClassName = classnames({ "edit-group-label": true, warning, required });

    return (
      <div className={classnames([{"edit-group": true }, className])}>
        <div className={labelClassName}>{label}{language && <span className="language-code">{language}</span>}</div>
        {
          text && (!editing || showAll || !children) ?
            <div className="edit-group-text">{text}</div>
          : html && (!editing || showAll || !children) ?
            <div className="edit-group-text" dangerouslySetInnerHTML={{__html: String(html).replace(/\n/g, "</br>")}} />
          : json && (!editing || showAll || !children) ?
            <pre className="edit-group-text json">{this.parseJSON(json)}</pre>
          : errorJson && (!editing || showAll || !children) ?
            <pre className="edit-group-text json">{this.parseJSON(errorJson, true)}</pre>
          : image && (!editing || showAll || !children) ?
            <img className="edit-group-image" src={image}/>
          : !editing || showAll || !children ?
            <div className="edit-group-text">-</div>
          : null
        }
        { (editing && children) && <div className="edit-group-control">{children}</div> }
      </div>
    )
  }
};
