import gql from "graphql-tag";

export const GetImages = gql`
  query GetImages($after: Int, $first: Int, $query: String, $contentItem: ID, $orderBy: ImageOrder, $catalogId: ID) {
    images(after: $after, first: $first, query: $query, contentItem: $contentItem, orderBy: $orderBy, catalogId: $catalogId) {
      nodes {
        id
        name
        url
        width
        height
        createdAt
        updatedAt
      }
      totalCount
    }
  }`
;

export const GetImage = gql`
  query GetImage($id: ID!) {
    image(id: $id) {
      id
      name
      url
      type
      width
      height
      contentItems {
        id
        name
      }
    }
  }`
;

export const UpdateImage = gql`
  mutation UpdateImage($input: UpdateImageInput!) {
    updateImage(input: $input) {
      image {
        id
        name
        url
        type
        contentItems {
          id
          name
        }
      }
    }
  }`
;

export const DeleteImages = gql`
  mutation DeleteImages($ids: [ID!]) {
    deleteImages(ids: $ids)
  }`
;

export const AddImagesToContentItems = gql`
  mutation AddImagesToContentItems($input: AddImagesToContentItemsInput!) {
    addImagesToContentItems(input: $input) {
      id
    }
  }`
;

export const UploadImage = gql`
  mutation UploadImage($file: Upload!, $input: UploadImageInput) {
    uploadImage(file: $file, input: $input) {
      image {
        id
        name
        url
        type
        contentItems {
          id
          name
        }
      }
    }
  }`
;
