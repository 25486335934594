import React from "react";
import Panel from "./Panel";
import EditGroup from "../EditGroup/EditGroup";
import { RadioGroup } from "../ControlledComponents";
import _ from "lodash";
import { withStore } from "../../store";
import SchedulePicker from '../SchedulePicker/SchedulePicker';
import { UpdateContentItemAvailability } from "../../graphql/content-queries";
import { UpdateChannelItemAvailability } from "../../graphql/channel-queries";
import { UpdateCollectionAvailability } from "../../graphql/collection-queries";
import { UpdateComponentAvailability } from "../../graphql/component-queries";
import { UpdateSeasonAvailability } from "../../graphql/season-queries";
import { UpdateEPGAvailability } from "../../graphql/epg-queries";
import { windowText, capitalize } from "../../utils";

class AvailabilityPanel extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      changes: {}
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.editing != this.props.editing) {
      this.setState({
        changes: {}
      })
    }
  }

  getContentProps = () => {
    const { content } = this.props;
    if(!content.__typename)
      return {}
    else if(content.__typename == "Collection")
      return { mutation: UpdateCollectionAvailability, type: undefined }
    else if(content.__typename == "Channel")
      return { mutation: UpdateChannelItemAvailability, type: undefined }
    else if(content.__typename.includes("Component"))
      return { mutation: UpdateComponentAvailability, type: content.type }
    else if(content.__typename == "Season")
      return { mutation: UpdateSeasonAvailability, type: undefined }
    else if(content.__typename == "EPG")
      return { mutation: UpdateEPGAvailability, type: undefined }
    else
      return { mutation: UpdateContentItemAvailability, type: content.__typename.toUpperCase() }
  }

  getChanges = () => {
    const { content } = this.props;
    const { changes } = this.state;
    return {
      input: {
        id: content.id,
        status: content.status,
        start: content.start,
        end: content.end,
        type: this.getContentProps().type,
        ...changes
      }
    }
  }

  onChange = (change, property) => {
    if(property == "status") {
      this.setState({
        changes: {
          ...this.state.changes,
          "status": change
        }
      })
    }
    else {
      this.setState({
        changes: {
          ...this.state.changes,
          ...change
        }
      })
    }

  }

  render() {
    const { content, staticWindow, statusDisabled, store: { language }, ...props } = this.props;
    let mutation = this.getContentProps().mutation;

    return (
      <Panel
        title="Availability"
        id="availability"
        mutation={mutation}
        {...props}
        changes={this.getChanges}
      >
        <EditGroup label="Status" text={capitalize(content.status)}>
          <RadioGroup
            name="status radio"
            inline
            disabled={statusDisabled}
            value={content.status}
            property="status"
            onChange={this.onChange}
            options={[{label: "Active", value: "ACTIVE"}, {label: "Inactive", value: "INACTIVE"}]}
          />
        </EditGroup>
        <EditGroup label="License Window" text={windowText(content.start, content.end, language)}>
        { staticWindow ?
          null :
          <React.Fragment>
            <SchedulePicker start={content.start} end={content.end} onChange={this.onChange}/>
          </React.Fragment>
        }
        </EditGroup>
      </Panel>
    )
  }
}

export default withStore(AvailabilityPanel);
