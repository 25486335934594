import React from "react"
import { Query } from "react-apollo"
import { GetActivityLog } from "../../graphql/activity-log-queries";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import Panel from "../../components/Panel/Panel";
import Loading from "../../components/Loading/Loading";
import _ from "lodash";
import { withRouter } from "react-router";
import Row from "../../components/Row/Row";
import EditGroup from "../../components/EditGroup/EditGroup";
import { formatDate, ToasterManager } from "../../utils";

const defaultModel = { catalog: {} };

class ActivityLog extends React.Component<any, any> {
  render() {
    const { history, match } = this.props
    return (
      <Query query={GetActivityLog} variables={{ id: match.params.id }}>
        {({ loading, error, data }) => {
          let activity: any = data && data.activityLog || defaultModel;
          if(error) ToasterManager.error(error)

          return (
            <React.Fragment>
              <SectionHeader
                title={activity.operation}
                subtitleAbove="Back"
                black
                onSubtitleClick={() => { history.goBack() }}
              />
              <div className="detail-main">
                <div className="detail-content full-width">
                  { loading && <Loading /> }
                  <Panel id="details" uneditable title="Activity Details">
                    <Row>
                      <EditGroup label="ID" text={activity.operation} />
                      <EditGroup label="Content ID" html={(activity.resourceIds || "").replace(/[{}\"]/g, "").replace(/,/g, "\n")} />
                      <EditGroup label="User Email" text={activity.userEmail} />
                      <EditGroup label="User ID" text={activity.userId} />
                    </Row>
                    <Row>
                      <EditGroup label="Catalog" text={activity.catalog && activity.catalog.name} />
                      <EditGroup label="Status" text={activity ? (activity.success ? "Success" : "Fail") : undefined} />
                      <EditGroup label="Created" text={formatDate(activity.createdAt)} />
                      <EditGroup.Placeholder />
                    </Row>
                    <EditGroup label="Body" json={activity.body} />
                    <EditGroup label="Headers" json={activity.headers} />
                    <EditGroup label="Errors" errorJson={activity.errors} />
                  </Panel>
                </div>
              </div>
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
};

export default withRouter(ActivityLog);
