import React from "react";
import Panel from "./Panel";
import EditGroup from "../EditGroup/EditGroup";
import { Input, TextArea, ImageInput, TagListInput } from "../ControlledComponents";
import _ from "lodash";
import { UpdateTile, UpdateSlide, UpdateKeyArt } from "../../graphql/display-item-queries";
import { GetContentItemImages } from "../../graphql/content-queries";
import { isValidImage, ImageRatios, ToasterManager } from "../../utils";

class DisplayItemPanel extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      newContentImage: false,
      changes: {},
      invalidImage: false
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.editing && !this.props.editing) {
      this.setState({
        changes: {},
        newContentImage: false,
        invalidImage: false
      })
    }
  }

  hasNewContentImage = (imageId) => {
    const { content } = this.props
    let images = this.getContentItem().images;

    if(imageId) {
      for(let image of images) {
        if(image.id == imageId)
          return false;
      }
      return true;
    }
    else {
      return false;
    }
  }

  getContentItem = () => {
    const { content } = this.props;
    return content.__typename == "Episode" ? content.series : content;
  }

  refetch = (mutationResult) => {
    let contentItemId = this.getContentItem().id;

    return [{
      query: GetContentItemImages,
      variables: { id: contentItemId }
    }]
  }

  getChanges = () => {
    const { content, type } = this.props;
    const { changes, newContentImage } = this.state;
    let model = content[type];
    let current = _.merge(_.omit(model, "image", "__typename"), { imageId: model.image.id });
    let contentItemId = newContentImage ? this.getContentItem().id : undefined;

    return {
      input: { ...current, ...changes },
      contentItemId: contentItemId
    }
  }

  onChange = (change, property) => {
    ToasterManager.dismiss();
    let newChange = {}
    let invalidImage = this.state.invalidImage;

    switch(property) {
      case "imageId":
        let type = this.props.type.toUpperCase();
        let valid = isValidImage(change.width, change.height, type);
        invalidImage = valid.result == false;

        if(invalidImage) {
          this.setState({ invalidImage: true });
          ToasterManager.error(valid.error!);
          return;
        }
        else {
          newChange = { [property]: change.id }
        }
        break;

      default:
        newChange = { [property]: change }
    }

    this.setState({
      changes: {
        ...this.state.changes,
        ...newChange
      },
      invalidImage: invalidImage,
      newContentImage: property == "imageId" ? this.hasNewContentImage(change.id) : this.state.newContentImage
    })
  }

  getTypeProps = () => {
    const { type } = this.props;
    let aspectRatio = ImageRatios[type.toUpperCase()];
    let props;

    switch(type) {
      case "tile": props = { title: "Tile", id: "tile", mutation: UpdateTile }; break;
      case "slide": props = { title: "Hero Slide", id: "slide", mutation: UpdateSlide }; break;
      case "keyArt": props = { title: "KeyArt", id: "key-art", mutation: UpdateKeyArt }; break;
      default: props = { title: "", id: "" }
    }
    props.aspectRatio = aspectRatio && `(${aspectRatio.width}x${aspectRatio.height})`;

    return props;
  }

  render() {
    const { content, type, ...props } = this.props;
    const { newContentImage, invalidImage } = this.state;
    let typeProps = this.getTypeProps();
    let model = content[type];

    return (
      <Panel
        {...typeProps}
        {...props}
        onValidation={() => { return !invalidImage }}
        changes={this.getChanges}
        refetch={newContentImage ? this.refetch : undefined}
      >
        <EditGroup label={`${typeProps.title} Header`} text={model.header}>
          <Input value={model.header} property="header" onChange={this.onChange}/>
        </EditGroup>
        <EditGroup label="ID" text={model.id}/>
        <EditGroup label={`${typeProps.title} Sub-Header`} text={model.subHeader}>
          <Input value={model.subHeader} property="subHeader" onChange={this.onChange}/>
        </EditGroup>
        <EditGroup label="Badge" text={(model.badges || [])[0]}>
          <TagListInput value={model.badges} property="badges" maxLength={1} normalize onChange={this.onChange}/>
        </EditGroup>
        { ["slide", "keyArt"].includes(type) &&
          <React.Fragment>
            <EditGroup label="Description" text={model.description}>
              <TextArea value={model.description} property="description" onChange={this.onChange}/>
            </EditGroup>
            <EditGroup label="Label for Primary Call to Action" text={model.ctaLabel}>
              <Input value={model.ctaLabel} property="ctaLabel" onChange={this.onChange}/>
            </EditGroup>
          </React.Fragment>
        }
        <EditGroup label={`Artwork ${typeProps.aspectRatio}`} image={model.image.url}>
          <ImageInput image={model.image} property="imageId" content={this.getContentItem()} onChange={this.onChange}/>
        </EditGroup>
      </Panel>
    )
  }
}

export default DisplayItemPanel;
