import gql from "graphql-tag";

export const UpdateTile = gql`
  mutation UpdateTile($input: UpdateTileInput!, $contentItemId: ID) {
    updateTile(input: $input, contentItemId: $contentItemId) {
      tile {
        id
        header
        subHeader
        badges
        image {
          id
          url
          width
          height
        }
      }
    }
  }`
;

export const UpdateSlide = gql`
  mutation UpdateSlide($input: UpdateSlideInput!, $contentItemId: ID) {
    updateSlide(input: $input, contentItemId: $contentItemId) {
      slide {
        id
        header
        subHeader
        description
        badges
        ctaLabel
        image {
          id
          url
          width
          height
        }
      }
    }
  }`
;

export const UpdateKeyArt = gql`
  mutation UpdateKeyArt($input: UpdateKeyArtInput!, $contentItemId: ID) {
    updateKeyArt(input: $input, contentItemId: $contentItemId) {
      keyArt {
        id
        header
        subHeader
        description
        badges
        ctaLabel
        image {
          id
          url
          width
          height
        }
      }
    }
  }`
;
