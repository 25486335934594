import React from "react";
import { UploadImage } from "../../graphql/image-queries";
import ImagePanel from "./ImagePanel";
import { Mutation } from "react-apollo";
import { Button } from "@brightcove/studio-components";
import _ from "lodash";
import { ToasterManager } from "../../utils";

export default class ImageUploadPanel extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      fileInfo: props.fileInfo || {},
      image: undefined,
      progress: 0,
      status: undefined,
      editing: false
    };
  }

  onProgressUpdate = (ev: ProgressEvent) => {
    this.setState({ progress: Number(((ev.loaded / ev.total) * 100).toFixed(2)) })
  }

  onCompleted = (data) => {
    const { fileInfo } = this.state;

    if(data.uploadImage) {
      this.setState({ status: "SUCCESS", image: data.uploadImage.image})
    }
    else {
      this.setState({ status: "ERROR"});
      ToasterManager.error({ message: `Something went wrong uploading "${fileInfo.file.name}". Please try again` });
    }
  }

  onSave = ({ data, error }) => {
    if(error)
      ToasterManager.error(error)
    else
      this.setState({ editing: false, image: data.updateImage.image })
  }

  onError = (err) => {
    ToasterManager.error({ message: err.message })
  }

  onEdit = () => {
    this.setState({ editing: true })
  }

  onCancel = () => {
    this.setState({ editing: false })
  }

  getVariables = () => {
    const { content } = this.props;
    const { fileInfo } = this.state;

    return {
      file: fileInfo.file,
      input: {
        contentItemId: content && content.id,
        ..._.omit(fileInfo, ["file"])
      }
    }
  }

  render() {
    const { onDismiss } = this.props;
    const { fileInfo, progress, status, editing, image } = this.state;
    let context = {
      fetchOptions: {
        useUpload: true,
        onProgress: this.onProgressUpdate
      }
    };

    return (
      <Mutation
        mutation={UploadImage}
        context={context}
        variables={this.getVariables()}
        onCompleted={this.onCompleted}
        onError={this.onError}
      >
        {(upload) => {
          const dismissButton = (
            <Button
              className="dismiss-button"
              small
              invert
              text="Dismiss"
              onClick={() => { onDismiss(fileInfo.file.name) }}
              style={{display: "inline !important"}}
            />
          )

          return (
            <ImagePanel
              title={fileInfo.file.name}
              file={fileInfo.file}
              image={image}
              progress={progress}
              status={status}
              upload={upload}
              editing={editing}
              onEdit={this.onEdit}
              onCancel={this.onCancel}
              onSave={this.onSave}
              titleBar={dismissButton}
            />
          )
        }}
      </Mutation>
    )
  }
};
