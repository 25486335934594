import React from "react";
import ControlledProps from "./ControlledProps";
import { Button } from "@brightcove/studio-components"
import { withModal, ImageSelectionModal } from "../Modals";
import classnames from "classnames";

interface ImageProps {
  onChange?: Function
  image: any
  content?: any
  className?: string
}

const SelectImageButton = withModal(Button, ImageSelectionModal);

export default class ImageInput extends React.Component<ControlledProps & ImageProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      image: props.image
    }
  }

  componentDidMount() {
    const { onChange, property } = this.props;
    const { image } = this.state;
    onChange && onChange(image, property);
  }

  onChange = (selections) => {
    const { onChange, property } = this.props;
    let image = selections[0];
    this.setState({ image })
    onChange && onChange(image, property);
  }

  render() {
    const { content, className } = this.props;
    const { image } = this.state;
    return (
      <div className={classnames(["image-input", className])}>
        <div><img src={image.url || image} /></div>
        <SelectImageButton
          small
          text="Change Image"
          onModalConfirm={this.onChange}
          modalProps={{
            content: content
          }}/>
      </div>
    )
  }
}
