import React from "react"
import { Query } from "react-apollo"
import { GetDynamicStringAndCatalogs, UpdateDynamicString } from "../../graphql/dynamic-string-queries";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import { withRouter } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import { withModal, DeleteConfirmationModal } from "../../components/Modals";
import { Button } from "@brightcove/studio-components";
import _ from "lodash";
import Panel from "../../components/Panel/Panel";
import Row from "../../components/Row/Row";
import EditGroup from "../../components/EditGroup/EditGroup";
import { TextArea, Select } from "../../components/ControlledComponents";
import { ToasterManager } from "../../utils";

const defaultModel = { contentItems: [] };
const DeleteButton = withModal(Button, DeleteConfirmationModal);

class DynamicString extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      changes: {}
    };
  }

  onSave = ({ data, error }) => {
    if(error)
      ToasterManager.error(error);
    else
      this.onCancel();
  }

  onEdit = () => {
    this.setState({ editing: true, changes: {} });
  }

  onCancel = () => {
    this.setState({ editing: false, changes: {} });
  }

  onChange = (change, property) => {
    if(property == "catalogId") {
      this.setState({
        changes: {
          ...this.state.changes,
          global: !change.length,
          catalogId: change ? change : undefined
        }
      })
    }
    else {
      this.setState({
        changes: {
          ...this.state.changes,
          [property]: change
        }
      })
    }
  }

  render() {
    const { history, match } = this.props
    const { editing } = this.state;

    return (
      <Query query={GetDynamicStringAndCatalogs} variables={{ id: match.params.id }}>
        {({ loading, error, data }) => {
          let dynamicString: any = data && data.dynamicString || defaultModel;
          let catalogs: any = data && data.catalogs || [];
          let catalogList = _.concat(
            [{ label: "Global", value: "" }],
            catalogs.map(catalog => ({ label: catalog.name, value: catalog.id }))
          )
          if(error) ToasterManager.error(error)

          return (
            <React.Fragment>
              <SectionHeader
                title={dynamicString.key}
                subtitleAbove="Back"
                black
                onSubtitleClick={() => { history.goBack() }}
                buttons={
                  <DeleteButton
                    text="Delete"
                    pink
                    onModalConfirm={() => { history.goBack() }}
                    modalProps={{selections: [dynamicString], type: "dynamic string"}}
                  />
                }
              />
              <div className="detail-main">
                <div className="detail-content">
                  { loading && <Loading /> }
                  <Panel
                    id="dynamic-string"
                    title="String Details"
                    onSave={this.onSave}
                    onCancel={this.onCancel}
                    onEdit={this.onEdit}
                    editing={editing}
                    mutation={UpdateDynamicString}
                    changes={() => ({
                      input: {
                        id: dynamicString.id,
                        catalogId: dynamicString.catalog && dynamicString.catalog.id,
                        ...this.state.changes
                      }
                    })}
                  >
                    <Row>
                      <EditGroup label="Key" text={dynamicString.key}/>
                      <EditGroup label="Scope" text={(dynamicString.catalog && dynamicString.catalog.name) || "Global"}>
                        <Select
                          gray
                          property="catalogId"
                          value={(dynamicString.catalog && dynamicString.catalog.id) || ""}
                          options={catalogList}
                          onChange={this.onChange}
                        />
                      </EditGroup>
                    </Row>
                    <EditGroup label="Value" html={dynamicString.value}>
                      <TextArea property="value" value={dynamicString.value} onChange={this.onChange}/>
                    </EditGroup>
                  </Panel>
                </div>
              </div>
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
};

export default withRouter(DynamicString);
