import React from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../../utils";
import { Icon, ICONS } from "@brightcove/studio-components";
import classnames from "classnames";
import _ from "lodash";

export const EpisodeColumns = [
  {
    Header: "Title",
    minWidth: 100,
    resizable: false,
    accessor: "tile.image.url",
    Cell: ({ value }) => {
      let className = classnames({"table-image": true, placeholder: !value});
      return <img className={className} src={value} />
    }
  },
  {
    Header: "",
    minWidth: 320,
    resizable: false,
    accessor: "name",
    Cell: ({original: { name, id }}) => {
      return (
        <div className="name-id">
          <div><Link to={`/episode/${id}`}>{name}</Link></div>
          <div>{id}</div>
        </div>
      )
    }
  },
  {
    Header: "Episode Number",
    minWidth: 140,
    maxWidth: 140,
    resizable: false,
    accessor: "episodeNumber"
  },
  {
    Header: "Status",
    accessor: "status",
    resizable: false,
    minWidth: 75,
    maxWidth: 75,
    style: { display: "block", textAlign: "center" },
    Cell: ({value}) => {
      return value == "ACTIVE" ? <Icon name={ICONS.CHECKMARK} color='green'/> : <Icon name={ICONS.BAN} color='magenta'/>
    }
  },
  {
    Header: "Start Date",
    accessor: "start",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  },
  {
    Header: "End Date",
    accessor: "end",
    resizable: false,
    minWidth: 190,
    maxWidth: 190,
    sortable: true,
    Cell: ({value}) => { return formatDate(value) }
  }
];
