import React from "react"
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import { withRouter } from "react-router-dom";
import { Button } from "@brightcove/studio-components";
import _ from "lodash";
import "./UploadImages.scss";
import { getImageDimensions } from "../../utils";
import ImageUploadPanel from "../../components/Panel/ImageUploadPanel";

class UploadImage extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      images: []
    };
  }

  onImageSelect = async (event) => {
    let input = event.currentTarget;
    let images = this.state.images;

    for(let i=0; i<input.files.length; i++) {
      let file = input.files[i];
      let dimensions = await getImageDimensions(file);
      images.push({ file, width: dimensions.width, height: dimensions.height, size: file.size })
    }

    this.setState({
      images: images
    })
  }

  onFileInputClick = (event) => {
    event.currentTarget.value = null
  }

  onImageDismiss = (filename) => {
    this.setState({
      images: this.state.images.filter(image => image.file.name != filename)
    })
  }

  render() {
    const { history, embedded, content } = this.props;
    const { images } = this.state;
    return (
      <React.Fragment>
        { !embedded &&
          <SectionHeader
            title="Upload Images"
            subtitleAbove="Back"
            black
            onSubtitleClick={() => { history.goBack() }}
          />
        }
        <div className="detail-main">
          <div className="detail-content">
            <div className="upload-message">Image files will upload here and are available to edit in <a href="/images" target="_blank">Images</a> when the upload is complete.</div>

            <form encType="multipart/form-data">
              <input className="upload-input" type="file" accept="image/*" onChange={this.onImageSelect} onClick={this.onFileInputClick} multiple />
            </form>

            <Button className="upload-button" text="Upload Images" onClick={() => {
              let input: HTMLElement = document.querySelector(".upload-input") as HTMLElement;
              input.click()
            }} />

            { images.map(image => (<ImageUploadPanel key={image.file.name} fileInfo={image} content={content} onDismiss={this.onImageDismiss} />)) }
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(UploadImage);
