import gql from "graphql-tag";

export const GetCatalogs = gql`
  query GetCatalogs {
    catalogs {
      id
      name
    }
  }`
;
