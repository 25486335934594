import React from "react"
import { Query } from "react-apollo"
import { UpdateEpisode, GetEpisode } from "../../graphql/episode-queries";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import { withRouter } from "react-router-dom";
import { PageIndex, PageIndexButton } from "../../components/PageIndex/PageIndex";
import Panel from "../../components/Panel/Panel";
import EditGroup from "../../components/EditGroup/EditGroup";
import { Input, TextArea, TagListInput } from "../../components/ControlledComponents";
import Row from "../../components/Row/Row";
import Loading from "../../components/Loading/Loading";
import ImageListPanel from "../../components/Panel/ImageListPanel";
import AvailabilityPanel from "../../components/Panel/AvailabilityPanel";
import DisplayItemPanel from "../../components/Panel/DisplayItemPanel";
import Details from "../Details/Details";

import _ from "lodash";
import { ToasterManager, formatDate, formatDuration } from "../../utils";

const defaultModel = {
  tile: {
    image: {}
  },
  slide: {
    image: {}
  },
  series: {},
  season: {},
  metadata: {}
};

class Episode extends Details {
  getChanges = (episode) => {
    let updated = _.pick(episode, ["id", "name", "runtime", "genres", "tags", "metadata", "tuneInText"]);
    _.forIn(this.state.changes, (value, key) => {
      if(key.startsWith("metadata")) {
        let mKey = key.replace("metadata.", "");
        updated.metadata[mKey] = value;
      }
      else {
        updated[key] = value;
      }
    })
    delete updated.metadata.__typename;

    return { input: updated };
  }

  render() {
    const { history, match } = this.props

    return (
      <Query query={GetEpisode} variables={{ id: match.params.id }}>
        {({ loading, error, data }) => {
          let episode: any = data && data.contentItem || defaultModel;
          if(error) ToasterManager.error(error)

          return (
            <React.Fragment>
              <SectionHeader
                title={episode.name}
                subtitleAbove="Back"
                black
                onSubtitleClick={() => { history.goBack() }} />
              <div className="detail-main">
                <div className="detail-content">
                  { loading && <Loading /> }
                  <Panel
                    title="Episode"
                    id="episode"
                    mutation={UpdateEpisode}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("episode")}
                    disabled={this.isDisabled("episode")}
                    changes={() => this.getChanges(episode)}
                    onValidation={(changes) => {
                      let inputs = changes.input;
                      return inputs.name && inputs.name.length > 0 || false;
                    }}
                  >
                    <Row>
                      <EditGroup label="Episode Name" text={episode.name}>
                        <Input value={episode.name} property="name" onChange={this.onChange}/>
                      </EditGroup>
                      <EditGroup label="ID" text={episode.id}/>
                      <EditGroup label="Show ID" text={episode.series.id}/>
                      <EditGroup label="Season ID" text={episode.season.id}/>
                    </Row>
                  </Panel>

                  <AvailabilityPanel
                    staticWindow
                    content={episode}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("availability")}
                    disabled={this.isDisabled("availability")}
                  />

                  <ImageListPanel
                    content={episode.series}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("images")}
                    disabled={this.isDisabled("images")}
                  />

                  <DisplayItemPanel
                    type="tile"
                    content={episode}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("tile")}
                    disabled={this.isDisabled("tile")}
                  />

                  <DisplayItemPanel
                    type="slide"
                    content={episode}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("slide")}
                    disabled={this.isDisabled("slide")}
                  />

                  <Panel
                    title="Episode Information"
                    id="info"
                    mutation={UpdateEpisode}
                    onSave={this.onSave}
                    onEdit={this.onPanelEdit}
                    onCancel={this.onPanelCancel}
                    editing={this.isEditing("info")}
                    disabled={this.isDisabled("info")}
                    changes={() => this.getChanges(episode)}
                  >
                    <EditGroup label="Reference ID" text={episode.extId}/>
                    <EditGroup label="Season Number" text={episode.seasonNumber}/>
                    <EditGroup label="Episode Number" text={episode.episodeNumber}/>
                    <EditGroup label="Episode Duration" text={formatDuration(episode.duration)}/>
                    <EditGroup label="Original Air Date" text={formatDate(episode.originalAirDate, false)}/>
                    <EditGroup label="TV Ratings" text={episode.metadata.rating}/>
                    <EditGroup label="Year" text={episode.metadata.year}>
                      <Input value={episode.metadata.year} property="metadata.year" type="number" onChange={this.onChange}/>
                    </EditGroup>
                    <EditGroup label="Tune In" text={episode.tuneInText}>
                      <Input value={episode.tuneInText} property="tuneInText" onChange={this.onChange}/>
                    </EditGroup>
                    <EditGroup label="Summary Short" text={episode.metadata.description}>
                      <TextArea value={episode.metadata.description} property="metadata.description" onChange={this.onChange}/>
                    </EditGroup>
                    <EditGroup label="Summary Long" text={episode.metadata.summary}>
                      <TextArea value={episode.metadata.summary} property="metadata.summary" onChange={this.onChange}/>
                    </EditGroup>
                    <EditGroup label="Genres" text={(episode.genres || []).join(", ")}>
                      <TagListInput value={episode.genres} property="genres" onChange={this.onChange}/>
                    </EditGroup>
                    <EditGroup label="Tags" text={(episode.tags || []).join(", ")}>
                      <TagListInput value={episode.tags} property="tags" onChange={this.onChange}/>
                    </EditGroup>
                  </Panel>
                </div>
                <PageIndex>
                  <PageIndexButton id="episode" text="Episode"/>
                  <PageIndexButton id="availability" text="Availability"/>
                  <PageIndexButton id="images" text="Images"/>
                  <PageIndexButton id="tile" text="Tile"/>
                  <PageIndexButton id="slide" text="Hero Slide"/>
                  <PageIndexButton id="info" text="Episode Information"/>
                </PageIndex>
              </div>
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
};

export default withRouter(Episode);
