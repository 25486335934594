import React from "react";
import { PlainSelect as StudioPlainSelect, PlainSelectProps } from "@brightcove/studio-components";
import ControlledProps from "./ControlledProps";
import classnames from "classnames";

interface SelectProps {
  gray?: boolean
}

export default class Select extends React.Component<PlainSelectProps & ControlledProps & SelectProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value || ""
    }
  }

  componentDidMount() {
    const { onChange, property } = this.props;
    const { value } = this.state;
    onChange && onChange(value, property);
  }

  onChange = (value) => {
    const { onChange, property } = this.props;
    this.setState({value})
    onChange && onChange(value, property);
  }

  render() {
    const { property, className, gray, ...props } = this.props;
    return (
      <StudioPlainSelect className={classnames(["select", className, { gray }])}  {...props} value={this.state.value} onChange={this.onChange}>
        {this.props.children}
        </StudioPlainSelect>
      )
  }
}
