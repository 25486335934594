import React from "react";
import { Modal, Button } from "@brightcove/studio-components";
import { Input, Select, TagListInput } from "../ControlledComponents";
import Row from "../Row/Row";
import EditGroup from "../EditGroup/EditGroup";
import Collections from "../../routes/Collections/Collections";
import "./AddComponentModal.scss";
import _ from "lodash";

export default class AddComponentModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      step: "details",
      component: {}
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.isOpen && !this.props.isOpen) {
      this.setState({ step: "details", component: {} })
    }
  }

  nextStep = (stepData) => {
    const { step } = this.state;
    const { confirm } = this.props;
    if (step == "details" && stepData.type != "RECOMMENDATIONS") {
      this.setState({
        step: ["CAROUSEL", "GRID", "HERO", "COMINGSOON"].includes(stepData.type) ? "collection" : "data",
        component: {
          ...stepData
        }
      })
    } else {
      let component = _.merge(this.state.component, stepData, { id: _.uniqueId("placeholder.") });
      confirm && confirm(component);

      this.setState({
        step: "details",
        component: {}
      })
    }
  }

  render() {
    const { step, component } = this.state;
    let CurrentModal;
    switch(step) {
      case "details":
        CurrentModal = AddDetails;
        break;
      case "collection":
        CurrentModal = AddCollection
        break;
      case "data":
        CurrentModal = AddData;
        break;
    }
    return <CurrentModal key={new Date().toISOString()} {...this.props} component={component} nextStep={this.nextStep} />
  }
};

class AddDetails extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      type: "CAROUSEL"
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.isOpen && !this.props.isOpen) {
      this.setState({ name: "", type: "CAROUSEL" })
    }
  }

  onChange = (value, property) => {
    this.setState({ [property]: value })
  }

  render() {
    const { isOpen, close, nextStep } = this.props;
    const { name, type } = this.state

    return (
      <Modal
        className="add-component-modal details"
        title="Add a Component - Name & Type"
        isOpen={isOpen}
        onClose={close}
        buttons={[
          <Button key="1" invert text="Cancel" onClick={close} />,
          <Button
            key="2"
            text={type == "RECOMMENDATIONS" ? "Add Component" : "Continue"}
            disabled={!(name && name.length && type)}
            onClick={() => { nextStep(this.state) }}
          />
        ]}
      >
        <Row>
          <EditGroup label="Component Name" required editing>
            <Input property="name" onChange={this.onChange} value="" />
          </EditGroup>
          <EditGroup label="Component Type" required editing>
            <Select gray property="type" onChange={this.onChange} value="CAROUSEL" options={[
              { label: "Carousel", value: "CAROUSEL" },
              { label: "Coming Soon", value: "COMINGSOON" },
              { label: "Grid", value: "GRID" },
              { label: "Hero", value: "HERO" },
              { label: "Live", value: "LIVE" },
              { label: "Genres", value: "GENRES" },
              { label: "Recommendations", value: "RECOMMENDATIONS" }
            ]}/>
          </EditGroup>
        </Row>
      </Modal>
    )
  }
};

class AddCollection extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      collection: undefined
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.isOpen && !this.props.isOpen) {
      this.setState({ collection: undefined })
    }
  }

  onRowSelect = (selections) => {
    this.setState({ collection: selections[0] })
  }

  render() {
    const { isOpen, close, nextStep } = this.props;
    const { collection } = this.state
    return (
      <Modal
        className="add-component-modal collection"
        title="Add a Component - Select Collection"
        isOpen={isOpen}
        onClose={close}
        buttons={[
          <Button key="1" invert text="Cancel" onClick={close} />,
          <Button key="2" text="Add Component" disabled={!collection} onClick={() => {
            nextStep(this.state)
          }}/>
        ]}
      >
        <Collections key={"modal.add-to-component"} embedded singleSelection={true} onRowSelect={this.onRowSelect}/>
      </Modal>
    )
  }
};

class AddData extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.isOpen && !this.props.isOpen) {
      this.setState({ data: {} })
    }
  }

  onChange = (value, property) => {
    this.setState({
      data: {
        [property]: value
      }
    })
  }

  render() {
    const { isOpen, close, component, nextStep } = this.props;
    const { data } = this.state
    return (
      <Modal
        className="add-component-modal data"
        title="Add a Component - Enter Data"
        isOpen={isOpen}
        onClose={close}
        buttons={[
          <Button key="1" invert text="Cancel" onClick={close} />,
          <Button
            key="2"
            text="Add Component"
            disabled={!((data.url && data.url.length) || (data.genres && data.genres.length))}
            onClick={() => { nextStep(this.state) }}
          />
        ]}
      >
        <React.Fragment>
          { component.type == "GENRES" &&
            <EditGroup label="Enter Genres (press Return to confirm)" required editing>
              <TagListInput value={[]} property="genres" onChange={this.onChange} />
            </EditGroup>
          }
          { component.type == "LIVE" &&
            <EditGroup label="Enter Stream URL" required editing>
              <Input value="" property="url" onChange={this.onChange} />
            </EditGroup>
          }
        </React.Fragment>
      </Modal>
    )
  }
};
