import React from "react";
import { Modal } from "@brightcove/studio-components";
import UploadImages from "../../routes/Images/UploadImages"
import "./ImageUploadModal.scss";
import { GetContentItemImages } from "../../graphql/content-queries";
import { ApolloConsumer } from "react-apollo";

export default class ImageUploadModal extends React.Component<any, any> {
  getContentItem = () => {
    const { content } = this.props;
    return content ?
      content.__typename == "Episode" ? content.series : content :
      undefined;
  }

  render() {
    const { content, isOpen, close, loading, data, confirm, ...props } = this.props;

    return (
      <ApolloConsumer>
          { client => {
            let onClose = async () => {
              await client.query({
                query: GetContentItemImages,
                variables: { id: content.id },
                fetchPolicy: "network-only"
              });
              close();
            }

            return (
              <Modal
                className="image-upload-modal"
                title="Upload Images"
                {...props}
                isOpen={isOpen}
                onClose={onClose}
              >
                <UploadImages embedded content={this.getContentItem()} />
              </Modal>
            )
          }}
      </ApolloConsumer>
    )
  }
};
