import React from "react";
import { Link } from "react-router-dom";

export const DynamicStringColumns = [
  {
    Header: "Key",
    accessor: "key",
    minWidth: 170,
    maxWidth: 170,
    Cell: ({original: { key, id }}) => {
      return <Link to={`/dynamic-string/${id}`}>{key}</Link>
    }
  },
  {
    Header: "Scope",
    accessor: "scope",
    minWidth: 150,
    maxWidth: 150,
    resizable: false,
    Cell: ({original: { catalog }}) => {
      return <div>{(catalog && catalog.name) || "Global" }</div>
    }
  },
  {
    Header: "Value",
    accessor: "value",
    resizable: false,
    sortable: false,
    Cell: ({value}) => {
      return <div className="dynamic-strings-values">{value}</div>
    }
  }
];
