import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import './Draggable.scss';
import $ from "jquery";

const offset = 2;
const padding = 15;

export default class Draggable {
  ref: any = React.createRef();
  onDragEnd?: Function = undefined;

  mouseStart?: any = undefined;
  initPosition?: any = undefined;
  oldIndex?: number = undefined;
  newIndex?: number = undefined;

  $element?: JQuery<any> = undefined;
  $clone?: JQuery<any> = undefined;
  $filler?: JQuery<any> = undefined;

  constructor(onDragEnd) {
    this.onDragEnd = onDragEnd;
  }

  table = () => {
    return ReactDOM.findDOMNode(this.ref.current)!;
  }

  getRef = () => {
    return this.ref;
  }

    getTdProps = (state, rowInfo, column, instance) => {
      return {
          onDragStart: this.onDragStart
      }
    }

    inTableBounds = (point) => {
      var $table = this.table() as HTMLElement;
      let tableRect = $table.getBoundingClientRect(),
        tableOffset = $($table).offset(),
        initBounds = _.merge(tableOffset, { width: tableRect.width, height: tableRect.height }),
        tableBounds = {
          left: initBounds.left - padding,
          top: initBounds.top - padding,
          width: initBounds.width + 2*padding,
          height: initBounds.height + 2*padding
        };

      return  point.left >= tableBounds.left &&
        point.left <= tableBounds.left + tableBounds.width &&
        point.top >= tableBounds.top &&
        point.top <= tableBounds.top + tableBounds.height;
    }

    getMousePoint = (event) => {
      return { left: event.pageX, top: event.pageY };
    }

    onDrag = (event) => {
      var mouse = this.getMousePoint(event);

      if(!this.$element || !this.inTableBounds(mouse)) {
        this._onDragEnd();
        return
      }

      var mouseDelta = {
        left: mouse.left - this.mouseStart.left,
        top: mouse.top - this.mouseStart.top
      },
      newPosition = {
        left: this.initPosition.left + mouseDelta.left,
        top: this.initPosition.top + mouseDelta.top
      };
      this.$clone!.css(newPosition);

      var $filler = this.$filler!;
      var newIndex = this.newIndex;

      $(this.table()).find('.rt-tr-group:not([class*="clone"]):not([class*="filler"])').each(function(index) {
        var $this = $(this),
          rowTop = $this.position().top,
          height = $this.height()!,
          topInBounds = newPosition.top >= rowTop && newPosition.top <= rowTop + height,
          bottomInBounds = (newPosition.top + height) >= rowTop && (newPosition.top + height) <= rowTop + height,
          halfwayPoint = rowTop + (height/2);

        if(topInBounds && newPosition.top < halfwayPoint) {
          newIndex = index;
          $filler.insertBefore($this);
        }
        else if(bottomInBounds && ((newPosition.top + height) > halfwayPoint)) {
          newIndex = index;
          $filler.insertAfter($this);
        }
      });

      this.newIndex = newIndex;
    }

    _onDragEnd = (event?: any) => {
      if(!this.$element) return;

      $(document).off('mouseup', this._onDragEnd);
      $(document).off('mousemouse', this.onDrag);

      this.$clone!.remove();
      this.$filler!.remove();
      this.$element!.removeClass('draggable-original');
      $(this.table()).removeClass("dragging");

      if(this.onDragEnd && this.oldIndex != this.newIndex)
        this.onDragEnd(this.oldIndex, this.newIndex);

      this.$clone = this.$element = this.$filler = this.mouseStart = this.initPosition = this.oldIndex = this.newIndex = undefined;
    }

    onDragStart = (event) => {
      this.$element = $(event.target).closest('.rt-tr-group');
      this.$clone = this.$element.clone();
      this.$filler = this.$element.clone();

      this.mouseStart = this.getMousePoint(event);
      this.initPosition = this.$element.position();
      this.oldIndex = this.newIndex = this.$element.index();

      this.$clone.width(this.$element.width()!);
      this.$clone.css({
          left: this.initPosition.left - offset,
          top: this.initPosition.top - offset
      });

      this.$element.addClass('draggable-original');
      this.$clone.addClass('draggable-clone');
      this.$filler.addClass('draggable-filler expanded');
      $(this.table()).addClass("dragging");

      $(document).one('mouseup', this._onDragEnd);
      $(document).on('mousemove', this.onDrag);

      this.$filler.insertAfter(this.$element);
      this.$clone.insertAfter(this.$element);
    }

};
