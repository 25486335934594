import React from "react";
import { Tabs, TabList, SmallTab, DropdownMenu, DropdownMenuItem } from "@brightcove/studio-components";
import {withRouter, Link} from "react-router-dom";
import "./Header.scss";
import { compose } from 'react-apollo';
import { withStore } from "../../store";
import { SVGIcon } from "../../icons";
import { GetCatalogs } from "../../graphql/catalog-queries";
import { Query } from "react-apollo"
import classnames from "classnames";

const BaseUserMenu: React.FunctionComponent<any> = ({toAdmin, catalog: currentCatalog, changeCatalog, history, className: customClassName, ...props}) => {
  let onCatalogClick = (catalog) => {
    return () => {
      changeCatalog(catalog, () => {
        history.push("/")
      })
    }
  };

  let isValidCatalog = (currentCatalog, catalogs) => {
    if(!catalogs || !currentCatalog) return true;
    for(let catalog of catalogs) {
      if(currentCatalog.id == catalog.id)
        return true;
    }
    return false
  }

  return (
    <Query query={GetCatalogs}>
      {({ data }) => {
        if(data && data.catalogs) {
          if(!isValidCatalog(currentCatalog, data.catalogs))
            changeCatalog(data.catalogs[0]);
        }
        let className = classnames([customClassName, { "no-catalog": !currentCatalog }])

        return (
          <DropdownMenu className={className} {...props}>
            { currentCatalog && data && data.catalogs &&
              <React.Fragment>
                {
                  data.catalogs.map((catalog, index) => {
                    let className = classnames({ selected: catalog.id == currentCatalog.id })
                    return <DropdownMenuItem key={index} className={className} onClick={onCatalogClick(catalog)}>{catalog.name}</DropdownMenuItem>
                  })
                }
                <DropdownMenuItem onClick={toAdmin("/logout")}>Logout</DropdownMenuItem>
              </React.Fragment>
            }
          </DropdownMenu>
        )
      }}
    </Query>
  )
};
const UserMenu = withRouter(BaseUserMenu);

class Header extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: "/",
      adminPath: false
    }
  }

  componentDidMount() {
    this.getTab();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.location.pathname != this.props.location.pathname) {
      this.getTab()
    }
  }

  getTab = () => {
    let tabIds = ["/", "/collections", "/channels", "/screens", "/epg"];
    let adminPaths = ["/service-messages", "/dynamic-strings", "/maintenance-mode", "/activity-history"];
    let path = (/\/[a-zA-Z\-]*/g.exec(this.props.location.pathname) || [])[0];
    let selectedTab = "/"
    let adminPath = false;

    if(path.length > 1) {
      for(let id of tabIds) {
        if(id.includes(path)) {
          selectedTab = id;
        }
      }
    }

    for(let id of adminPaths) {
      if(path.length > 1 && id.includes(path)) {
        adminPath = true;
        break;
      }
    }

    this.setState({ selectedTab, adminPath });
  }

  onSelect = (id) => {
    const { history } = this.props;
    history.push(id);
    this.setState({
      selectedTab: id,
      adminPath: false
    });
  }

  toAdmin = (id) => {
    return () => {
      const { history } = this.props;
      history.push(id);
      this.setState({
        adminPath: true
      });
    }
  }

  toExternal = (url, newWindow?: boolean) => {
    return () => {
      if(newWindow)
        window.open(url, "_blank");
      else
        window.location = url;
    }
  }

  toDownloadUrl = (url) => {
    return async () => {
      const { store: { authenticator } } = this.props;
      if(authenticator.hasExpired()) {
        await new Promise(resolve => {
          authenticator.renewSession(resolve)
        });
      }
      const token = authenticator.getIdToken();
      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      fetch(url, options)
        .then(async res => {
          const blob = await res.blob();
          const filename = res.headers.get("content-disposition")!.match(/\"([a-zA-Z0-9-.]+)\"/)![1];
          return { blob, filename };
        })
        .then(obj => {
          var file = window.URL.createObjectURL(obj.blob);

          var fileLink = document.createElement('a');
          fileLink.href = file;
          fileLink.download = obj.filename;
          fileLink.click();
          fileLink.remove();
        });
    }
  }

  render() {
    const { selectedTab, adminPath } = this.state;
    const { user, changeCatalog, store: { catalog } } = this.props;

    return (
      <div className="header">
        <div className="header-logo"><Link to="/"><img src={SVGIcon.Logo}/></Link></div>
        { user ?
          <React.Fragment>
            { catalog &&
              <React.Fragment>
                <div className="header-catalog">{catalog.name}</div>
                <Tabs
                  selectedId={selectedTab}
                  onSelect={this.onSelect}
                  className={classnames({ "header-tabs": true, "no-selection": adminPath })}
                >
                  <TabList>
                    <SmallTab className="header-tab" tabId="/">Content Management</SmallTab>
                    <SmallTab className="header-tab" tabId="/collections">Collections</SmallTab>
                    <SmallTab className="header-tab" tabId="/channels">Channels</SmallTab>
                    <SmallTab className="header-tab" tabId="/screens">Screens</SmallTab>
                    <SmallTab className="header-tab" tabId="/epg">EPG</SmallTab>
                  </TabList>
                </Tabs>
              </React.Fragment>
            }
            <div className="header-menus">
              <DropdownMenu className="header-admin-menu" label="ADMIN" buttonProps={{className: "header-menu-button"}}>
                <DropdownMenuItem onClick={this.toAdmin("/dynamic-strings")}>Dynamic Strings</DropdownMenuItem>
                <DropdownMenuItem onClick={this.toAdmin("/activity-history")}>Activity History</DropdownMenuItem>
                <DropdownMenuItem onClick={this.toAdmin("/service-messages")}>Service Messages</DropdownMenuItem>
                <DropdownMenuItem onClick={this.toAdmin("/maintenance-mode")}>Maintenance Mode</DropdownMenuItem>
                <DropdownMenuItem onClick={this.toExternal(process.env.REACT_APP_INGEST_DASHBOARD_URL, true)}>Ingest Dashboard</DropdownMenuItem>
                <DropdownMenuItem onClick={this.toExternal("https://studio.brightcove.com", true)}>Video Cloud</DropdownMenuItem>
                <DropdownMenuItem onClick={this.toExternal(process.env.REACT_APP_INGEST_DASHBOARD_URL)}>User Management</DropdownMenuItem>
                <DropdownMenuItem onClick={this.toDownloadUrl(`${process.env.REACT_APP_MIDDLEWARE_URL}/csv`)}>Export User Metadata</DropdownMenuItem>
              </DropdownMenu>
              <UserMenu
                className="header-user-menu"
                label={(user.email || "")}
                buttonProps={{className: "header-menu-button"}}
                toAdmin={this.toAdmin}
                changeCatalog={changeCatalog}
                catalog={catalog}
              />
            </div>
          </React.Fragment>
          : null
        }
      </div>
    )
  }
};

export default compose(withRouter, withStore)(Header);
