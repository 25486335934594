import React from "react";
import { Input, RadioGroup, Select } from "../ControlledComponents";
import EditGroup from "../EditGroup/EditGroup";
import ToggleButton from "../ToggleButton/ToggleButton";
import Row from "../Row/Row";
import { getTextFromOptions } from "../../utils";
import "./CollectionRule.scss";
import _ from "lodash";

const fieldOptions = [
  { label: "Genres", value: "GENRES" },
  { label: "Type", value: "TYPE" },
  { label: "Tags", value: "TAGS" }
];
const relationOptions = [
  { label: "Match Any", value: "CONTAINS" },
  { label: "Match All", value: "EQUALS" }
];

export default class CollectionRule extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      field: props.field,
      relation: props.relation,
      value: props.value
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps != this.props) {
      this.setState({
        field: this.props.field,
        relation: this.props.relation,
        value: this.props.value
      })
    }
  }

  onChange = (change, property) => {
    const { id, onChange } = this.props;
    this.setState(
      { [property]: change },
      () => { onChange && onChange({ id, ...this.state }, "rule") }
    )
  }

  render() {
    const { field, relation, value } = this.state;
    const { id, onRemove, editing } = this.props;

    return (
      <div className="collection-rule">
        <Row>
          <EditGroup editing={editing} label="Include" text={getTextFromOptions(field, fieldOptions)}>
            <Select gray onChange={this.onChange} value={field} property="field" options={fieldOptions} />
          </EditGroup>
          <EditGroup editing={editing} label="Keywords" text={getTextFromOptions(relation, relationOptions)}>
            <RadioGroup name="keywords" onChange={this.onChange} property="relation" inline={false} value={relation} options={relationOptions} />
          </EditGroup>
          <EditGroup editing={editing} label="Value" text={value}>
            <Input value={value} property="value" onChange={this.onChange} />
          </EditGroup>
        </Row>
        { editing && <ToggleButton type="remove" text="Clear" onClick={() => { onRemove(id) }} /> }
      </div>
    )
  }
};
