import Authenticator from "../auth/Authenticator";
import config from "../config";

export interface StoreState {
  authenticator?: Authenticator;
  catalog: any;
  language: string;
};

export interface GenericAction {
  type: string,
  data?: any
};

export enum StorageField {
  Catalog = "Catalog",
  Language = "Language"
};

export function storageKey(field) {
  return `${config.localStoragePrefix}.${field}`
}
