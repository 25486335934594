import React from "react"
import { handleErrors } from "../../utils";

export default class Details extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      changes: null,
      editingPanel: null
    };
  }

  onChange = (change, property) => {
    if (property == "bannerImageUrl" || property == "image") {
      this.setState({
        changes: {
          ...this.state.changes,
          [property]: change.url
        }
      })
    } else {
      this.setState({
        changes: {
          ...this.state.changes,
          [property]: change
        }
      })
    }
  }

  onCompleted = (data?: any) => {
    this.setState({ changes: null, editingPanel: null });
  }

  onError = (error) => {
    handleErrors(error)
  }

  onSave = ({ data, error }) => {
    if(error) {
      this.onError(error);
    }
    else {
      this.onCompleted(data);
    }
  }

  onPanelEdit = (editingPanel) => {
    this.setState({ editingPanel });
  }

  onPanelCancel = () => {
    this.setState({ editingPanel: null, changes: null });
  }

  isEditing = (id) => {
    return id == this.state.editingPanel;
  }

  isDisabled = (id) => {
    return this.state.editingPanel != null && id != this.state.editingPanel
  }
};
