import gql from "graphql-tag";

export const GetDynamicStrings = gql`
  query GetDynamicStrings($after: Int, $first: Int, $query: String, $orderBy: DynamicStringOrder) {
    dynamicStrings(after: $after, first: $first, query: $query, orderBy: $orderBy) {
      nodes {
        id
        key
        value
        catalog {
          id
          name
        }
      }
      totalCount
    }
  }`
;

export const CreateDynamicString = gql`
  mutation CreateDynamicString($input: CreateDynamicStringInput!) {
    createDynamicString(input: $input) {
      dynamicString {
        id
      }
    }
  }`
;

export const GetDynamicStringAndCatalogs = gql`
  query GetDynamicStringAndCatalogs($id: ID!) {
    dynamicString(id: $id) {
      id
      key
      value
      catalog {
        id
        name
      }
    }
    catalogs {
      id
      name
    }
  }`
;

export const UpdateDynamicString = gql`
  mutation UpdateDynamicString($input: UpdateDynamicStringInput!) {
    updateDynamicString(input: $input) {
      dynamicString {
        id
        value
        catalog {
          id
        }
      }
    }
  }`
;

export const DeleteDynamicStrings = gql`
  mutation DeleteDynamicStrings($ids: [ID!]) {
    deleteDynamicStrings(ids: $ids)
  }`
;
