import React from "react";
import { Icon, ICONS, Input } from "@brightcove/studio-components";
import ControlledProps from "../ControlledComponents/ControlledProps";
import classnames from "classnames";
import "./Stepper.scss";

interface StepperProps {
  value?: number
  min: number
  max: number
  onChange?: Function
}

export default class Stepper extends React.Component<StepperProps & ControlledProps, any> {
  static defaultProps = {
    min: -Number.MAX_VALUE,
    max: Number.MAX_VALUE
  }

  constructor(props) {
    super(props)
    this.state = {
      value: props.value || 0
    }
  }

  addValue = () => {
    this.changeValue("+");
  }

  subtractValue = () => {
    this.changeValue("-");
  }

  changeValue = (update) => {
    const { min, max, onChange, property } = this.props;
    let value;

    if(Number.isInteger(Number(update))) {
      value = Math.max(Math.min(Number(update), max), min)
    }
    else {
      value = update == "-" ?
        Math.max(this.state.value - 1, min) :
        Math.min(this.state.value + 1, max)
    }

    this.setState({ value }, () => {
      onChange && onChange(value, property);
    })
  }

  render() {
    const { className: customClassName } = this.props;
    const { value } = this.state;

    return (
      <div className={classnames(["stepper", customClassName])}>
        <div className="stepper-minus" onClick={this.subtractValue}><Icon name={ICONS.MINUS_CIRCLE} color="teal"/></div>
        <Input className="stepper-input" value={value} type="number" onChange={this.changeValue}/>
        <div className="stepper-plus" onClick={this.addValue}><Icon name={ICONS.PLUS_CIRCLE} color="teal"/></div>
      </div>
    )
  }
}
