import React from "react";
import { Icon, ICONS } from "@brightcove/studio-components";
import "./ToggleButton.scss";
import classnames from "classnames";

interface ToggleButtonProps {
  type: "add" | "remove"
  text?: string
  onClick?: any
}

const ToggleButton: React.FunctionComponent<ToggleButtonProps> = ({ type, text, onClick }) => {
  return (
    <div className={classnames({"toggle-button": true, [type]: true})} onClick={onClick}>
      <Icon name={type == "add" ? ICONS.PLUS_CIRCLE : ICONS.MINUS_CIRCLE}  />
      <div>{text}</div>
    </div>
  )
};

export default ToggleButton;
