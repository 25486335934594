import React from "react"
import { Query } from "react-apollo"
import { GetMaintenanceModes } from "../../graphql/maintenance-mode-queries";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import Loading from "../../components/Loading/Loading";
import { SVGIcon } from "../../icons";
import Details from "../Details/Details";
import MaintenanceModePanel from "../../components/Panel/MaintenanceModePanel";
import { Button } from "@brightcove/studio-components";
import _ from "lodash";
import { ToasterManager } from "../../utils";

export default class MaintenanceModes extends Details {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      tempMode: null
    }
  }

  getPanelId = (id) => {
    return `maintenance-mode-${id}`
  }

  onError = (error) => {
    ToasterManager.error({ message: "Maintenance mode has already been created for one of the selected platforms" });
  }

  onCompleted = () => {
    ToasterManager.dismiss();
    this.setState({ editingPanel: null, tempMode: null });
  }

  createUnsavedMode = () => {
    let id =  _.uniqueId("temporary");

    this.setState({
      tempMode: { id, status: "INACTIVE", platforms: [] },
      editingPanel: this.getPanelId(id)
    })
  }

  deleteUnsavedMode = ()  => {
    this.setState({
      tempMode: null,
      editingPanel: null
    })
  }

  render() {
    const { tempMode, editingPanel } = this.state;

    return (
      <Query query={GetMaintenanceModes}>
        {({ loading, error, refetch, data }) => {
          let messages = data && data.maintenanceModes || [];
          let allModes = tempMode ? _.concat(messages, tempMode) : messages;
          if(error) ToasterManager.error(error)

          return (
            <React.Fragment>
              <SectionHeader icon={SVGIcon.MaintenanceMode} teal title="Maintenance Mode" />
              <div className="detail-main">
                <div className="detail-content full-width">
                  { loading && <Loading /> }
                  { allModes.map(message => {
                      let id = this.getPanelId(message.id)
                      return (
                        <MaintenanceModePanel
                          key={id}
                          id={id}
                          unsaved={id.includes("temporary")}
                          message={message}
                          refetch={refetch}
                          onSave={this.onSave}
                          onEdit={this.onPanelEdit}
                          onCancel={this.onPanelCancel}
                          onUnsavedDelete={this.deleteUnsavedMode}
                          editing={this.isEditing(id)}
                          disabled={this.isDisabled(id)}
                        />
                      )
                    })
                  }
                  <Button text="Create Maintenance Mode" onClick={this.createUnsavedMode} disabled={editingPanel != null} />
                </div>
              </div>
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
};
