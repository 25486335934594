import React from "react";
import { Mutation, Query } from "react-apollo";
import { PanelFooterBar } from "../../components/Panel/Panel";
import { withRouter } from "react-router";
import { Panel, Button } from "@brightcove/studio-components";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import EditGroup from "../../components/EditGroup/EditGroup";
import Row from "../../components/Row/Row";
import { Input, Select, TextArea } from "../../components/ControlledComponents";
import { CreateDynamicString } from "../../graphql/dynamic-string-queries";
import { GetCatalogs } from "../../graphql/catalog-queries";
import _ from "lodash";
import { ToasterManager } from "../../utils";

const SaveButton: React.FunctionComponent<any> = ({ changes, onCompleted, onError, ...props }) => {
  return (
    <Mutation mutation={CreateDynamicString} onCompleted={onCompleted} onError={onError} >
    {(save) => {
      return <Button {...props} onClick={() => {
        ToasterManager.dismiss();
        save({ variables: { input: changes } })
      }} />
    }}
    </Mutation>
  )
};

class NewDynamicString extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      valid: false,
      changes: {}
    }
  }

  onChange = (change, property) => {
    ToasterManager.dismiss();
    this.setState({
      changes: {
        ...this.state.changes,
        [property]: change == "" ? undefined : change
      }
    }, () => {
      this.setState({ valid: this.validate(this.state.changes) })
    })
  }

  validate = ({ key, value }) => {
    return (key && key.length > 0) && (value && value.length > 0);
  }

  onError = (error) => {
    ToasterManager.error(error);
  }

  onCompleted = (data) => {
    if(data && data.createDynamicString && data.createDynamicString.dynamicString) {
      const { history } = this.props;
      history.replace(`/dynamic-string/${data.createDynamicString.dynamicString.id}`);
    }
    else {
      ToasterManager.error({ message: "This key already exists." })
    }
  }

  render() {
    const { history } = this.props;
    const { valid, changes } = this.state;

    return (
      <Query query={GetCatalogs}>
        {({ data }) => {
          let catalogs = data && data.catalogs || [];
          let catalogList = _.concat(
            [{ label: "Global", value: "" }],
            catalogs.map(catalog => ({ label: catalog.name, value: catalog.id }))
          )

          return (
            <React.Fragment>
              <SectionHeader
                title="New String"
                subtitleAbove="Back"
                black
                onSubtitleClick={() => { history.goBack() }} />
              <div className="detail-main">
                <div className="detail-content new">
                  <Panel className="panel" title="String Details">
                    <Row>
                      <EditGroup label="Key" editing required>
                        <Input value="" property="key" onChange={this.onChange}/>
                      </EditGroup>
                      <EditGroup label="Scope" editing required>
                        <Select
                          gray
                          value=""
                          property="catalogId"
                          onChange={this.onChange}
                          options={catalogList}
                        />
                      </EditGroup>
                    </Row>
                    <EditGroup label="Value" editing required>
                      <TextArea property="value" value="" onChange={this.onChange}/>
                    </EditGroup>

                    <PanelFooterBar>
                      <Button small invert text="Cancel" onClick={() => { history.goBack() }} />
                      <SaveButton
                        small
                        disabled={!valid}
                        text="Create"
                        changes={changes}
                        onError={this.onError}
                        onCompleted={this.onCompleted}
                      />
                    </PanelFooterBar>
                  </Panel>
                </div>
              </div>
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
}

export default withRouter(NewDynamicString);
