import React from "react";
import SideMenu, { SideMenuItem } from "./SideMenu";
import { SVGIcon } from "../../icons";

const DefaultSideMenu: React.FunctionComponent<any> = () => {
  return (
    <SideMenu autoSelect>
      <SideMenuItem link="/" icon={SVGIcon.NavAllContent}>All Content</SideMenuItem>
      <SideMenuItem link="/episodes" icon={SVGIcon.NavEpisode}>Episodes</SideMenuItem>
      <SideMenuItem link="/shows" icon={SVGIcon.NavShows}>Shows</SideMenuItem>
      <SideMenuItem link="/images" icon={SVGIcon.NavImage}>Images</SideMenuItem>
    </SideMenu>
  )
};

export default DefaultSideMenu;
