import React from "react"
import { Query } from "react-apollo"
import { GetImage } from "../../graphql/image-queries";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import { withRouter } from "react-router-dom";
import ImagePanel from "../../components/Panel/ImagePanel";
import Loading from "../../components/Loading/Loading";
import { withModal, DeleteConfirmationModal } from "../../components/Modals";
import { Button } from "@brightcove/studio-components";
import _ from "lodash";
import { ToasterManager } from "../../utils";

const defaultModel = { contentItems: [] };
const DeleteButton = withModal(Button, DeleteConfirmationModal);

class Image extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
  }

  onSave = ({ data, error }) => {
    if(error)
      ToasterManager.error(error)
    else
      this.onCancel();
  }

  onEdit = () => {
    this.setState({ editing: true });
  }

  onCancel = () => {
    this.setState({ editing: false });
  }

  render() {
    const { refetch, history, embedded, match } = this.props
    const { editing } = this.state;

    return (
      <Query query={GetImage} variables={{ id: match.params.id }}>
        {({ loading, error, data }) => {
          let image: any = data && data.image || defaultModel;
          if(error) ToasterManager.error(error)

          return (
            <React.Fragment>
              <SectionHeader
                title={image.name}
                subtitleAbove="Back"
                black
                onSubtitleClick={() => { history.goBack() }}
                buttons={
                  <DeleteButton
                    text="Delete"
                    pink
                    onModalConfirm={() => { history.goBack() }}
                    modalProps={{selections: [image], type: "image"}}
                  />
                }
              />
              <div className="detail-main">
                <div className="detail-content">
                  { loading && <Loading /> }
                  <ImagePanel
                    onSave={this.onSave}
                    onCancel={this.onCancel}
                    onEdit={this.onEdit}
                    editing={editing}
                    image={image}
                  />
                </div>
              </div>
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
};

export default withRouter(Image);
